import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-raise-bid',
  templateUrl: './dialog-raise-bid.component.html',
  styleUrls: ['./dialog-raise-bid.component.sass']
})
export class DialogRaiseBidComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
