import { Component, OnInit } from '@angular/core';
import { theme } from '../../../data/theme';
import { AuthService } from '../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit{
    theme = theme;
    lang = "";
    infoItems = [
        {label: 'aboutUs',             url: './site/about-us', en: '', zh: ''},
        {label: 'tnc',       url: './site/tnc', en: '', zh: ''},
        {label: 'afterSalesService', url: './site/after-sales-service', en: '', zh: ''},
        {label: 'deliveryInfo', url: './site/delivery-info', en: '', zh: ''},
        // {label: 'brands',               url: '', en: '', zh: ''},
        // {label: 'contactUs',           url: './site/contact-us', en: '', zh: ''},
        // {label: 'returns',              url: './site/returns', en: '', zh: ''},
        // {label: 'siteMap',             url: '', en: '', zh: ''}
    ];

    myAccountItems = [
        {label: 'storeLocation', url: '', en: '', zh: ''},
        {label: 'orderHistory',  url: '', en: '', zh: ''},
        {label: 'wishList',      url: './shop/wishlist', en: '', zh: ''},
        {label: 'newsletter',     url: '', en: '', zh: ''},
        {label: 'specials',       url: '', en: '', zh: ''},
        {label: 'giftCards',     url: '', en: '', zh: ''},
        {label: 'affiliate',      url: '', en: '', zh: ''}
    ];

    infoHeader = {
      header: 'info',
      en: '',
      zh: ''
    };

    myAccountHeader = {
      header: 'myAccount',
      en: '',
      zh: ''
    };

    constructor(private translate: TranslateService, public authService: AuthService) { }

    ngOnInit(){
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });

      this.initTranslate();
    }

    initTranslate(){
      // this.translate.getTranslation("zh").subscribe(res => {
      //   this.authService.zh = res;
      //   this.infoItems.forEach(item => {
      //     item.zh = this.authService.zh.footer.info[item.label];
      //   });
      //   this.myAccountItems.forEach(item => {
      //     item.zh = this.authService.zh.footer.myAccount[item.label];
      //   });
      //   this.infoHeader.zh = this.authService.zh.footer.infoTxt;
      //   this.myAccountHeader.zh = this.authService.zh.footer.myAccountTxt;
      // });
      // this.translate.getTranslation("en").subscribe(res => {
      //   this.authService.en = res;
      //   this.infoItems.forEach(item => {
      //     item.en = this.authService.en.footer.info[item.label];
      //   });
      //   this.myAccountItems.forEach(item => {
      //     item.en = this.authService.en.footer.myAccount[item.label];
      //   });
      //   this.infoHeader.en = this.authService.en.footer.infoTxt;
      //   this.myAccountHeader.en = this.authService.en.footer.myAccountTxt;
      // });



      this.infoItems.forEach(item => {
        item.zh = this.authService.zh.footer.info[item.label];
      });
      this.myAccountItems.forEach(item => {
        item.zh = this.authService.zh.footer.myAccount[item.label];
      });
      this.infoHeader.zh = this.authService.zh.footer.infoTxt;
      this.myAccountHeader.zh = this.authService.zh.footer.myAccountTxt;


      this.infoItems.forEach(item => {
        item.en = this.authService.en.footer.info[item.label];
      });
      this.myAccountItems.forEach(item => {
        item.en = this.authService.en.footer.myAccount[item.label];
      });
      this.infoHeader.en = this.authService.en.footer.infoTxt;
      this.myAccountHeader.en = this.authService.en.footer.myAccountTxt;
    }
}
