<!-- .block-products -->
<div class="block block-products">
    <div class="container">
        <app-block-header [header]="header" [moreParam]="moreParam"></app-block-header>

        <div class="block-products__body">
            <!-- <ng-template #largeTemplate>
                <ng-container *ngIf="large !== null">
                    <div class="block-products__featured">
                        <div class="block-products__featured-item">
                            <app-custom-product-card [product]="large"></app-custom-product-card>
                        </div>
                    </div>
                </ng-container>
            </ng-template> -->
            <ng-template #largeTemplate>
                <ng-container *ngIf="large !== null">
                    <div class="block-products__featured">
                      <div class="block-products__featured-item">
                        <img *ngIf="banner" class="img-ban" [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="banner.url" alt="">
                      </div>
                    </div>
                </ng-container>
            </ng-template>

            <ng-template #smallsTemplate>
                <div *ngIf="smalls.length > 0" class="block-products__list">
                    <div *ngFor="let product of smalls; trackBy: trackByProduct" class="block-card col-6 col-md-4 col-lg-4">
                        <app-custom-product-card-modified [product]="product"></app-custom-product-card-modified>
                    </div>
                </div>
            </ng-template>

            <ng-container *ngIf="layout === 'large-last'" [ngTemplateOutlet]="smallsTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="largeTemplate"></ng-container>
            <ng-container *ngIf="layout === 'large-first'" [ngTemplateOutlet]="smallsTemplate"></ng-container>
        </div>
    </div>
</div>
<!-- .block-products / end -->
