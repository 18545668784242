<div class="product_card-deck card" >
    <div *ngIf="product && product.type && product.type != null && product.type == 'WAIT'" class="badge-auct -one">{{'auction.WAIT' | translate}}</div>
    <div *ngIf="product && product.type && product.type != null && product.type == 'FOREVER'" class="badge-auct -three">{{'auction.FOREVER' | translate}}</div>

      <div class="card-img product_card-deck-img" >
          <a  appClick [routerLink]="[product.routerLink]" *ngIf="product.auctionImageList && product.auctionImageList.length"><img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="product.auctionImageList[0].url"/></a>
          <a  appClick [routerLink]="[product.routerLink]" *ngIf="!product.auctionImageList || !product.auctionImageList.length"><img src="/assets/images/placeholder.png" alt=""></a>
      </div>
      <div class="product_auction-banner">
        <div class="product-card__prices" *ngIf="product">
            <ng-container>
                <div class="typography" *ngIf="product.status == 'ACTIVE' || product.status == 'START'">
                    <!-- <div class="product__new-price font-weight-bold product_counter product_currency" [ngClass]="{'product_counter_pending': product.status == 'ACTIVE'}" >{{parseCountdown(counter$ | async)}}</div> -->
                    <div *ngIf="day" class="product__new-price font-weight-bold product_counter product_currency" [ngClass]="{'product_counter_pending': product.status == 'ACTIVE'}" ><countdown [config]="{ leftTime: count, format: 'dd:HH:mm:ss' }"></countdown></div>
                    <div *ngIf="!day" class="product__new-price font-weight-bold product_counter product_currency" [ngClass]="{'product_counter_pending': product.status == 'ACTIVE'}" ><countdown [config]="{ leftTime: count, format: 'HH:mm:ss' }"></countdown></div>

                    <!-- <div *ngIf="lang == 'zh'" class="product__new-price font-weight-bold product_counter product_currency" [ngClass]="{'product_counter_pending': product.status == 'ACTIVE'}" ><countdown [config]="{ leftTime: count, format: 'h时m分s秒' }"></countdown></div> -->

                </div>
            </ng-container>
        </div>
      </div>
      <div class="card-body card-deck-body">
          <div class="typography product-card__name">
              <a appClick [routerLink]="[product.routerLink]" *ngIf="product.auctionLocaleList && lang == 'en' && product?.auctionLocaleList[0]">{{ product?.auctionLocaleList[0]?.name }}</a>
              <a appClick [routerLink]="[product.routerLink]" *ngIf="product.auctionLocaleList && lang == 'zh' && product?.auctionLocaleList[1]">{{ product?.auctionLocaleList[1]?.name }}</a>
          </div>
          <div class="product-card__action ">
              <div class="product-card__prices" *ngIf="product">
                  <ng-container >
                      <div class="d-flex typography pb-1">
                          <span *ngIf="product.canBid" class="typography product-deposit-paid">
                           {{'order.depositPaid' | translate}}
                          </span>
                          <div >
                          </div>
                      </div>
                    <div *ngIf="!(product && product.auctionBidList && product.auctionBidList.length > 0) && product && product.auctionStartingPriceList">
                      <div class="d-flex typography ">
                          <span class="product-label" >{{'productCard.startingprice' | translate}}: </span>
                          <div *ngFor="let arp of product.auctionStartingPriceList" class="product_currency font-weight-bold">
                            <div class="product_currency" >
                              <span style=" font-size: 8px;" *ngIf="!arp.nameEn && !arp.nameZh">{{'topbar.' + arp.currency | translate}} {{authService.thousandSeparator(arp.amount)}}</span>
                              <span style=" font-size: 8px;" *ngIf="arp.nameEn && lang == 'en'">{{arp.nameEn}} {{authService.thousandSeparator(arp.amount)}}</span>
                              <span style=" font-size: 8px;" *ngIf="arp.nameZh && lang == 'zh'">{{arp.nameZh}} {{authService.thousandSeparator(arp.amount)}}</span>
                            </div>
                          </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container>
                      <div class="d-flex" *ngIf="product && product.auctionBidList && product.auctionBidList.length > 0">
                          <span class=" typography product-label">{{'productCard.biddingprice' | translate}}: </span>
                          <div *ngFor="let abp of product.auctionBidList[0].auctionBidPriceList" class=" product_currency typography font-weight-bold">
                            <span style="font-size: 8px;">{{'topbar.' + abp.currency | translate}}</span> {{authService.thousandSeparator(abp.amount)}}
                          </div>
                            <svg-icon src="../../../../assets/images/icon/auction_1.svg" [svgStyle]="{'height.px':27, 'stoke':'#000'}"></svg-icon>
                          <div class=" typography font-weight-bold product_currency product_bidder-mobile">{{product.totalBidders}}</div>
                      </div>
                  </ng-container>

                  <ng-container>
                      <div class="product-bidder">
                          <div class=" typography product-label">{{'productCard.bidders' | translate}}: </div>

                          <div class=" typography font-weight-bold product_currency">{{product.totalBidders}}</div>
                      </div>
                  </ng-container>
              </div>
          </div>

</div>
