import { Component, OnInit } from '@angular/core';
import { MobileMenuService } from '../../../../shared/services/mobile-menu.service';
import { WishlistService } from '../../../../shared/services/wishlist.service';
import { CartService } from '../../../../shared/services/cart.service';
import { AuthService } from '../../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-mobile-header',
    templateUrl: './mobile-header.component.html',
    styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent implements OnInit{

  short = "EN";
  languages = [
      {name: 'English', image: 'language-1', locale: 'en', short: 'EN'},
      {name: '中文',  image: 'language-2', locale: 'zh', short: '中文'},

  ];
  menuItemsEn = [
    // {label: 'Dashboard',     url: './account/dashboard'},
    // {label: 'Edit Profile',  url: './account/profile'},
    {label: 'Edit Profile', url: './account/profile'},
    {label: 'Reload History', url: './account/reload-history'},
    {label: 'Order History', url: './account/orders'},
    
   // {label: 'Addresses',     url: './account/addresses'},
    {label: 'Withdrawal', url: './account/withdraw'},
    {label: 'Password',      url: './account/password'},
    {label: 'Logout',        url: './account/login', logout: true}
];

menuItemsZh = [
    // {label: '仪表板',     url: './account/dashboard'},
    // {label: 'Edit Profile',  url: './account/profile'},
    
    {label: '编辑个人资料', url: './account/profile'},
      {label: '充值历史', url: './account/reload-history'},
    {label: '订单历史', url: './account/orders'},
    //{label: '地址',     url: './account/addresses'},
    {label: '提款', url: './account/withdraw'},
    {label: '密碼',      url: './account/password'},
    {label: '登出',        url: './account/login', logout: true}
];

menuItems = [];
  lang = "";
  searchh = "";
    constructor(
        public menu: MobileMenuService,
        public wishlist: WishlistService,
        public cart: CartService,
        public authService: AuthService,
        public translate: TranslateService
    ) { }

    ngOnInit(){
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });

      this.authService.removeSearchKeyword$.subscribe(search => {
          this.searchh = "";
      });

      if(this.lang == "en"){
        this.short = "EN";
      }else if(this.lang == "zh"){
        this.short = "中文";
      }
    }

    changeLanguage(language) {
      this.lang = language;
      this.authService.setLocale(language);
      if(this.lang == "en"){
        this.short = "EN";
        this.menuItems = this.menuItemsEn;
      }else if(this.lang == "zh"){
        this.short = "中文";
        this.menuItems = this.menuItemsZh;
      }
    }
    emitSearch(){
      this.searchh = this.searchh.trim();
      if(this.searchh.length > 0){
        // this.authService.searchProductList(this.search);
        this.authService.router.navigate(['/classic/shop/category-grid-3-columns-sidebar/', this.searchh]);
      }
    }
}
