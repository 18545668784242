<div class="pd-2 ">
<h2 mat-dialog-title>{{'message.' + data.type | translate}}</h2>
<mat-dialog-content class="text">
    <div class="card" class=" outer-center">

        <!-- <div class="card-divider"></div> -->
        <div class="card-divider"></div>
        <div class="card-body" class=" inner-center">
            <div class="row no-gutters">
                <div class="col-12 col-lg-12 col-xl-12 overflow-hidden">
                  {{'message.' + data.result.infoCode | translate}}
                  <div>
                    <div *ngIf="data.type != 'BID'">
                      <div *ngIf="data.result.result.usdt"> {{'topbar.USDT' | translate}} {{data.result.result.usdt}}<br></div>
                      <div *ngIf="data.result.result.weniPoint"> {{'topbar.WENI_POINT' | translate}} {{data.result.result.weniPoint}}<br></div>
                      <div *ngIf="data.result.result.otr"> {{'topbar.OTR' | translate}} {{data.result.result.otr}}<br></div>
                      <div *ngIf="data.result.result.lp"> {{'topbar.LP' | translate}} {{data.result.result.lp}}</div>
                      <div *ngIf="data.result.result.usdp"> {{'topbar.USDT_POINT' | translate}} {{data.result.result.usdp}}</div>
                      <div *ngIf="data.result.result.apList">
                        <div *ngFor="let ap of data.result.result.apList">
                          <div *ngIf="ap.nameEn && lang == 'en'">{{ap.nameEn}} {{ap.amount}}</div>
                          <div *ngIf="ap.nameZh && lang == 'zh'">{{ap.nameZh}} {{ap.amount}}</div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="data.type == 'BID'">
                      <div class="form-group" *ngIf="showUsdt">
                          <label>{{'topbar.USDT' | translate}}</label>
                          <input min="1" step="1" type="number" class="form-control" placeholder="{{'topbar.USDT' | translate}}" [(ngModel)]="data.result.result.usdt" [ngModelOptions]="{standalone: true}" (ngModelChange)="validateVal('USDT')" onkeydown="return event.keyCode !== 69">
                      </div>
                      <div class="form-group" *ngIf="showWeniPoint">
                          <label>{{'topbar.WENI_POINT' | translate}}</label>
                          <input min="1" step="1" type="number" class="form-control" placeholder="{{'topbar.WENI_POINT' | translate}}" [(ngModel)]="data.result.result.weniPoint" [ngModelOptions]="{standalone: true}" (ngModelChange)="validateVal('WENI_POINT')" onkeydown="return event.keyCode !== 69">
                      </div>
                      <div class="form-group" *ngIf="showOtr">
                          <label>{{'topbar.OTR' | translate}}</label>
                          <input min="1" step="1" type="number" class="form-control" placeholder="{{'topbar.OTR' | translate}}" [(ngModel)]="data.result.result.otr" [ngModelOptions]="{standalone: true}" (ngModelChange)="validateVal('OTR')" onkeydown="return event.keyCode !== 69">
                      </div>
                      <div class="form-group" *ngIf="showLp">
                          <label>{{'topbar.LP' | translate}}</label>
                          <input min="1" step="1" type="number" class="form-control" placeholder="{{'topbar.LP' | translate}}" [(ngModel)]="data.result.result.lp" [ngModelOptions]="{standalone: true}" (ngModelChange)="validateVal('LP')" onkeydown="return event.keyCode !== 69">
                      </div>
                      <div class="form-group" *ngIf="showUsdp">
                          <label>{{'topbar.USDT_POINT' | translate}}</label>
                          <input min="1" step="1" type="number" class="form-control" placeholder="{{'topbar.USDT_POINT' | translate}}" [(ngModel)]="data.result.result.usdp" [ngModelOptions]="{standalone: true}" (ngModelChange)="validateVal('USDT_POINT')" onkeydown="return event.keyCode !== 69">
                      </div>
                      <div *ngIf="showDynamic">
                        <div class="form-group" *ngFor="let ap of data.result.result.apList">
                            <label *ngIf="ap.nameEn && lang == 'en'">{{ap.nameEn}}</label>
                            <label *ngIf="ap.nameZh && lang == 'zh'">{{ap.nameZh}}</label>
                            <input *ngIf="ap.nameEn && lang == 'en'" min="1" step="1" type="number" class="form-control" placeholder="{{ap.nameEn}}" [(ngModel)]="ap.amount" [ngModelOptions]="{standalone: true}" (ngModelChange)="validateVal(ap.currency)" onkeydown="return event.keyCode !== 69">
                            <input *ngIf="ap.nameZh && lang == 'zh'" min="1" step="1" type="number" class="form-control" placeholder="{{ap.nameZh}}" [(ngModel)]="ap.amount" [ngModelOptions]="{standalone: true}" (ngModelChange)="validateVal(ap.currency)" onkeydown="return event.keyCode !== 69">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group mt-5 mb-0">
                        <button style="background: transparent; border: none; color: #f34770 !important; " class="btn btn-secondary mr-2" (click)="cancel()">{{'common.cancel' | translate}}</button>
                    </div>
                    <div class="form-group mt-5 mb-0">
                        <button  class="btn btn-primary" (click)="pay()">{{'common.pay' | translate}}</button>
                    </div>
                  </div>
                </div>
            </div>

        </div>
    </div>

</mat-dialog-content>
</div>
