import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// modules
import { SharedModule } from '../../shared/shared.module';

// components
import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component';
import { MobileLinksComponent } from './components/mobile-links/mobile-links.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { FormsModule } from '@angular/forms';  //<<<< import it here
import { MobileFixedMenuComponent } from './components/mobile-fixed-menu/mobile-fixed-menu.component';  //<<<< import it here
import { AngularSvgIconModule } from 'angular-svg-icon';


@NgModule({
    declarations: [
        // components
        MobileHeaderComponent,
        MobileLinksComponent,
        MobileMenuComponent,
        MobileFixedMenuComponent
    ],
    imports: [
        // modules (angular)
        CommonModule,
        RouterModule,
        // modules
        SharedModule,
        FormsModule,
        AngularSvgIconModule.forRoot()
    ],
    exports: [
        // components
        MobileHeaderComponent,
        MobileMenuComponent,
        MobileFixedMenuComponent
    ]
})
export class MobileModule { }
