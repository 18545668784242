<!-- .block-product-columns -->
<div class="block block-product-columns d-lg-block">
    <div class="container">
        <div class="row">
            <div *ngFor="let column of columns" class="col">
                <app-block-header *ngIf="lang == 'en'" [header]="column"></app-block-header>
                <app-block-header *ngIf="lang == 'zh'" [header]="column"></app-block-header>

                <div class="block-product-columns__column">
                    <div *ngFor="let product of column.products" class="block-product-columns__item">
                        <app-product-card [product]="product" layout="horizontal"></app-product-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- .block-product-columns / end -->
