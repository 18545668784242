<!-- .dropcart -->
<div class="dropcart">
    <div class="dropcart__empty" *ngIf="!cart.data.items.length">
        <span>{{'cart.empty' | translate}}</span>
    </div>
    <ng-container *ngIf="cart.data.items.length">
        <div class="dropcart__products-list">
            <div *ngFor="let item of cart.data.items" class="dropcart__product">

                <div class="dropcart__product-image">
                    <a [routerLink]="item.product.routerLink" *ngIf="item.product.productImageList?.length"><img [src]="item.product.productImageList[0].url" alt=""></a>
                    <a [routerLink]="item.product.routerLink" *ngIf="!item.product.productImageList || !item.product.productImageList.length"><img src="/assets/images/placeholder.png" alt=""></a>
                </div>
                <div class="dropcart__product-info">
                    <div class="dropcart__product-name">
                      <a *ngIf="lang == 'en' && authService.en && item.product && item.product.productLocaleList" [routerLink]="item.product.routerLink">{{ item.product.productLocaleList[0].name }}</a>
                      <a *ngIf="lang == 'zh' && authService.zh && item.product && item.product.productLocaleList" [routerLink]="item.product.routerLink">{{ item.product.productLocaleList[1].name }}</a>
                    </div>

                    <ul *ngIf="item.options?.length" class="dropcart__product-options">
                        <li *ngFor="let option of item.options">{{ option.name }}: {{ option.value }}</li>
                    </ul>

                    <div class="dropcart_product-attributes mt-4 ">
                            <div class="font-size-sm mr-2" *ngFor="let spa of item.selectedProductAttributeList">
                              <small class="text-muted" *ngIf="lang == 'en'">{{spa.productAttribute.attributeValue?.attributeValueLocaleList[0].name}}</small>
                              <small class="text-muted" *ngIf="lang == 'zh'">{{spa.productAttribute.attributeValue?.attributeValueLocaleList[1].name}}</small>
                            </div>
                            <!-- <div class="font-size-sm mr-2"><small class="text-muted">Small</small></div> -->
                    </div>
                    <div class="dropcart__product-meta">
                        <span class="dropcart__product-quantity">x {{ item.quantity }}</span>
                        <!-- <span class="dropcart__product-price">{{ item.product.price|currencyFormat }}</span> -->
                    </div>


                </div>

                <button  style="background: none;" type="button" class="dropcart__product-remove btn btn-light btn-sm btn-svg-icon" (click)="remove(item)" [ngClass]="{'btn-loading': removedItems.includes(item)}">
                    <app-icon name="cross-10" size="10"></app-icon>
                </button>
            </div>
        </div>

        <div class="dropcart__totals">
            <table>
                <tr *ngIf="(cart.totals$|async).length">
                    <th>{{'cart.subtotal' | translate}}</th>
                    <td>{{ cart.subtotal$|async }} {{'topbar.weniPoints' | translate}}</td>
                </tr>
                <tr *ngFor="let total of cart.totals$|async">
                    <th>{{ total.title }}</th>
                    <td>{{ total.price }} {{'topbar.weniPoints' | translate}}</td>
                </tr>
                <tr>
                  <th>{{'productCard.shippingFee' | translate}}</th>
                  <td><span *ngIf="!(cart.totalShippingFee$|async) || (cart.totalShippingFee$|async) == 0">{{'productCard.free' | translate}}</span><span *ngIf="(cart.totalShippingFee$|async) && (cart.totalShippingFee$|async) > 0">USDT {{cart.totalShippingFee$|async}}</span></td>
                </tr>
                <!-- <tr *ngIf="(cart.totalFinalUsdt$|async) > 0">
                    <th></th>
                    <td>USDT {{cart.totalFinalUsdt$|async}}</td>
                  </tr> -->
                <tr class="font-size-smaller">
                    <th>{{'cart.total' | translate}}</th>
                    <td>
                      <div *ngIf="(cart.totalFinalUsdt$|async)">USDT {{cart.totalFinalUsdt$|async}}</div>
                      <div *ngIf="(cart.totalWeniToken$|async)">{{cart.appendPlusSign("weni")}} {{'topbar.weni' | translate}} {{cart.totalWeniToken$|async}}</div>
                      <div *ngIf="(cart.totalUsdtPoint$|async)">{{cart.appendPlusSign("usdtPoint")}} {{cart.totalUsdtPoint$|async}} {{'topbar.usdtPoints' | translate}}</div>
                      <div *ngIf="(cart.total$|async)">{{cart.appendPlusSign("weniPoint")}} {{ cart.total$|async }} {{'topbar.weniPoints' | translate}}</div>
                    </td>
                </tr>


            </table>
        </div>

        <div class="dropcart__buttons">
            <a style="background: transparent; border: 2px solid #d61b82; color: #3d464d !important;" class="btn btn-secondary" [routerLink]="['../classic/shop/cart']">{{'cart.viewCart' | translate}}</a>
            <a class="btn btn-primary btn-alt-col"  (click)="checkout()">{{'cart.checkout' | translate}}</a>
        </div>
    </ng-container>
</div>
<!-- .dropcart / end -->
