<mat-dialog-content class="text">
    <div class="card" class="scrollable outer-center">

        <!-- <div class="card-divider"></div> -->
        <div class="card-body" class="scrollable inner-center">
            <div class="row no-gutters">
                <div class="col-12 col-lg-12 col-xl-12">
                    <pre *ngIf="lang == 'en'">
                      Notice from Weini Mall

Due to the upcoming Chinese Spring Festival holiday closure,freight logistic companies have shorten the operational hour,
causing difficulties and delays of shipments,hence:

1. Starting from 18 Jan 2020 till 31 Jan 2020, all delivery services are suspended on all countries and regions.

2. Some countries & regions (ex. Malaysia & Taiwan,China) had their delivery/logistic services suspended since before 16 Jan.

3. From 1 Feb 2020 onwards,  delivery/logistics services will be gradually restored.

4. All products under WENI mall remain open for order.
                    </pre>
                    <pre *ngIf="lang == 'zh'">
                      唯你商城”通知

       由于春节期间，货运物流公司提前放假，导致商城的订单不能正常发货，故：

    1、2020年1月18日开始，至2020年1月31日，所有国家和地区暂停配送货品。

    2、部分国家和地区（如马来西亚和中国台湾）的物流公司已经于本月16日前暂停配送。

    3、2020年2月1日，开始恢复正常货运物流的配送工作。

    4、“唯你商城”的各种商品保持正常下单状态。
                    </pre>
                </div>
            </div>
        </div>
    </div>

</mat-dialog-content>
