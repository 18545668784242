<div class="mobile-header">
    <div class="mobile-header__panel">
        <div class="container">
            <div class="mobile-header__body">
                <button class="mobile-header__menu-button" (click)="menu.open()">
                    <app-icon name="menu-18x14" size="18x14"></app-icon>
                </button>
                <a class="mobile-header__logo" routerLink="./home">
                  <img src="assets/images/logos/weni-mall-mobile.png">
                </a>
                <div class="mobile-header__search" appDropdown="mobile-header__search--opened" #search="appDropdown">
                    <form class="mobile-header__search-form" action="">
                        <!-- <input class="mobile-header__search-input" name="search" placeholder="{{'search.search' | translate}}" aria-label="Site search" type="text" autocomplete="off" (keydown.escape)="search.close()" #searchInput> -->
                        <input *ngIf="lang == 'en' && authService.en" class="mobile-header__search-input" name="search" placeholder="{{authService.en.search.search}}" aria-label="Site search" type="text" autocomplete="off" [(ngModel)]="searchh" [ngModelOptions]="{standalone: true}" (keyup.enter)="emitSearch()">
                        <input *ngIf="lang == 'zh' && authService.zh" class="mobile-header__search-input" name="search" placeholder="{{authService.zh.search.search}}" aria-label="Site search" type="text" autocomplete="off" [(ngModel)]="searchh" [ngModelOptions]="{standalone: true}" (keyup.enter)="emitSearch()">

                        <button class="mobile-header__search-button mobile-header__search-button--submit" type="submit" (click)="emitSearch()">
                            <app-icon name="search-20" size="20"></app-icon>
                        </button>
                        <div appDropdown="topbar-dropdown--opened" #languageDropdown="appDropdown" >
                            <button  class="topbar-dropdown__btn" type="button" (click)="languageDropdown.toggle()" *ngIf="lang == 'en' && authService.en">
                                <!-- <app-icon name="cross-20" size="20"></app-icon> -->
                                <span class="topbar__item-value">{{short}}</span>
                                <app-icon name="arrow-rounded-down-7x5" size="7x5"></app-icon>
                            </button>
                            <button class="topbar-dropdown__btn" type="button" (click)="languageDropdown.toggle()" *ngIf="lang == 'zh' && authService.zh">
                                <span class="topbar__item-value">{{short}}</span>
                                <app-icon name="arrow-rounded-down-7x5" size="7x5"></app-icon>
                            </button>
                            <div class="topbar-dropdown__body">
                                <ul class="menu menu--layout--topbar menu--with-icons">
                                    <li *ngFor="let language of languages">
                                        <button type="button" (click)="changeLanguage(language.locale); languageDropdown.toggle();">
                                            <span class="menu__icon">
                                                <img [src]="'assets/images/languages/'+language.image+'.png'"
                                                    [srcset]="'assets/images/languages/'+language.image+'.png 1x,' +
                                                            'assets/images/languages/'+language.image+'@2x.png 2x'" alt="">
                                            </span>
                                            {{ language.name }}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                         </div>
                        <div class="mobile-header__search-body"></div>
                    </form>

                </div>

                <div class="mobile-header__indicators">
                    <div class="indicator indicator--mobile-search indicator--mobile d-sm-none">
                        <!-- <button class="indicator__button" (click)="search.open(); searchInput.focus()"> -->
                        <button class="indicator__button" (click)="search.open();">
                            <span class="indicator__area">
                                <app-icon name="search-20" size="20"></app-icon>
                            </span>
                        </button>
                    </div>

                    <!-- <div class="indicator indicator--mobile d-sm-flex d-none">
                        <a routerLink="./shop/wishlist" class="indicator__button">
                            <span class="indicator__area">
                                <app-icon name="heart-20" size="20"></app-icon>
                                <span class="indicator__value">{{ wishlist.count$|async }}</span>
                            </span>
                        </a>
                    </div> -->

                    <div class="indicator indicator--mobile">
                        <a routerLink="./shop/cart" class="indicator__button">
                            <span class="indicator__area">
                                <app-icon name="cart-20" size="20"></app-icon>
                                <span class="indicator__value">{{ cart.quantity$|async }}</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
