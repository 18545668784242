import { Component, Input, OnInit, ChangeDetectorRef, AfterViewInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DirectionService } from '../../../shared/services/direction.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth.service';

@Component({
    selector: 'app-block-slideshow',
    templateUrl: './block-slideshow.component.html',
    styleUrls: ['./block-slideshow.component.scss'],
  	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockSlideshowComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() withDepartments = false;
    @Input() assetList = [];
    options = {
        nav: false,
        dots: true,
        loop: true,
        autoplay:true,
        responsive: {
            0: {items: 1}
        },
        rtl: this.direction.isRTL()
    };

    slides = [
        {
            url: null,
            image_classic: 'assets/images/slides/01.png',
            image_full: 'assets/images/slides/slide-1-full.jpg',
            // image_mobile: 'assets/images/slides/slide-1-mobile.jpg'
            image_mobile: 'assets/images/slides/01.png'

        },
        {
            url: null,
            image_classic: 'assets/images/slides/02.png',
            image_full: 'assets/images/slides/slide-2-full.jpg',
            // image_mobile: 'assets/images/slides/slide-2-mobile.jpg'
            image_mobile: 'assets/images/slides/02.png'
        },
        {
            url: null,
            image_classic: 'assets/images/slides/03.png',
            image_full: 'assets/images/slides/slide-3-full.jpg',
            // image_mobile: 'assets/images/slides/slide-3-mobile.jpg'
            image_mobile: 'assets/images/slides/03.png'
        }
    ];


    image = [
       {
        boxed_image:'assets/images/slides/a-side-1.jpg'
       }
    ];

    constructor(
        public sanitizer: DomSanitizer,
        private direction: DirectionService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef
    ) {

    }

    ngOnInit(){
      // this.getAssetList();
      this.detectChange();
    }

    ngOnDestroy() {
      this.cdr.detach();
    }

    detectChange(){
      if(!this.cdr['destroyed']){
       this.cdr.detectChanges();
      }
    }

    ngAfterViewInit() {
      // this.cdr.detectChanges();
    }


    getAssetList(){
      this.authService.start();
      this.authService.getAssetList().subscribe(
          data => {
            if(data['status'] == 'ok'){
              var list = data['result'];
              this.assetList = [];
              list.slice().reverse().forEach(a => {
                if(a.type == "SLIDE"){
                  this.assetList.push(a);
                }
              });
            }
            // this.cdr.detectChanges();
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              // this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }
}
