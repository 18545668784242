<div class="product product_main product--layout--{{ layout }} "  *ngIf="product">
    <div class="product__content">
        <!-- .product__gallery -->
 <div *ngIf="product && product.type && product.type != null && product.type == 'WAIT'" class="badge-auct -one t-20">{{'auction.WAIT' | translate}}</div>
 <div *ngIf="product && product.type && product.type != null && product.type == 'FOREVER'" class="badge-auct -three t-20">{{'auction.FOREVER' | translate}}</div>

    <!-- <div class="badge-auct -two">level 2</div> -->
    <!-- <div class="badge-auct -three">level 3</div> -->
        <div class="product__gallery">
            <div class="product-gallery" *ngIf="showGallery">
                <div class="product-gallery__featured">
                    <owl-carousel-o [options]="carouselOptions" (changed)="featuredCarouselTranslated($event)" appOwlPreventClick #featuredCarousel>
                        <ng-container *ngFor="let image of images">
                            <ng-template carouselSlide [id]="image.id">
                                    <!-- <div  class="product-card__badges-list">
                                      <div *ngIf=" lang == 'en' && authService.en" class="product-card__badge product-card__badge--sale">{{authService.en.productCard.sale}}</div>
                                      <div *ngIf=" lang == 'zh' && authService.zh" class="product-card__badge product-card__badge--sale">{{authService.zh.productCard.sale}}</div>

                                    </div> -->
                                <a appClick (click)="openPhotoSwipe($event, image);" [href]="image.url" target="_blank">
                                    <!-- <img [src]="image.url" alt="" #imageElement> -->
                                    <img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="image.url" #imageElement/>
                                </a>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
                <div class="product-gallery__carousel">
                    <owl-carousel-o [options]="thumbnailsCarouselOptions" [appFakeSlides]="images.length" #fakeSlides="appFakeSlides" appOwlPreventClick #thumbnailsCarousel>
                        <ng-container *ngFor="let image of images">
                            <ng-template carouselSlide [id]="image.id">
                                <span class="product-gallery__carousel-item"
                                   [ngClass]="{'product-gallery__carousel-item--active': image.active}"
                                   appClick (click)="featuredCarousel.to(image.id); setActiveImage(image)">
                                    <!-- <img class="product-gallery__carousel-image" [src]="image.url" alt=""> -->
                                    <img class="product-gallery__carousel-image" [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="image.url"/>

                                </span>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngFor="let i of fakeSlides.slides">
                            <ng-template carouselSlide [id]="'fake-slide-'+i"></ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
        <!-- .product__gallery / end -->
        <!-- .product__info -->
        <div class="product__info">

            <h1 class="product__name" *ngIf="product && lang == 'en' && product?.auctionLocaleList">{{ product?.auctionLocaleList[0]?.name }}</h1>
            <h1 class="product__name" *ngIf="product && lang == 'zh' && product?.auctionLocaleList">{{ product?.auctionLocaleList[1]?.name }}</h1>

            <ul class="product__meta" *ngIf="product">
                <li *ngIf="product.merchant">{{'productCard.soldBy' | translate}}: <span  class="cursor-pointer product__meta-link" (click)="goToMerchant(product.merchant.id)">{{product.merchant.company}}</span></li>
                <li *ngIf="lang == 'en' && product && product?.brand?.brandLocaleList">{{'productCard.brand' | translate}}: <span class="cursor-pointer product__meta-link" (click)="goToBrand(product.brand.id)">{{product?.brand?.brandLocaleList[0]?.name}}</span></li>
                <li *ngIf="lang == 'zh' && product && product?.brand?.brandLocaleList">{{'productCard.brand' | translate}}: <span class="cursor-pointer product__meta-link" (click)="goToBrand(product.brand.id)">{{product?.brand?.brandLocaleList[1]?.name}}</span></li>
                <li *ngIf="product && product?.sku"><span class="">{{'productCard.sku' | translate}}:</span> <span class="product__meta-link-sub"> {{product?.sku}}</span></li>
                <li *ngIf="product && product?.pv"><span class="">{{'topbar.pv' | translate}}:</span> <b>{{product?.pv}}</b></li>
            </ul>
        </div>
        <!-- .product__info / end -->
        <!-- .product__sidebar -->
        <div class="product__sidebar">

           <div class="form-group product__option">
            <div class="product__prices" *ngIf="product">
                  <div class="product__counter" *ngIf="product && (product.status == 'ACTIVE' || product.status == 'START')">
                      <ng-container *ngIf="product">
                        <div class=" typography product_counter-wrapper">
                          <div class="pr-2 product_icon">
                             <i class="far fa-clock"></i>
                          </div>
                          <div>
                            <div class="d-flex align-items-center">
                            <div class="text-muted product-counter__label" *ngIf="product.status == 'ACTIVE'">{{'productCard.auctionstarts' | translate}} </div>
                            <div class="text-muted product-counter__label" *ngIf="product.status == 'START'">{{'productCard.auctionends' | translate}}  </div>
                            </div>

                            <div *ngIf="day" class="product__new-price product_counter-inner" [ngClass]="{'product_counter_pending-counter': product.status == 'ACTIVE'}" ><countdown [config]="{ leftTime: count, format: 'dd:HH:mm:ss' }"></countdown></div>
                            <div *ngIf="!day" class="product__new-price product_counter-inner" [ngClass]="{'product_counter_pending-counter': product.status == 'ACTIVE'}" ><countdown [config]="{ leftTime: count, format: 'HH:mm:ss' }"></countdown></div>
                          </div>
                        </div>
                    </ng-container>
                  </div>
                  <div class="product_wrapper">

                    <!-- Bidding Price-->
                            <ng-container *ngIf="!(product && product.auctionBidList && product.auctionBidList.length > 0) && product && product.auctionStartingPriceList">
                                <div class="d-flex typography mb-3">
                                  <span class="product_bidder">{{'productCard.startingprice' | translate}}: </span>
                                  <div class="product-page_currency pl-2 pr-2" *ngFor="let arp of product.auctionStartingPriceList">
                                    <strong>
                                      <span style="font-size: 12px;" *ngIf="!arp.nameEn && !arp.nameZh">{{'topbar.' + arp.currency | translate}} </span>
                                      <span style="font-size: 12px;" *ngIf="arp.nameEn && lang == 'en'">{{arp.nameEn}} </span>
                                      <span style="font-size: 12px;" *ngIf="arp.nameZh && lang == 'zh'">{{arp.nameZh}} </span>
                                      {{authService.thousandSeparator(arp.amount)}}
                                    </strong>
                                  </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="product && product.auctionBidList && product.auctionBidList.length > 0">
                                <div class="d-flex product_bidder mb-3">
                                    <span class=" ">{{'productCard.biddingprice' | translate}}: </span>
                                      <div class="product-page_currency pl-2 pr-2" *ngFor="let abp of product.auctionBidList[0].auctionBidPriceList">
                                        <span style="font-size: 12px;" *ngIf="!abp.nameEn && !arp.nameZh">{{'topbar.' + abp.currency | translate}} </span>
                                        <span style="font-size: 12px;" *ngIf="abp.nameEn && lang == 'en'">{{abp.nameEn}} </span>
                                        <span style="font-size: 12px;" *ngIf="abp.nameZh && lang == 'zh'">{{abp.nameZh}} </span>
                                        {{authService.thousandSeparator(abp.amount)}}
                                      </div>
                                </div>
                            </ng-container>


                    <!-- Bidding Price End-->
                    <div class="row">
                    <div class="col-xs-12 col-sm-6">
                    <!-- Your Bidding Price-->
                            <ng-container *ngIf="product && product.yourBid">
                                <div class="product_bidder-price mb-2">
                                  <span>{{'product.yourBid' | translate}} </span>
                                  <span  *ngFor="let abp of product.yourBid.auctionBidPriceList">
                                   <b *ngIf="!abp.nameEn && !abp.nameZh">{{'topbar.' + abp.currency | translate}} {{authService.thousandSeparator(abp.amount)}}</b>
                                   <b *ngIf="abp.nameEn && lang == 'en'">{{abp.nameEn}} {{authService.thousandSeparator(abp.amount)}}</b>
                                   <b *ngIf="abp.nameZh && lang == 'zh'">{{abp.nameZh}} {{authService.thousandSeparator(abp.amount)}}</b>
                                  </span>
                                </div>
                            </ng-container>
                    <!-- Your Bidding Price End-->
                    <div class=" d-flex flex-wrap  product_bidder-count d-none d-md-block d-lg-none" >
                      <span >{{product.totalBidders}} {{'productCard.bidders' | translate}}</span>
                    </div>
                        <div class="form-group product__option mb-2" *ngIf="product.status == 'START'">
                            <div class="product__actions">

                                <div class="product__actions-item product__actions-item--addtocart w-100" *ngIf="product.start && !product.end && product.canBid && !product.top && !product.yourBid">
                                    <button *ngIf="!addingToCart" type="button" class="btn btn-primary btn-lg w-100"
                                            [ngClass]="{'btn-loading': addingToCart}"
                                            appClick (click)="createPayment('BID', false, bidPrice)"><i class="fa fa-money"></i>{{'productCard.bidNow' | translate}}</button>
                                    <button *ngIf="addingToCart" type="button" class="btn btn-primary btn-lg w-100" enabled><i class="fas fa-spinner rotating"></i></button>
                                </div>
                                <div class="product__actions-item w-100" *ngIf="!product.end && !product.canBid ">
                                    <button *ngIf="!addingToCart" type="button" class="btn btn-primary btn-lg w-100" (click)="createPayment('DEPOSIT', false, depositPrice)"><i class="fa-mon fa fa-money"></i>{{'productCard.payDeposit' | translate}}</button>
                                    <button *ngIf="addingToCart" type="button" class="btn btn-primary btn-lg w-100" enabled><i class="fas fa-spinner rotating"></i></button>
                                  </div>
                                <div>
                                <div class="product__actions-item w-100" *ngIf="product.start && !product.end && product.canBid && !product.top && product.yourBid">
                                  <button *ngIf="!addingToCart" type="button" class="btn btn-primary btn-lg w-100" (click)="createPayment('BID', false, bidPrice)">
                                  <i class="fa-mon fa fa-money"></i>{{'productCard.raiseBid' | translate}}
                                  </button>
                                  <button *ngIf="addingToCart" type="button" class="btn btn-primary btn-lg w-100" enabled><i class="fas fa-spinner rotating"></i></button>
                                </div>
                                <div>
                                </div>

                            </div>
                          </div>
                        </div>


                     </div>
                      <div class="col-xs-12 col-sm-6">

                            <ng-container>
                              <div class="product_bidder-count bidder-hidden" >
                                <span >{{product.totalBidders}} {{'productCard.bidders' | translate}}</span>
                              </div>
                              <div class="product_bidder-count pt-3 col-sm-12 container d-block d-sm-none d-md-none " *ngIf="authService.loggedIn()" style="background-color: aliceblue;">
                                <ul class="p-0">
                                  <li >{{'topbar.usdt' | translate}}: <b *ngIf="authService.usdtValue"> {{authService.usdtValue}} </b><b *ngIf="!authService.usdtValue"> 0 </b></li>
                                  <li >{{'topbar.weni' | translate}}: <b *ngIf="authService.weniTokenValue"> {{authService.weniTokenValue}} </b><b *ngIf="!authService.weniTokenValue"> 0 </b></li>
                                  <li >{{'topbar.weniPoints' | translate}}: <b *ngIf="authService.weniValue"> {{authService.weniValue}} </b><b *ngIf="!authService.weniValue"> 0 </b></li>
                                  <li >{{'topbar.usdtPoints' | translate}}: <b *ngIf="authService.usdtPointValue"> {{authService.usdtPointValue}} </b><b *ngIf="!authService.usdtPointValue"> 0 </b></li>
                                  <li >{{'topbar.pp' | translate}}: <b *ngIf="authService.ppValue"> {{authService.ppValue}} </b><b *ngIf="!authService.ppValue"> 0 </b></li>
                                  <li >{{'topbar.mlt' | translate}}: <b *ngIf="authService.mltValue"> {{authService.mltValue}} </b><b *ngIf="!authService.mltValue"> 0 </b></li>
                                  <li >{{'topbar.jdt' | translate}}: <b *ngIf="authService.jdtValue"> {{authService.jdtValue}} </b><b *ngIf="!authService.jdtValue"> 0 </b></li>
                                  <li *ngFor="let pc of authService.userCoinList">
                                    <span *ngIf="lang == 'en'">{{pc.nameEn}}</span><span *ngIf="lang == 'zh'">{{pc.nameZh}}</span> <b> {{pc.amount}} </b>
                                  </li>

                                  <div class="mt-2" ><a  style="color: #f34770;" class="topbar-link" routerLink="../../../account/reload"><i class="fas fa-retweet"></i> {{'topbar.reload' | translate}}</a></div>
                                </ul>
                              </div>
                          </ng-container>

                        </div>
                    </div>
              </div>
            </div>
          </div>
          </div>


      <div class="product__footer">
            <div class="product__tags tags">
                <div class="tags__list">
                       <i class="fas fa-info-circle mr-2 product_icon"></i>
                    <p class="pr-4 m-0">{{'productCard.bidRaisePrice' | translate}}:
                      <span *ngFor="let ai of product.auctionIncrementList">
                          <span *ngIf="!ai.nameEn && !ai.nameZh">{{'topbar.' + ai.currency | translate}} </span>
                          <span *ngIf="ai.nameEn && lang == 'en'">{{ai.nameEn}} </span>
                          <span *ngIf="ai.nameZh && lang == 'zh'">{{ai.nameZh}} </span>
                          {{authService.thousandSeparator(ai.amount)}}
                      </span>
                    </p>
                    <p class="m-0 ml-4-sm" >{{'cart.deposit' | translate}}:
                      <span  *ngFor="let ad of product.auctionDepositList">
                          <span *ngIf="!ad.nameEn && !ad.nameZh">{{'topbar.' + ad.currency | translate}} </span>
                          <span *ngIf="ad.nameEn && lang == 'en'">{{ad.nameEn}} </span>
                          <span *ngIf="ad.nameZh && lang == 'zh'">{{ad.nameZh}} </span>
                          {{authService.thousandSeparator(ad.amount)}}
                      </span>
                    </p>

                    <!-- <p class="mt-2">
                      Quick bid is an easy bidding you can bid on the fly where it will include deposit , increment of the item and the starting Price
                      also applied after someone bid it will give you a fair amount of the bid + deposit + increment. Quick bid also applied for raise your bid
                      as well
                    </p> -->
                </div>
            </div>
        </div>
      </div>
</div>
