<div class="footer-newsletter">
    <h5 class="footer-newsletter__title" *ngIf="lang == 'en' && authService.en">{{authService.en.footer.newsletter}}</h5>
    <h5 class="footer-newsletter__title" *ngIf="lang == 'zh'  && authService.zh">{{authService.zh.footer.newsletter}}</h5>
    <div class="footer-newsletter__text">
        <!-- Praesent pellentesque volutpat ex, vitae auctor lorem pulvinar mollis felis at lacinia. -->
    </div>

    <form action="" class="footer-newsletter__form">
        <label class="sr-only" for="footer-newsletter-address" *ngIf="lang == 'en' && authService.en">{{authService.en.login.email}}</label>
        <label class="sr-only" for="footer-newsletter-address" *ngIf="lang == 'zh' && authService.zh">{{authService.zh.login.email}}</label>

        <input type="text" class="footer-newsletter__form-input form-control" id="footer-newsletter-address" *ngIf="lang == 'en' && authService.en" placeholder="{{authService.en.login.email}}...">
        <input type="text" class="footer-newsletter__form-input form-control" id="footer-newsletter-address" *ngIf="lang == 'zh' && authService.zh" placeholder="{{authService.zh.login.email}}...">


        <button class="footer-newsletter__form-button btn btn-primary" *ngIf="lang == 'en' && authService.en">{{authService.en.footer.subscribe}}</button>
        <button class="footer-newsletter__form-button btn btn-primary set-width" *ngIf="lang == 'zh' && authService.zh">{{authService.zh.footer.subscribe}}</button>

    </form>

</div>
