<div class="mobilemenu" [ngClass]="{'mobilemenu--open': isOpen}">
    <div class="mobilemenu__backdrop" (click)="mobilemenu.close()"></div>
    <div class="mobilemenu__body">
        <div class="mobilemenu__header">
            <div class="mobilemenu__title">  
                 <a class="mobile-header__logo" routerLink="./home">
                     <img src="assets/images/logos/wenmall-mobile.png">
                </a>
            </div>
            <button type="button" class="mobilemenu__close" (click)="mobilemenu.close()">
                <app-icon name="cross-20" size="20"></app-icon>
            </button>
        </div>
        <div class="mobilemenu__content" *ngIf="items && items.length > 0">
            <app-mobile-links [links]="items" (itemClick)="onItemClick($event)"></app-mobile-links>
        </div>
    </div>
</div>
