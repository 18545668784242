import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-block-categories',
    templateUrl: './block-categories.component.html',
    styleUrls: ['./block-categories.component.scss']
})
export class BlockCategoriesComponent implements OnInit{
    @Input() header = {};
    @Input() layout: 'classic'|'compact' = 'classic';
    @Input() categories: any[] = [];

    lang;

    constructor(private translate: TranslateService, public authService: AuthService) { }

    ngOnInit(){
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });

      // console.log(this.categories);
    }
}
