<!-- .block-slideshow -->
<div class="block-slideshow block" [ngClass]="{'block-slideshow--layout--full': !withDepartments, 'block-slideshow--layout--with-departments': withDepartments}">
    <div class="sideshow-background"></div>
  <div class="container">
        <div class="row">
            <div *ngIf="withDepartments" class="col-3 d-lg-block d-none" appDepartmentsArea></div>
            <div class="col-12" [ngClass]="{'col-lg-12': !withDepartments, 'col-lg-9': withDepartments}">
                <div class="block-slideshow__body">
                  <owl-carousel-o [options]="options" appOwlPreventClick>
                    <ng-container *ngFor="let slide of assetList">
                        <ng-template carouselSlide *ngIf="slide.link && slide.sendToken && authService.loggedIn() && slide.newTab">
                          <a class="block-slideshow__slide" [href]="slide.link + '?token=' + authService.getToken()"  target="_blank" appClick>
                              <div class="block-slideshow__slide-image block-slideshow__slide-image--desktop"> <img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="slide.url" alt=""></div>
                              <div class="block-slideshow__slide-image block-slideshow__slide-image--mobile"> <img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="slide.url" alt=""></div>
                          </a>
                        </ng-template>
                        <ng-template carouselSlide *ngIf="slide.link && slide.sendToken && authService.loggedIn() && !slide.newTab">
                          <a  class="block-slideshow__slide" [href]="slide.link + '?token=' + authService.getToken()" appClick>
                              <div class="block-slideshow__slide-image block-slideshow__slide-image--desktop"> <img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="slide.url" alt=""></div>
                              <div class="block-slideshow__slide-image block-slideshow__slide-image--mobile"> <img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="slide.url" alt=""></div>

                          </a>
                        </ng-template>
                        <ng-template carouselSlide *ngIf="slide.link && slide.sendToken && !authService.loggedIn() && slide.newTab">
                          <a class="block-slideshow__slide" [href]="slide.link" target="_blank" appClick>
                              <div class="block-slideshow__slide-image block-slideshow__slide-image--desktop"> <img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="slide.url" alt=""></div>
                              <div class="block-slideshow__slide-image block-slideshow__slide-image--mobile"> <img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="slide.url" alt=""></div>

                          </a>
                        </ng-template>
                        <ng-template carouselSlide *ngIf="slide.link && slide.sendToken && !authService.loggedIn() && !slide.newTab">
                          <a class="block-slideshow__slide" [href]="slide.link" appClick>
                              <div class="block-slideshow__slide-image block-slideshow__slide-image--desktop"> <img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="slide.url" alt=""></div>
                              <div class="block-slideshow__slide-image block-slideshow__slide-image--mobile"> <img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="slide.url" alt=""></div>

                          </a>
                        </ng-template>
                        <ng-template carouselSlide *ngIf="slide.link && !slide.sendToken && slide.newTab">
                          <a  class="block-slideshow__slide" [href]="slide.link"  target="_blank" appClick>
                              <div class="block-slideshow__slide-image block-slideshow__slide-image--desktop"> <img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="slide.url" alt=""></div>
                              <div class="block-slideshow__slide-image block-slideshow__slide-image--mobile"> <img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="slide.url" alt=""></div>

                          </a>
                        </ng-template>
                        <ng-template carouselSlide *ngIf="slide.link && !slide.sendToken && !slide.newTab">
                          <a  class="block-slideshow__slide" [href]="slide.link" appClick>
                              <div class="block-slideshow__slide-image block-slideshow__slide-image--desktop"> <img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="slide.url" alt=""></div>
                              <div class="block-slideshow__slide-image block-slideshow__slide-image--mobile"> <img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="slide.url" alt=""></div>

                          </a>
                        </ng-template>
                        <ng-template carouselSlide *ngIf="!slide.link">

                            <div class="block-slideshow__slide">
                                <div class="block-slideshow__slide-image block-slideshow__slide-image--desktop"> <img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="slide.url" alt=""></div>
                                <div class="block-slideshow__slide-image block-slideshow__slide-image--mobile"> <img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="slide.url" alt=""></div>

                            </div>
                        </ng-template>
                    </ng-container>
                  </owl-carousel-o>

                </div>
            </div>
            <!-- <div class="col-12 col-lg-3" >

                   <div class="block-slideshow__body ">

                      <div class="card text-white bg-dark h-50 w-100 ">

                            <img src="assets/images/slides/a-side-1.jpg" class="card-img-2" alt="...">



                      </div>

                      <div class="card  text-white bg-dark h-50 w-100" >


                            <img src="assets/images/slides/a-side-2.jpg" class="card-img-2" alt="...">


                      </div>
                   </div>


            </div> -->
        </div>



    </div>
</div>
<!-- .block-slideshow / end -->
