<ul class="mobile-links mobile-links--level--{{ level }}" appCollapse>
    
    <li class="mobile-links_nav">
        <span class="mobile-links_nav--title mb-5">Categories</span>
        <hr>
        <div  *ngFor="let link of links">    
        <div *ngIf="['link', 'button', 'language'].includes(link.type)" class="mobile-links__item" appCollapseItem="mobile-links__item--open" #item="appCollapseItem">
            <div class="mobile-links__item-title nav-item">       
                <div *ngIf="link.type === 'link' && !link.requireLogin && !link.requireLogout">
                  <a *ngIf="link.type === 'link' && lang == 'en'" class="mobile-links__item-link" (click)="onItemClick(link)" [routerLink]="link.url">{{ link.en }}</a>
                  <a *ngIf="link.type === 'link' && lang == 'zh'" class="mobile-links__item-link" (click)="onItemClick(link)" [routerLink]="link.url">{{ link.zh }}</a>
                  <button *ngIf="link.children?.length" class="mobile-links__item-toggle" type="button" (click)="item.toggle()">
                      <app-icon class="mobile-links__item-arrow" name="arrow-rounded-down-12x7" size="12x7"></app-icon>
                  </button>
                </div>
            </div>
       
        </div>
     </div>
        <!-- <div *ngIf="link.type === 'divider'" class="mobile-links__divider"></div> -->
    </li>

    <li class="mobile-links_nav">
        <span class="mobile-links_nav--title mb-5">Language</span>
        <hr>
        <div  *ngFor="let link of links">    
        <div *ngIf="['link', 'button', 'language'].includes(link.type)" class="mobile-links__item" appCollapseItem="mobile-links__item--open" #item="appCollapseItem">
            <div class="mobile-links__item-title nav-item">
                <a *ngIf="link.type === 'language' && lang == 'en'" class="mobile-links__item-link" (click)="changeLanguage(link.locale)">{{ link.en }}</a>
                <a *ngIf="link.type === 'language' && lang == 'zh'" class="mobile-links__item-link" (click)="changeLanguage(link.locale)">{{ link.zh }}</a>
            </div>
     
        </div>
     </div>
        <!-- <div *ngIf="link.type === 'divider'" class="mobile-links__divider"></div> -->
    </li>

    <li class="mobile-links_nav">
        <span class="mobile-links_nav--title mb-5">Reload Balance</span>
        <hr>
        <div  *ngFor="let link of links">    
        <div *ngIf="['link', 'button', 'language'].includes(link.type)" class="mobile-links__item" appCollapseItem="mobile-links__item--open" #item="appCollapseItem">
            <div class="mobile-links__item-title nav-item">
                <div *ngIf="link.type === 'link' && link.requireLogin && authService.loggedIn()" >
                  <a *ngIf="link.type === 'link' && lang == 'en' && link.en != 'Logout'" class="mobile-links__item-link" (click)="onItemClick(link)" [routerLink]="link.url">{{ link.en }}</a>
                  <a *ngIf="link.type === 'link' && lang == 'zh' && link.en != 'Logout'" class="mobile-links__item-link" (click)="onItemClick(link)" [routerLink]="link.url">{{ link.zh }}</a>              
                </div>
            </div>
       
        </div>
     </div>
        <!-- <div *ngIf="link.type === 'divider'" class="mobile-links__divider"></div> -->
    </li>



    <li>
        <span class="mobile-links_nav--title mb-5">My Account</span>
        <hr>
        <div  *ngFor="let link of links">    
        <div *ngIf="['link', 'button', 'language'].includes(link.type)" class="mobile-links__item" appCollapseItem="mobile-links__item--open" #item="appCollapseItem">
            <div class="mobile-links__item-title nav-item">
                <div *ngIf="link.type === 'link' && link.requireLogin && authService.loggedIn()">
                  <button *ngIf="link.children?.length" class="mobile-links__item-toggle" type="button" (click)="item.toggle()">
                      <app-icon class="mobile-links__item-arrow" name="arrow-rounded-down-12x7" size="12x7"></app-icon>
                  </button>
                </div>       
            </div>
         
        </div>
     </div>
        <!-- <div *ngIf="link.type === 'divider'" class="mobile-links__divider"></div> -->
    </li>


    
</ul>
