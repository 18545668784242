<!-- .block-brands -->
<div class="block block-brands">
    <div class="container">
        <div class="block-brands__slider">
            <owl-carousel-o [options]="carouselOptions" appOwlPreventClick>
                <ng-container *ngFor="let brand of brands">
                    <ng-template carouselSlide>
                        <div class="block-brands__item text-center" [routerLink]="brand.routerLink">
                            <a href=""><img [src]="brand.url" alt=""></a>
                            <span class="brand-name" *ngIf="lang == 'en'">{{brand.en}}</span>
                            <span class="brand-name" *ngIf="lang == 'zh'">{{brand.zh}}</span>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</div>
<!-- .block-brands / end -->
