<!-- .block-products -->
<div class="block block-products block-products--layout--{{ layout }}">
    <div class="container">
        <app-block-header [header]="header"></app-block-header>

        <div class="block-products__body">


            <ng-template #smallsTemplate>
                <div *ngIf="smalls.length > 0" class="block-products__list flex-grow-1">
                    <div *ngFor="let product of smalls" class="block-products__list-item">
                        <app-custom-product-card [product]="product" style="width: calc(100% - -12px);"></app-custom-product-card>
                    </div>
                </div>
            </ng-template>

            <ng-container *ngIf="layout === 'large-last'" [ngTemplateOutlet]="smallsTemplate"></ng-container>
            <!-- <ng-container [ngTemplateOutlet]="largeTemplate"></ng-container> -->
            <ng-container *ngIf="layout === 'large-first'" [ngTemplateOutlet]="smallsTemplate"></ng-container>
        </div>
    </div>
</div>
<!-- .block-products / end -->
