import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, QueryList, ViewChild, ViewChildren, Output, EventEmitter } from '@angular/core';
import { Product } from '../../interfaces/product';
import { CarouselComponent, SlidesOutputData } from 'ngx-owl-carousel-o';
import { FormControl } from '@angular/forms';
import { CartService } from '../../services/cart.service';
import { WishlistService } from '../../services/wishlist.service';
import { CompareService } from '../../services/compare.service';
import { isPlatformBrowser } from '@angular/common';
import { OwlCarouselOConfig } from 'ngx-owl-carousel-o/lib/carousel/owl-carousel-o-config';
import { PhotoSwipeService } from '../../services/photo-swipe.service';
import { DirectionService } from '../../services/direction.service';
import { AuthService } from '../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuickviewService } from '../../services/quickview.service';
import { MatDialog } from '@angular/material/dialog';
import { AuctionPriceComponent } from '../../dialog/auction-price/auction-price.component';
import { Observable, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


interface ProductImage {
    id: string;
    url: string;
    active: boolean;
}

export type Layout = 'standard'|'sidebar'|'columnar'|'quickview';

@Component({
    selector: 'app-custom-product',
    templateUrl: './custom-product.component.html',
    styleUrls: ['./custom-product.component.scss']
})
export class CustomProductComponent implements OnInit {
    private dataProduct: any;
    private dataLayout: Layout = 'standard';

    faTimes = faTimes;

    showGallery = true;
    showGalleryTimeout: number;
    lang = "";

    attributeList: any[] = [];
    selectedAvList = [];
    countryList = [];
    selectedCountry;
    shippingFee;

    depositPrice = null;
    bidPrice = null;

    counter$: Observable<number>;
    count = null;
    day = false;

    @ViewChild('featuredCarousel', { read: CarouselComponent }) featuredCarousel: CarouselComponent;
    @ViewChild('thumbnailsCarousel', { read: CarouselComponent }) thumbnailsCarousel: CarouselComponent;
    @ViewChildren('imageElement', {read: ElementRef}) imageElements: QueryList<ElementRef>;

    @Output() closeQuickview = new EventEmitter<string>();

    @Input() set layout(value: Layout) {
        this.dataLayout = value;

        if (isPlatformBrowser(this.platformId)) {
            // this dirty hack is needed to re-initialize the gallery after changing the layout
            clearTimeout(this.showGalleryTimeout);
            this.showGallery = false;
            this.showGalleryTimeout = window.setTimeout(() => {
                this.showGallery = true;
            }, 0);
        }
    }
    get layout(): Layout {
        return this.dataLayout;
    }

    @Input() set product(value: any) {
        this.dataProduct = value;
        try{
          this.images = value.auctionImageList.length > 0 ? this.dataProduct.auctionImageList.map((image, index) => {
              return {
                  id: image.id.toString(),
                  url: image.url,
                  active: index === 0
              };
          }) : [];

          if(this.images.length == 0){
            var placeholder = {
              id: "1",
              url: "/assets/images/placeholder.png",
              active: true
            };
            this.images.push(placeholder);
          }

          try{
            var d1 = new Date();
            if(this.product.timestamp){
              d1 = new Date(this.product.timestamp);
            }
            var d2 = null;
            if(this.dataProduct.status == "ACTIVE"){
                if(this.dataProduct.auctionStartDate){
                  d2 = new Date(this.dataProduct.auctionStartDate);
                }
            }else if(this.dataProduct.status == "START"){
                if(this.dataProduct.auctionEndDate){
                  d2 = new Date(this.dataProduct.auctionEndDate);
                }
            }
            if(d2){
              var dif = d2.getTime() - d1.getTime();
              dif = dif / 1000;
              if(dif < 0){
                dif = 0;
              }
              this.count = dif;
              if(this.count > 86400){
                this.count -= 86400;
                this.day = true;
              }else{
                this.day = false;
              }
            }
          }catch(err){

          }
        }catch(err){
          // console.log(err);
        }
    }
    get product(): any {
        return this.dataProduct;
    }

    images: ProductImage[] = [];

    carouselOptions: Partial<OwlCarouselOConfig> = {
        dots: false,
        autoplay: false,
        responsive: {
            0: {items: 1}
        },

        rtl: this.direction.isRTL()
    };

    thumbnailsCarouselOptions: Partial<OwlCarouselOConfig> = {
        dots: false,
        autoplay: false,
        margin: 10,
        items: 5,
        nav: true,
        navText: [ '<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>' ],
        responsive: {
            480: {items: 5},
            380: {items: 4},
              0: {items: 3}
        },
        rtl: this.direction.isRTL()
    };

    quantity: FormControl = new FormControl(1);

    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private cart: CartService,
        private wishlist: WishlistService,
        private compare: CompareService,
        private photoSwipe: PhotoSwipeService,
        private direction: DirectionService,
        private translate: TranslateService,
        public authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private quickview: QuickviewService,
        public dialog: MatDialog
    ) {

    }

    ngOnInit(): void {
        this.cart.loading$.subscribe(product => {
            this.addingToCart = false;
        });

        this.getAllCountries();
        this.lang = this.authService.getLocale();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
          this.lang = event.lang;
        });
        if (this.layout !== 'quickview' && isPlatformBrowser(this.platformId)) {
            this.photoSwipe.load().subscribe();
        }
    }

    setActiveImage(image: ProductImage): void {
        this.images.forEach(eachImage => eachImage.active = eachImage === image);
    }

    featuredCarouselTranslated(event: SlidesOutputData): void {
        if (event.slides.length) {
            const activeImageId = event.slides[0].id;

            this.images.forEach(eachImage => eachImage.active = eachImage.id === activeImageId);

            if (!this.thumbnailsCarousel.slidesData.find(slide => slide.id === event.slides[0].id && slide.isActive)) {
                this.thumbnailsCarousel.to(event.slides[0].id);
            }
        }
    }

    bidNow(): void {

    }

    openPhotoSwipe(event: MouseEvent, image: ProductImage): void {
        if (this.layout !== 'quickview') {
            event.preventDefault();

            const images = this.images.map(eachImage => {
                return {
                    src: eachImage.url,
                    msrc: eachImage.url,
                    w: 700,
                    h: 700
                };
            });
            const options = {
                getThumbBoundsFn: index => {
                    const imageElement = this.imageElements.toArray()[index].nativeElement;
                    const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
                    const rect = imageElement.getBoundingClientRect();

                    return {x: rect.left, y: rect.top + pageYScroll, w: rect.width};
                },
                index: this.images.indexOf(image),
                bgOpacity: .9,
                history: false
            };

            this.photoSwipe.open(images, options).subscribe(galleryRef => {
                galleryRef.listen('beforeChange', () => {
                    this.featuredCarousel.to(this.images[galleryRef.getCurrentIndex()].id);
                });
            });
        }
    }

    getAllCountries(){
      this.authService.getAllCountries().subscribe(
          data => {
            if(data['status'] == 'ok'){
              this.countryList = data['result'];
              try{
                this.selectedCountry = this.countryList[0];
                // this.changeCountry();
              }catch(err){

              }
            }else if(data['status'] == 'error'){
              this.authService.showToast("danger", data['errorMessage']);
            }else{
              this.authService.showToast("danger", this.authService.getTranslation("message.generalError"));
            }
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }

    // changeCountry(){
    //   var found = false;
    //   this.product.productShippingFeeList.forEach(psf => {
    //     try{
    //       if(psf.country.id == this.selectedCountry.id && !found){
    //         found = true;
    //         this.shippingFee = psf.shippingFee;
    //       }
    //     }catch(err){
    //       console.log(err);
    //     }
    //   });
    //   if(!found){
    //     this.shippingFee = null;
    //   }
    // }

    goToMerchant(storeId){
      try{
        // this.closeQuickview.next('somePhone');
      }catch(err){

      }
      this.router.navigate(['/classic/shop/merchant', storeId]);
    }

    createPayment(type, confirm, ap){
      console.log(ap);
      if(this.addingToCart){
        return;
      }
      if(!this.authService.loggedIn()){
        this.router.navigate(['/classic/account/login']);
        return;
      }
      this.addingToCart = true;
      this.authService.createPayment(this.product.id, type, confirm, ap).subscribe(
          data => {
            // console.log(data);
            if(data['status'] == 'ok'){
              this.depositPrice = null;
              this.bidPrice = null;
              this.authService.refreshProduct(this.product.id);
              this.authService.refreshBalance();
            }else if(data['status'] == 'info'){
              this.authService.refreshProduct(this.product.id);
              if(type == "DEPOSIT"){
                this.depositPrice = data['result'];
                console.log(this.depositPrice);
              }else if(type == "BID"){
                this.bidPrice = data['result'];
              }
              this.openDialog(type, data);
            }else if(data['status'] == 'error'){
              this.authService.showToast("danger", data['errorMessage']);
            }else{
              this.authService.showToast("danger", this.authService.getTranslation("message.generalError"));
            }
            this.authService.complete();
            this.addingToCart = false;
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
            this.addingToCart = false;
          }
        );
    }

    openDialog(type, res): void {
      const dialogRef = this.dialog.open(AuctionPriceComponent, {
        width: '450px',
        data: {
          type: type,
          result: res
        },
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);
        if(result && result.type){
          var price = null;
          if(result.type == "DEPOSIT"){
            price = this.depositPrice;
          }else if(result.type == "BID"){
            price = this.bidPrice;
          }
          this.createPayment(result.type, true, price);
        }
      });
    }

    parseCountdown(cd){
      var day = null;
      var hour = null;
      var min = null;
      var sec = null;
      if(cd < 60) { //seconds
        sec = cd;
      }else if(cd < 3600) { // minutes and seconds
        min = cd / 60;
        sec = cd % 60;

      }else if(cd < 86400) { //hours, minutes and seconds
        hour = cd / 3600;
  			var cd2 = cd % 3600;
  			min = cd2 / 60;
  			sec = cd2 % 60;
      }else { //days, hours, minutes, and seconds
        day = cd / 86400;
  			var cd2 = cd % 86400;
  			hour = cd2 / 3600;
  			var cd3 = cd2 % 3600;
  			min = cd3 / 60;
  			sec = cd3 % 60;
      }

      var str = "";
      if(this.lang == 'en'){
        if(day && day >= 1){
          str += Math.floor(day) + "d";
        }
        if(hour && hour >= 1){
          str += " " + Math.floor(hour) + "h";
        }
        if(min && min >= 1){
          str += " " + Math.floor(min) + "m";
        }
        if(sec && sec >= 1){
          str += " " + Math.floor(sec) + "s";
        }
      }else if(this.lang == 'zh'){
        if(day && day >= 1){
          str += Math.floor(day) + " 天";
        }
        if(hour && hour >= 1){
          str += " " + Math.floor(hour) + " 小时";
        }
        if(min && min >= 1){
          str += " " + Math.floor(min) + " 分钟";
        }
        if(sec && sec >= 1){
          str += " " + Math.floor(sec) + " 秒";
        }
      }
      return str;
    }

    goToBrand(brandId){
      this.router.navigate(['/classic/shop/category-grid-3-columns-sidebar', "brand-" + brandId]);
    }
}
