import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { posts } from '../../../data/blog-posts';
import { brands } from '../../../data/shop-brands';
import { products } from '../../../data/shop-products';
// import { categories } from '../../../data/shop-block-categories';
import { AuthService } from '../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Category } from '../../../app/shared/interfaces/category';
import { Product } from '../../../app/shared/interfaces/product';

import { ScrollEvent } from 'ngx-scroll-event';

import { AfterViewInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { NoticeComponent } from '../../shared/dialog/notice/notice.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';



@Component({
    selector: 'app-home',
    templateUrl: './page-home-one.component.html',
    styleUrls: ['./page-home-one.component.scss'],
  	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHomeOneComponent implements OnInit, AfterViewInit, OnDestroy{
    loading = true;
    products = products;
    usdtBanner;
    usdpBanner;
    slideList = [];
    // categories = categories;
    categories = []; //banner categories (popular categories)

    bannerCategoryLoad = false;
    posts = posts;
    brands = brands;
    showsidebar = false;
    public pageYOffset :number = -200;
    lang = "";
    categoryList: Category[] = [
       {url: 'assets/images/logos/logo-1.png', name:"womensFashion", en:'', zh:'', routerLink: '../shop/27'},
       {url: 'assets/images/logos/logo-2.png', name:"mensFashion", en:'', zh:'', routerLink: '../shop/28'},
       {url: 'assets/images/logos/logo-11.png', name:"fashionAccessories", en:'', zh:'', routerLink: '../shop/38'},
       {url: 'assets/images/logos/logo-3.png', name:"shoesAndBags", en:'', zh:'', routerLink: '../shop/30' },

       {url: 'assets/images/logos/logo-4.png', name:"sportsAndOutdoors", en:'', zh:'', routerLink: '../shop/31'},
       {url: 'assets/images/logos/logo-5.png', name:"homeAndLifestyle", en:'', zh:'', routerLink: '../shop/32'},
       {url: 'assets/images/logos/logo-6.png', name:"electronic", en:'', zh:'', routerLink: '../shop/33'},
       {url: 'assets/images/logos/logo-7.png', name:"tvAndHomeAppliances", en:'', zh:'', routerLink: '../shop/34'},
       {url: 'assets/images/logos/logo-8.png', name:"babiesAndToys", en:'', zh:'', routerLink: '../shop/35'},
       {url: 'assets/images/logos/logo-9.png', name:"healthAndBeauty", en:'', zh:'', routerLink: '../shop/36'},
       {url: 'assets/images/logos/logo-10.png', name:"weniSpecial", en:'', zh:'', routerLink: '../shop/37'},

   ];
   pendingUsdtList = [];
   startUsdtList = [];
   pendingUsdpList = [];
   startUsdpList = [];
   pendingList = [];
   startList = [];
   endList = [];

   notActiveIdList = [];
   notStartIdList = [];
   randomActiveList = [];
   randomStartList = [];

   waitPendingUsdtList = [];
   waitStartUsdtList = [];
   waitPendingUsdpList = [];
   waitStartUsdpList = [];

   foreverPendingUsdtList = [];
   foreverStartUsdtList = [];
   foreverPendingUsdpList = [];
   foreverStartUsdpList = [];

    @ViewChild('top') topElement: ElementRef;
    @ViewChild('sld') sldElement: ElementRef;
    @ViewChild('fp') fpElement: ElementRef;
    @ViewChild('bs') bsElement: ElementRef;
    @ViewChild('pc') pcElement: ElementRef;
    @ViewChild('na') naElement: ElementRef;
    @ViewChild('trp') trpElement: ElementRef;

    public currentActive = 0;
    public topOffset: Number = null;
    public sldOffset: Number = null;
    public fpOffset: Number = null;
    public bsOffset: Number = null;
    public pcOffset: Number = null;
    public naOffset: Number = null;
    public trpOffset: Number = null;
    interval;

    columns = [
        // {
        //     header: 'topRatedProducts',
        //     products: list4.slice(0, 3),
        //     en: "",
        //     zh: ""
        // },
        // {
        //     header: 'specialOffers',
        //     products: list5.slice(3, 6),
        //     en: "",
        //     zh: ""
        // },
        // {
        //     header: 'bestsellers',
        //     products: list6.slice(6, 9),
        //     en: "",
        //     zh: ""
        // }
    ];

    productList: any[] = [];

    normalTab = false;
    waitTab = false;
    foreverTab = false;

    ngOnInit(){
      this.route.queryParams.subscribe(params => {
          if(params['token'] != undefined && params['token'].length > 0){
            this.authService.setToken(params['token']);
            this.getProfile();
          }else if(params['sessionId'] != undefined && params['sessionId'].length > 0){
            this.authService.loadingSso = true;
            this.checkSso(params['sessionId']);
          }
      });

      if(this.authService.first){
        // this.showNoticeDialog();
        this.authService.first = false;
      }

      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
        // this.authService.setLocale(this.lang);
      });

      // this.initTranslate();
      //
      // this.getCategoryList();

      // this.initTranslate();
      this.getHomeProductList();
      this.startInterval();
      // this.getRandomList("START");

    }

    clearPosParam() {
      this.authService.router.navigate(
        ['.'],
        { relativeTo: this.route, queryParams: { } }
      );
    }

    ngAfterViewInit()

     {
        // this.topOffset = this.topElement.nativeElement.offsetTop;

        // this.topOffset = 0;
        // this.fpOffset = this.fpElement.nativeElement.offsetTop;
        // this.bsOffset = this.bsElement.nativeElement.offsetTop;
        // this.pcOffset = this.pcElement.nativeElement.offsetTop;
        // this.naOffset = this.naElement.nativeElement.offsetTop;
        // this.trpOffset = this.trpElement.nativeElement.offsetTop;
      }


  @HostListener('window:scroll', ['$event'])
  checkOffsetTop() {
    // console.log("window.pageYOffset : " + window.pageYOffset);
    // if (window.pageYOffset>= this.topOffset && window.pageYOffset < this.fpOffset) {
    //   console.log("1");
    //   this.currentActive = 1;
    // } else if (window.pageYOffset>= this.fpOffset && window.pageYOffset < this.bsOffset) {
    //   console.log("2");
    //   this.currentActive = 2;
    // } else if (window.pageYOffset>= this.bsOffset && window.pageYOffset < this.pcOffset) {
    //   console.log("3");
    //   this.currentActive = 3;
    // } else if (window.pageYOffset>= this.pcOffset && window.pageYOffset < this.naOffset) {
    //   console.log("4");
    //   this.currentActive = 4;
    // } else if (window.pageYOffset >= this.naOffset && window.pageYOffset < this.trpOffset )  {
    //   console.log("5");
    //   this.currentActive = 5;
    // } else if (window.pageYOffset  >= this.trpOffset )  {
    //   console.log("6");
    //     this.currentActive = 6;
    // } else {
    //   console.log("0");
    //   this.currentActive = 0;
    // }
  }


    featuredProducts = {
        loading: false,
        products: products.slice(),
        groups: [

            // {name: 'All', current: true},
            // {name: 'Power Tools', current: false},
            // {name: 'Hand Tools', current: false},
            // {name: 'Plumbing', current: false}

        ],

        timeout: null, // only for demo

        groupChange: group => {
            // only for demo
            this.featuredProducts.loading = true;

            clearTimeout(this.featuredProducts.timeout);

            this.featuredProducts.timeout = setTimeout(() => {
                const itemsArray = this.featuredProducts.products;
                const newItemsArray = [];
                while (itemsArray.length > 0) {
                    const randomIndex = Math.floor(Math.random() * itemsArray.length);
                    const randomItem = itemsArray.splice(randomIndex, 1)[0];
                    newItemsArray.push(randomItem);
                }
                this.featuredProducts.products = newItemsArray;
                this.featuredProducts.loading = false;
            }, 1000);
        }
    };

    newArrivals = {
        loading: false,
        products: products.slice(),
        groups: [
            // {name: 'All', current: true},
            // {name: 'Power Tools', current: false},
            // {name: 'Hand Tools', current: false},
            // {name: 'Plumbing', current: false}
        ],

        timeout: null, // only for demo

        groupChange: group => {
            // only for demo
            this.newArrivals.loading = true;

            clearTimeout(this.newArrivals.timeout);

            this.newArrivals.timeout = setTimeout(() => {
                const itemsArray = this.newArrivals.products;
                const newItemsArray = [];
                while (itemsArray.length > 0) {
                    const randomIndex = Math.floor(Math.random() * itemsArray.length);
                    const randomItem = itemsArray.splice(randomIndex, 1)[0];
                    newItemsArray.push(randomItem);
                }
                this.newArrivals.products = newItemsArray;
                this.newArrivals.loading = false;
            }, 1000);
        }
    };


    popularAuctionItemHeader = {
      header: "popularAuctionItem",
      en: "",
      zh: "",
    };

    liveAuctionItemHeader = {
      header: "liveAuctionItem",
      en: "",
      zh: "",
    };

    endAuctionItemHeader = {
      header: "endAuctionItem",
      en: "",
      zh: "",
    };

    bestsellersHeader = {
      header: "bestsellers",
      en: "",
      zh: "",
    };

    popularCategoriesHeader = {
      header: "popularCategories",
      en: "",
      zh: "",
    };

    newArrivalsHeader = {
      header: "newArrivals",
      en: "",
      zh: "",
    };

    superLowDealHeader = {
      header: "superLowDeal",
      en: "",
      zh: "",
    };

    wenProductHeader = {
      header: "wenProduct",
      en: "",
      zh: "",
    };

    weiniSpecialHeader = {
      header: "weiniSpecial",
      en: "",
      zh: "",
    };

    pendingHeader = {
      header: "pendingAuction",
      en: "",
      zh: "",
    };

    startHeader = {
      header: "startAuction",
      en: "",
      zh: "",
    };

    endHeader = {
      header: "endAuction",
      en: "",
      zh: "",
    };

    pendingUsdtHeader = {
      header: "pendingUsdtHeader",
      en: "",
      zh: "",
    };

    startUsdtHeader = {
      header: "startUsdtHeader",
      en: "",
      zh: "",
    };

    pendingUsdpHeader = {
      header: "pendingUsdpHeader",
      en: "",
      zh: "",
    };

    startUsdpHeader = {
      header: "startUsdpHeader",
      en: "",
      zh: "",
    };

    mayLikeHeader = {
      header: "mayLike",
      en: "",
      zh: "",
    };

    scrollToTop(){
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }


    scroll(el: HTMLElement) {
        el.scrollIntoView({  behavior: 'smooth' });
     }

     public handleScroll(event: ScrollEvent) {

        if ( event.isReachingTop  )  {
          this.showsidebar=false;
        }

        else if ( event.isReachingBottom ) {
            this.showsidebar=true;
        }
      }


    constructor(private translate: TranslateService, public authService: AuthService, public dialog: MatDialog, private route: ActivatedRoute, private changeDetectorRefs: ChangeDetectorRef) { }

    ngOnDestroy() {
      this.stopInterval();
      this.changeDetectorRefs.detach();
    }

    detectChange(){
      if(!this.changeDetectorRefs['destroyed']){
       this.changeDetectorRefs.detectChanges();
      }
    }

    initTranslate(){
      // this.translate.getTranslation("zh").subscribe(res => {
      //   this.authService.zh = res;
      //   this.columns.forEach(item => {
      //     item.zh = this.authService.zh.home[item.header];
      //   });
      //   this.categoryList.forEach(item => {
      //     item.zh = this.authService.zh.departmentsData[item.name];
      //   });
      //   this.featuredProductsHeader.zh = this.authService.zh.home[this.featuredProductsHeader.header];
      //   this.bestsellersHeader.zh = this.authService.zh.home[this.bestsellersHeader.header];
      //   this.popularCategoriesHeader.zh = this.authService.zh.home[this.popularCategoriesHeader.header];
      //   this.newArrivalsHeader.zh = this.authService.zh.home[this.newArrivalsHeader.header];
      // });
      // this.translate.getTranslation("en").subscribe(res => {
      //   this.authService.en = res;
      //   this.columns.forEach(item => {
      //     item.en = this.authService.en.home[item.header];
      //   });
      //   this.categoryList.forEach(item => {
      //     item.en = this.authService.en.departmentsData[item.name];
      //   });
      //   this.featuredProductsHeader.en = this.authService.en.home[this.featuredProductsHeader.header];
      //   this.bestsellersHeader.en = this.authService.en.home[this.bestsellersHeader.header];
      //   this.popularCategoriesHeader.en = this.authService.en.home[this.popularCategoriesHeader.header];
      //   this.newArrivalsHeader.en = this.authService.en.home[this.newArrivalsHeader.header];
      // });



      this.columns.forEach(item => {
        item.zh = this.authService.zh.home[item.header];
      });
      this.categoryList.forEach(item => {
        item.zh = this.authService.zh.departmentsData[item.name];
      });
      this.popularAuctionItemHeader.zh = this.authService.zh.home[this.popularAuctionItemHeader.header];
      this.liveAuctionItemHeader.en = this.authService.zh.home[this.liveAuctionItemHeader.header];
      this.endAuctionItemHeader.zh = this.authService.zh.home[this.endAuctionItemHeader.header];
      this.bestsellersHeader.zh = this.authService.zh.home[this.bestsellersHeader.header];
      this.popularCategoriesHeader.zh = this.authService.zh.home[this.popularCategoriesHeader.header];
      this.newArrivalsHeader.zh = this.authService.zh.home[this.newArrivalsHeader.header];
      this.superLowDealHeader.zh = this.authService.zh.home[this.superLowDealHeader.header];
      this.wenProductHeader.zh = this.authService.zh.home[this.wenProductHeader.header];
      this.weiniSpecialHeader.zh = this.authService.zh.home[this.weiniSpecialHeader.header];
      this.pendingHeader.zh = this.authService.zh.home[this.pendingHeader.header];
      this.startHeader.zh = this.authService.zh.home[this.startHeader.header];
      this.endHeader.zh = this.authService.zh.home[this.endHeader.header];
      this.pendingUsdtHeader.zh = this.authService.zh.home[this.pendingUsdtHeader.header];
      this.startUsdtHeader.zh = this.authService.zh.home[this.startUsdtHeader.header];
      this.pendingUsdpHeader.zh = this.authService.zh.home[this.pendingUsdpHeader.header];
      this.startUsdpHeader.zh = this.authService.zh.home[this.startUsdpHeader.header];
      this.mayLikeHeader.zh = this.authService.zh.product[this.mayLikeHeader.header];



      this.columns.forEach(item => {
        item.en = this.authService.en.home[item.header];
      });
      this.categoryList.forEach(item => {
        item.en = this.authService.en.departmentsData[item.name];
      });
      this.popularAuctionItemHeader.en = this.authService.en.home[this.popularAuctionItemHeader.header];
      this.liveAuctionItemHeader.en = this.authService.en.home[this.liveAuctionItemHeader.header];
      this.endAuctionItemHeader.en = this.authService.en.home[this.endAuctionItemHeader.header];
      this.bestsellersHeader.en = this.authService.en.home[this.bestsellersHeader.header];
      this.popularCategoriesHeader.en = this.authService.en.home[this.popularCategoriesHeader.header];
      this.newArrivalsHeader.en = this.authService.en.home[this.newArrivalsHeader.header];
      this.superLowDealHeader.en = this.authService.en.home[this.superLowDealHeader.header];
      this.wenProductHeader.en = this.authService.en.home[this.wenProductHeader.header];
      this.weiniSpecialHeader.en = this.authService.en.home[this.weiniSpecialHeader.header];
      this.pendingHeader.en = this.authService.en.home[this.pendingHeader.header];
      this.startHeader.en = this.authService.en.home[this.startHeader.header];
      this.endHeader.en = this.authService.en.home[this.endHeader.header];
      this.pendingUsdtHeader.en = this.authService.en.home[this.pendingUsdtHeader.header];
      this.startUsdtHeader.en = this.authService.en.home[this.startUsdtHeader.header];
      this.pendingUsdpHeader.en = this.authService.en.home[this.pendingUsdpHeader.header];
      this.startUsdpHeader.en = this.authService.en.home[this.startUsdpHeader.header];
      this.mayLikeHeader.en = this.authService.en.product[this.mayLikeHeader.header];

    }

    getHomeProductList(){
      this.authService.start();
      this.authService.getHomeProductList().subscribe(
          data => {
            if(data['status'] == 'ok'){
              this.loading = false;

              var assets = data['result'].assetList;
              // this.slideList = [];
              var tempSlideList = [];
              var tempUsdtBanner;
              var tempUsdpBanner;
              assets.slice().reverse().forEach(a => {
                if(a.type == "SLIDE"){
                  // this.slideList.push(a);
                  tempSlideList.push(a);
                }else if(a.type == "USDT"){
                  // this.usdtBanner = a;
                  tempUsdtBanner = a;
                }else if(a.type == "USDP"){
                  // this.usdpBanner = a;
                  tempUsdpBanner = a;
                }
              });

              if(this.slideList.length != tempSlideList.length){
                this.slideList = tempSlideList;
              }
              if(this.usdtBanner != tempUsdtBanner){
                this.usdtBanner = tempUsdtBanner;
              }
              if(this.usdpBanner != tempUsdpBanner){
                this.usdpBanner = tempUsdpBanner;
              }

              // this.pendingUsdtList = data['result'].pendingUsdtList;
              // this.startUsdtList = data['result'].startUsdtList;
              // this.pendingUsdpList = data['result'].pendingUsdpList;
              // this.startUsdpList = data['result'].startUsdpList;
              // this.pendingUsdtList.forEach(p => {
              //   p.routerLink = '../shop/custom-product/' + p.id;
              // });
              // this.startUsdtList.forEach(p => {
              //   p.routerLink = '../shop/custom-product/' + p.id;
              // });
              // this.pendingUsdpList.forEach(p => {
              //   p.routerLink = '../shop/custom-product/' + p.id;
              // });
              // this.startUsdpList.forEach(p => {
              //   p.routerLink = '../shop/custom-product/' + p.id;
              // });

              var tempPendingUsdtList = this.authService.parseAuctionList(data['result'].pendingUsdtList);
              var tempStartUsdtList = this.authService.parseAuctionList(data['result'].startUsdtList);
              var tempPendingUsdpList = this.authService.parseAuctionList(data['result'].pendingUsdpList);
              var tempStartUsdpList = this.authService.parseAuctionList(data['result'].startUsdpList);

              var tempWaitPendingUsdtList = this.authService.parseAuctionList(data['result'].waitPendingUsdtList);
              var tempWaitStartUsdtList = this.authService.parseAuctionList(data['result'].waitStartUsdtList);
              var tempWaitPendingUsdpList = this.authService.parseAuctionList(data['result'].waitPendingUsdpList);
              var tempWaitStartUsdpList = this.authService.parseAuctionList(data['result'].waitStartUsdpList);

              var tempForeverPendingUsdtList = this.authService.parseAuctionList(data['result'].foreverPendingUsdtList);
              var tempForeverStartUsdtList = this.authService.parseAuctionList(data['result'].foreverStartUsdtList);
              var tempForeverPendingUsdpList = this.authService.parseAuctionList(data['result'].foreverPendingUsdpList);
              var tempForeverStartUsdpList = this.authService.parseAuctionList(data['result'].foreverStartUsdpList);

              tempPendingUsdtList.forEach(p => {
                p.routerLink = '../shop/custom-product/' + p.id;
              });
              tempStartUsdtList.forEach(p => {
                p.routerLink = '../shop/custom-product/' + p.id;
              });
              tempPendingUsdpList.forEach(p => {
                p.routerLink = '../shop/custom-product/' + p.id;
              });
              tempStartUsdpList.forEach(p => {
                p.routerLink = '../shop/custom-product/' + p.id;
              });

              tempWaitPendingUsdtList.forEach(p => {
                p.routerLink = '../shop/custom-product/' + p.id;
              });
              tempWaitStartUsdtList.forEach(p => {
                p.routerLink = '../shop/custom-product/' + p.id;
              });
              tempWaitPendingUsdpList.forEach(p => {
                p.routerLink = '../shop/custom-product/' + p.id;
              });
              tempWaitStartUsdpList.forEach(p => {
                p.routerLink = '../shop/custom-product/' + p.id;
              });

              tempForeverPendingUsdtList.forEach(p => {
                p.routerLink = '../shop/custom-product/' + p.id;
              });
              tempForeverStartUsdtList.forEach(p => {
                p.routerLink = '../shop/custom-product/' + p.id;
              });
              tempForeverPendingUsdpList.forEach(p => {
                p.routerLink = '../shop/custom-product/' + p.id;
              });
              tempForeverStartUsdpList.forEach(p => {
                p.routerLink = '../shop/custom-product/' + p.id;
              });

              var change = false;

              //normal
              if(this.pendingUsdtList.length != tempPendingUsdtList.length){
                this.pendingUsdtList = [];
                this.detectChange();
                change = true;
                this.pendingUsdtList = tempPendingUsdtList;
              }else{
                try{
                  var dif = false;
                  tempPendingUsdtList.forEach((a, index) => {
                    if(!dif){
                      var a2 = this.pendingUsdtList[index];
                      if(a.id != a2.id || a.status != a2.status || a.auctionEndDate != a2.auctionEndDate || a.auctionStartDate != a2.auctionStartDate){
                        dif = true;
                      }
                      if(!dif){
                        var abList1 = a.auctionBidList;
                        var abList2 = a2.auctionBidList;
                        if(abList1.length != abList2.length || (abList1.length > 0 && abList2.length > 0 && abList1[0].id != abList2[0].id)){
                          dif = true;
                        }
                      }
                    }
                  });
                  if(dif){
                    this.pendingUsdtList = [];
                    this.detectChange();
                    change = true;
                    this.pendingUsdtList = tempPendingUsdtList;
                  }
                }catch(err){
                  this.pendingUsdtList = [];
                  this.detectChange();
                  change = true;
                  this.pendingUsdtList = tempPendingUsdtList;
                }
              }

              if(this.startUsdtList.length != tempStartUsdtList.length){
                this.startUsdtList = [];
                this.detectChange();
                change = true;
                this.startUsdtList = tempStartUsdtList;
              }else{
                try{
                  var dif = false;
                  tempStartUsdtList.forEach((a, index) => {
                    if(!dif){
                      var a2 = this.startUsdtList[index];
                      if(a.id != a2.id || a.status != a2.status || a.auctionEndDate != a2.auctionEndDate || a.auctionStartDate != a2.auctionStartDate){
                        dif = true;
                      }
                      if(!dif){
                        var abList1 = a.auctionBidList;
                        var abList2 = a2.auctionBidList;
                        if(abList1.length != abList2.length || (abList1.length > 0 && abList2.length > 0 && abList1[0].id != abList2[0].id)){
                          dif = true;
                        }
                      }
                    }
                  });
                  if(dif){
                    this.startUsdtList = [];
                    this.detectChange();
                    change = true;
                    this.startUsdtList = tempStartUsdtList;
                  }
                }catch(err){
                  this.startUsdtList = [];
                  this.detectChange();
                  change = true;
                  this.startUsdtList = tempStartUsdtList;
                }
              }

              if(this.pendingUsdpList.length != tempPendingUsdpList.length){
                this.pendingUsdpList = [];
                this.detectChange();
                change = true;
                this.pendingUsdpList = tempPendingUsdpList;
              }else{
                try{
                  var dif = false;
                  tempPendingUsdpList.forEach((a, index) => {
                    if(!dif){
                      var a2 = this.pendingUsdpList[index];
                      if(a.id != a2.id || a.status != a2.status || a.auctionEndDate != a2.auctionEndDate || a.auctionStartDate != a2.auctionStartDate){
                        dif = true;
                      }
                      if(!dif){
                        var abList1 = a.auctionBidList;
                        var abList2 = a2.auctionBidList;
                        if(abList1.length != abList2.length || (abList1.length > 0 && abList2.length > 0 && abList1[0].id != abList2[0].id)){
                          dif = true;
                        }
                      }
                    }
                  });
                  if(dif){
                    this.pendingUsdpList = [];
                    this.detectChange();
                    change = true;
                    this.pendingUsdpList = tempPendingUsdpList;
                  }
                }catch(err){
                  this.pendingUsdpList = [];
                  this.detectChange();
                  change = true;
                  this.pendingUsdpList = tempPendingUsdpList;
                }
              }

              if(this.startUsdpList.length != tempStartUsdpList.length){
                this.startUsdpList = [];
                this.detectChange();
                change = true;
                this.startUsdpList = tempStartUsdpList;
              }else{
                try{
                  var dif = false;
                  tempStartUsdpList.forEach((a, index) => {
                    if(!dif){
                      var a2 = this.startUsdpList[index];
                      if(a.id != a2.id || a.status != a2.status || a.auctionEndDate != a2.auctionEndDate || a.auctionStartDate != a2.auctionStartDate){
                        dif = true;
                      }
                      if(!dif){
                        var abList1 = a.auctionBidList;
                        var abList2 = a2.auctionBidList;
                        if(abList1.length != abList2.length || (abList1.length > 0 && abList2.length > 0 && abList1[0].id != abList2[0].id)){
                          dif = true;
                        }
                      }
                    }
                  });
                  if(dif){
                    this.startUsdpList = [];
                    this.detectChange();
                    change = true;
                    this.startUsdpList = tempStartUsdpList;
                  }
                }catch(err){
                  this.startUsdpList = [];
                  this.detectChange();
                  change = true;
                  this.startUsdpList = tempStartUsdpList;
                }
              }


              //wait
              if(this.waitPendingUsdtList.length != tempWaitPendingUsdtList.length){
                this.waitPendingUsdtList = [];
                this.detectChange();
                change = true;
                this.waitPendingUsdtList = tempWaitPendingUsdtList;
              }else{
                try{
                  var dif = false;
                  tempWaitPendingUsdtList.forEach((a, index) => {
                    if(!dif){
                      var a2 = this.waitPendingUsdtList[index];
                      if(a.id != a2.id || a.status != a2.status || a.auctionEndDate != a2.auctionEndDate || a.auctionStartDate != a2.auctionStartDate){
                        dif = true;
                      }
                      if(!dif){
                        var abList1 = a.auctionBidList;
                        var abList2 = a2.auctionBidList;
                        if(abList1.length != abList2.length || (abList1.length > 0 && abList2.length > 0 && abList1[0].id != abList2[0].id)){
                          dif = true;
                        }
                      }
                    }
                  });
                  if(dif){
                    this.waitPendingUsdtList = [];
                    this.detectChange();
                    change = true;
                    this.waitPendingUsdtList = tempWaitPendingUsdtList;
                  }
                }catch(err){
                  this.waitPendingUsdtList = [];
                  this.detectChange();
                  change = true;
                  this.waitPendingUsdtList = tempWaitPendingUsdtList;
                }
              }

              if(this.waitStartUsdtList.length != tempWaitStartUsdtList.length){
                this.waitStartUsdtList = [];
                this.detectChange();
                change = true;
                this.waitStartUsdtList = tempWaitStartUsdtList;
              }else{
                try{
                  var dif = false;
                  tempWaitStartUsdtList.forEach((a, index) => {
                    if(!dif){
                      var a2 = this.waitStartUsdtList[index];
                      if(a.id != a2.id || a.status != a2.status || a.auctionEndDate != a2.auctionEndDate || a.auctionStartDate != a2.auctionStartDate){
                        dif = true;
                      }
                      if(!dif){
                        var abList1 = a.auctionBidList;
                        var abList2 = a2.auctionBidList;
                        if(abList1.length != abList2.length || (abList1.length > 0 && abList2.length > 0 && abList1[0].id != abList2[0].id)){
                          dif = true;
                        }
                      }
                    }
                  });
                  if(dif){
                    this.waitStartUsdtList = [];
                    this.detectChange();
                    change = true;
                    this.waitStartUsdtList = tempWaitStartUsdtList;
                  }
                }catch(err){
                  this.waitStartUsdtList = [];
                  this.detectChange();
                  change = true;
                  this.waitStartUsdtList = tempWaitStartUsdtList;
                }
              }

              if(this.waitPendingUsdpList.length != tempWaitPendingUsdpList.length){
                this.waitPendingUsdpList = [];
                this.detectChange();
                change = true;
                this.waitPendingUsdpList = tempWaitPendingUsdpList;
              }else{
                try{
                  var dif = false;
                  tempWaitPendingUsdpList.forEach((a, index) => {
                    if(!dif){
                      var a2 = this.waitPendingUsdpList[index];
                      if(a.id != a2.id || a.status != a2.status || a.auctionEndDate != a2.auctionEndDate || a.auctionStartDate != a2.auctionStartDate){
                        dif = true;
                      }
                      if(!dif){
                        var abList1 = a.auctionBidList;
                        var abList2 = a2.auctionBidList;
                        if(abList1.length != abList2.length || (abList1.length > 0 && abList2.length > 0 && abList1[0].id != abList2[0].id)){
                          dif = true;
                        }
                      }
                    }
                  });
                  if(dif){
                    this.waitPendingUsdpList = [];
                    this.detectChange();
                    change = true;
                    this.waitPendingUsdpList = tempWaitPendingUsdpList;
                  }
                }catch(err){
                  this.waitPendingUsdpList = [];
                  this.detectChange();
                  change = true;
                  this.waitPendingUsdpList = tempWaitPendingUsdpList;
                }
              }

              if(this.waitStartUsdpList.length != tempWaitStartUsdpList.length){
                this.waitStartUsdpList = [];
                this.detectChange();
                change = true;
                this.waitStartUsdpList = tempWaitStartUsdpList;
              }else{
                try{
                  var dif = false;
                  tempWaitStartUsdpList.forEach((a, index) => {
                    if(!dif){
                      var a2 = this.waitStartUsdpList[index];
                      if(a.id != a2.id || a.status != a2.status || a.auctionEndDate != a2.auctionEndDate || a.auctionStartDate != a2.auctionStartDate){
                        dif = true;
                      }
                      if(!dif){
                        var abList1 = a.auctionBidList;
                        var abList2 = a2.auctionBidList;
                        if(abList1.length != abList2.length || (abList1.length > 0 && abList2.length > 0 && abList1[0].id != abList2[0].id)){
                          dif = true;
                        }
                      }
                    }
                  });
                  if(dif){
                    this.waitStartUsdpList = [];
                    this.detectChange();
                    change = true;
                    this.waitStartUsdpList = tempWaitStartUsdpList;
                  }
                }catch(err){
                  this.waitStartUsdpList = [];
                  this.detectChange();
                  change = true;
                  this.waitStartUsdpList = tempWaitStartUsdpList;
                }
              }


              //forever
              if(this.foreverPendingUsdtList.length != tempForeverPendingUsdtList.length){
                this.foreverPendingUsdtList = [];
                this.detectChange();
                change = true;
                this.foreverPendingUsdtList = tempForeverPendingUsdtList;
              }else{
                try{
                  var dif = false;
                  tempForeverPendingUsdtList.forEach((a, index) => {
                    if(!dif){
                      var a2 = this.foreverPendingUsdtList[index];
                      if(a.id != a2.id || a.status != a2.status || a.auctionEndDate != a2.auctionEndDate || a.auctionStartDate != a2.auctionStartDate){
                        dif = true;
                      }
                      if(!dif){
                        var abList1 = a.auctionBidList;
                        var abList2 = a2.auctionBidList;
                        if(abList1.length != abList2.length || (abList1.length > 0 && abList2.length > 0 && abList1[0].id != abList2[0].id)){
                          dif = true;
                        }
                      }
                    }
                  });
                  if(dif){
                    this.foreverPendingUsdtList = [];
                    this.detectChange();
                    change = true;
                    this.foreverPendingUsdtList = tempForeverPendingUsdtList;
                  }
                }catch(err){
                  this.foreverPendingUsdtList = [];
                  this.detectChange();
                  change = true;
                  this.foreverPendingUsdtList = tempForeverPendingUsdtList;
                }
              }

              if(this.foreverStartUsdtList.length != tempForeverStartUsdtList.length){
                this.foreverStartUsdtList = [];
                this.detectChange();
                change = true;
                this.foreverStartUsdtList = tempForeverStartUsdtList;
              }else{
                try{
                  var dif = false;
                  tempForeverStartUsdtList.forEach((a, index) => {
                    if(!dif){
                      var a2 = this.foreverStartUsdtList[index];
                      if(a.id != a2.id || a.status != a2.status || a.auctionEndDate != a2.auctionEndDate || a.auctionStartDate != a2.auctionStartDate){
                        dif = true;
                      }
                      if(!dif){
                        var abList1 = a.auctionBidList;
                        var abList2 = a2.auctionBidList;
                        if(abList1.length != abList2.length || (abList1.length > 0 && abList2.length > 0 && abList1[0].id != abList2[0].id)){
                          dif = true;
                        }
                      }
                    }
                  });
                  if(dif){
                    this.foreverStartUsdtList = [];
                    this.detectChange();
                    change = true;
                    this.foreverStartUsdtList = tempForeverStartUsdtList;
                  }
                }catch(err){
                  this.foreverStartUsdtList = [];
                  this.detectChange();
                  change = true;
                  this.foreverStartUsdtList = tempForeverStartUsdtList;
                }
              }

              if(this.foreverPendingUsdpList.length != tempForeverPendingUsdpList.length){
                this.foreverPendingUsdpList = [];
                this.detectChange();
                change = true;
                this.foreverPendingUsdpList = tempForeverPendingUsdpList;
              }else{
                try{
                  var dif = false;
                  tempForeverPendingUsdpList.forEach((a, index) => {
                    if(!dif){
                      var a2 = this.foreverPendingUsdpList[index];
                      if(a.id != a2.id || a.status != a2.status || a.auctionEndDate != a2.auctionEndDate || a.auctionStartDate != a2.auctionStartDate){
                        dif = true;
                      }
                      if(!dif){
                        var abList1 = a.auctionBidList;
                        var abList2 = a2.auctionBidList;
                        if(abList1.length != abList2.length || (abList1.length > 0 && abList2.length > 0 && abList1[0].id != abList2[0].id)){
                          dif = true;
                        }
                      }
                    }
                  });
                  if(dif){
                    this.foreverPendingUsdpList = [];
                    this.detectChange();
                    change = true;
                    this.foreverPendingUsdpList = tempForeverPendingUsdpList;
                  }
                }catch(err){
                  this.foreverPendingUsdpList = [];
                  this.detectChange();
                  change = true;
                  this.foreverPendingUsdpList = tempForeverPendingUsdpList;
                }
              }

              if(this.foreverStartUsdpList.length != tempForeverStartUsdpList.length){
                this.foreverStartUsdpList = [];
                this.detectChange();
                change = true;
                this.foreverStartUsdpList = tempForeverStartUsdpList;
              }else{
                try{
                  var dif = false;
                  tempForeverStartUsdpList.forEach((a, index) => {
                    if(!dif){
                      var a2 = this.foreverStartUsdpList[index];
                      if(a.id != a2.id || a.status != a2.status || a.auctionEndDate != a2.auctionEndDate || a.auctionStartDate != a2.auctionStartDate){
                        dif = true;
                      }
                      if(!dif){
                        var abList1 = a.auctionBidList;
                        var abList2 = a2.auctionBidList;
                        if(abList1.length != abList2.length || (abList1.length > 0 && abList2.length > 0 && abList1[0].id != abList2[0].id)){
                          dif = true;
                        }
                      }
                    }
                  });
                  if(dif){
                    this.foreverStartUsdpList = [];
                    this.detectChange();
                    change = true;
                    this.foreverStartUsdpList = tempForeverStartUsdpList;
                  }
                }catch(err){
                  this.foreverStartUsdpList = [];
                  this.detectChange();
                  change = true;
                  this.foreverStartUsdpList = tempForeverStartUsdpList;
                }
              }

              //init tab
              if((this.startUsdtList && this.startUsdtList.length > 0) || (this.startUsdpList && this.startUsdpList.length > 0) || (this.pendingUsdtList && this.pendingUsdtList.length > 0) || (this.pendingUsdpList && this.pendingUsdpList.length > 0)){
                this.normalTab = true;
                this.waitTab = false;
                this.foreverTab = false;
              }else if((this.waitStartUsdtList && this.waitStartUsdtList.length > 0) || (this.waitStartUsdpList && this.waitStartUsdpList.length > 0) || (this.waitPendingUsdtList && this.waitPendingUsdtList.length > 0) || (this.waitPendingUsdpList && this.waitPendingUsdpList.length > 0)){
                this.normalTab = false;
                this.waitTab = true;
                this.foreverTab = false;
              }else if((this.foreverStartUsdtList && this.foreverStartUsdtList.length > 0) || (this.foreverStartUsdpList && this.foreverStartUsdpList.length > 0) || (this.foreverPendingUsdtList && this.foreverPendingUsdtList.length > 0) || (this.foreverPendingUsdpList && this.foreverPendingUsdpList.length > 0)){
                this.normalTab = false;
                this.waitTab = false;
                this.foreverTab = true;
              }

            }else if(data['status'] == 'error'){
              if(data['errorCode'] == 'request.error.token.invalid'){
                this.authService.connectionTimeOut();
              }else{
                // this.authService.showToast("danger", data['errorMessage']);
              }
            }else{
              // this.authService.showToast("danger", this.authService.getTranslation("message.generalError"));
            }
            this.initTranslate();
            if(change){
              this.detectChange();
            }
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }

    getCategoryList(){
      this.bannerCategoryLoad = false;
      this.authService.start();
      this.authService.getAllCategoryList().subscribe(
          data => {
            if(data['status'] == 'ok'){
              this.categories = [];
              var list = data['result'];
              list.forEach(c => {
                var image = 'assets/images/categories/bags.jpg';
                if(c.id == 43){
                  image = 'assets/images/categories/shoeshats.jpg';
                }else if(c.id == 42){
                  image = 'assets/images/categories/watches.jpg';
                }else if(c.id == 41){
                  image = 'assets/images/categories/bags.jpg';
                }else if(c.id == 40){
                  image = 'assets/images/categories/healthcare.jpg';
                }else if(c.id == 39){
                  image = 'assets/images/categories/beauty.jpg';
                }else if(c.id == 38){
                  image = 'assets/images/categories/accessories.jpg';
                }else if(c.id == 28){
                  image = 'assets/images/categories/mensfashion.jpg';
                }else if(c.id == 27){
                  image = 'assets/images/categories/womenfashion.jpg';
                }
                var item = {
                  category: c,
                  url: '../shop/' + c.id,
                  image: image
                };
                if(c.id != 37){ //exclude weini special
                  this.categories.push(item);
                }
              });

              this.bannerCategoryLoad = true;
            }else if(data['status'] == 'error'){
              this.authService.showToast("danger", data['errorMessage']);
            }else{
              this.authService.showToast("danger", this.authService.getTranslation("message.generalError"));
            }
            this.detectChange();
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }

    showNoticeDialog(): void {
      const dialogRef = this.dialog.open(NoticeComponent, {
        width: '650px',
        data: {

        }
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    }

    getProfile(){
      this.authService.start();
      this.authService.getProfile().subscribe(
          data => {
            if(data['status'] == 'ok'){
              this.authService.weniValue = data['result'].weni;
              this.authService.usdtValue = data['result'].usdt;
              this.authService.usdtPointValue = data['result'].usdtPoint;
              this.authService.weniTokenValue = data['result'].weniToken;
              this.authService.otrValue = data['result'].otr;
              this.authService.lpValue = data['result'].lp;
              this.authService.pvValue = data['result'].pv;
              this.authService.ppValue = data['result'].pp;
              this.authService.mltValue = data['result'].mlt;
              this.authService.jdtValue = data['result'].jdt;
              this.authService.userCoinList = data['result'].userCoinList;

            }
            this.detectChange();
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              // this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }

    getRandomList(status){
      this.authService.start();

      var notIdList = [];
      if(status == "ACTIVE"){
        notIdList = this.notActiveIdList;
      }else if(status == "START"){
        notIdList = this.notStartIdList;
      }

      this.authService.getRandomList(10, status, notIdList).subscribe(
          data => {
            if(data['status'] == 'ok'){
              this.loading = false;
              var list = this.authService.parseAuctionList(data['result'].auctionList);
              if(status == "ACTIVE"){
                list.forEach(p => {
                  p.routerLink = '../shop/custom-product/' + p.id;
                  this.notActiveIdList.push(p.id);
                  this.randomActiveList.push(p);
                });
              }else if(status == "START"){
                list.forEach(p => {
                  p.routerLink = '../shop/custom-product/' + p.id;
                  this.notStartIdList.push(p.id);
                  this.randomStartList.push(p);
                });
              }
            }else if(data['status'] == 'error'){
              if(data['errorCode'] == 'request.error.token.invalid'){
                this.authService.connectionTimeOut();
              }
            }
            this.initTranslate();
            this.detectChange();
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }

    startInterval(){
      this.interval = setInterval(()=>{
        this.getHomeProductList();
      }, 5000);
    }

    stopInterval(){
      clearInterval(this.interval);
    }

    checkSso(sessionId) {
      this.authService.checkSso(sessionId).subscribe(
          data => {
            if(data['status'] == 'ok'){
              try{
                this.authService.setToken(data['result'].user.token);
                this.authService.getToken();
                this.authService.weniValue = data['result'].user.weni;
                this.authService.usdtValue = data['result'].user.usdt;
                this.authService.usdtPointValue = data['result'].user.usdtPoint;
                this.authService.weniTokenValue = data['result'].user.weniToken;
                this.authService.otrValue = data['result'].user.otr;
                this.authService.lpValue = data['result'].user.lp;
                this.authService.pvValue = data['result'].pv;
                this.authService.ppValue = data['result'].pp;
                this.authService.mltValue = data['result'].mlt;
                this.authService.jdtValue = data['result'].jdt;
                this.authService.userCoinList = data['result'].userCoinList;

                this.authService.updateMobileMenu();
              }catch(err){

              }
            }else if(data['status'] == 'error'){
              // this.authService.showToast("danger", data['errorMessage']);
              // this.authService.router.navigate(['/classic/home']);
            }else{
              // this.authService.showToast("danger", this.authService.getTranslation("message.generalError"));
              // this.authService.router.navigate(['/classic/home']);
            }
            this.detectChange();
            this.authService.complete();
            this.authService.loadingSso = false;
          },
          error => {
            if(error['error'].errorCode == 400){
              // this.authService.showToast("danger", error['error'].message);
            }
            // this.calledAPI = false;
            this.authService.complete();
            // this.authService.router.navigate(['/classic/home']);
          }
        );
    }
}
