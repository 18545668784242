import { NavigationLink } from '../app/shared/interfaces/navigation-link';

export const navigation: NavigationLink[] = [
    {label: 'Shoes', url: './', /*menu: {
         type: 'menu',
       items: [
            {label: 'Home 1', url: '/classic'},
            {label: 'Home 2', url: '/compact'}
        ]
    }*/},
    {label: 'Accessories', url: './shop', /*menu: {
        type: 'megamenu',
        size: 'nl',
        columns: [
            {size: 6, items: [
                {label: 'Power Tools', url: './shop', items: [
                    {label: 'Engravers', url: './shop'},
                    {label: 'Wrenches', url: './shop'},
                    {label: 'Wall Chaser', url: './shop'},
                    {label: 'Pneumatic Tools', url: './shop'}
                ]},
                {label: 'Machine Tools', url: './shop', items: [
                    {label: 'Thread Cutting', url: './shop'},
                    {label: 'Chip Blowers', url: './shop'},
                    {label: 'Sharpening Machines', url: './shop'},
                    {label: 'Pipe Cutters', url: './shop'},
                    {label: 'Slotting machines', url: './shop'},
                    {label: 'Lathes', url: './shop'}
                ]}
            ]},
            {size: 6, items: [
                {label: 'Hand Tools', url: './shop', items: [
                    {label: 'Screwdrivers', url: './shop'},
                    {label: 'Handsaws', url: './shop'},
                    {label: 'Knives', url: './shop'},
                    {label: 'Axes', url: './shop'},
                    {label: 'Multitools', url: './shop'},
                    {label: 'Paint Tools', url: './shop'}
                ]},
                {label: 'Garden Equipment', url: './shop', items: [
                    {label: 'Motor Pumps', url: './shop'},
                    {label: 'Chainsaws', url: './shop'},
                    {label: 'Electric Saws', url: './shop'},
                    {label: 'Brush Cutters', url: './shop'}
                ]}
            ]}
        ]
    }*/},
    {label: 'Electronics', url: './shop',},
    {label: 'Sports', url: './shop',},
    {label: 'Health & Beauty', url: './shop',},
];
