import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-custom-carousel',
  templateUrl: './block-custom-carousel.component.html',
  styleUrls: ['./block-custom-carousel.component.sass']
})
export class BlockCustomCarouselComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
