import { Injectable, OnDestroy } from '@angular/core';
import { Product } from '../interfaces/product';
import { Observable, Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CustomQuickviewService implements OnDestroy {
    private destroy$: Subject<void> = new Subject();
    private showSubject$: Subject<Product> = new Subject();
    private hideSubject$: Subject<Product> = new Subject();

    show$: Observable<Product> = this.showSubject$.pipe(takeUntil(this.destroy$));
    hide$: Observable<Product> = this.hideSubject$.pipe(takeUntil(this.destroy$));

    constructor() { }

    show(product: Product): Observable<void> {
        // timer only for demo
        return timer(500).pipe(map(() => {
            this.showSubject$.next(product);
        }));
    }

    ngOnDestroy(): void {
      // console.log("ngOnDestroy()");
        this.destroy$.next();
        this.destroy$.complete();
    }

    hide(): Observable<void> {
      // console.log("asd");
        return timer(0).pipe(map(() => {
            this.hideSubject$.next({});
        }));
    }
}
