import { Component, OnInit } from '@angular/core';
import { navigation } from '../../../../../data/header-navigation';
import { NavigationLink } from '../../../../shared/interfaces/navigation-link';
import { DirectionService } from '../../../../shared/services/direction.service';
import { AuthService } from '../../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-header-links',
    templateUrl: './links.component.html',
    styleUrls: ['./links.component.scss']
})
export class LinksComponent implements OnInit{
    items: NavigationLink[] = navigation;
    lang = "";
    mallUrl : string = environment.mallUrl;

    constructor(
        private direction: DirectionService,
        private translate: TranslateService,
        public authService: AuthService
    ) {}

    ngOnInit(){
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });
      this.getAllCategoryList();
    }

    mouseenter(event: MouseEvent): void {
        if (!(event.target instanceof HTMLElement)) {
            return;
        }

        const element: HTMLElement = event.target;
        const megamenu = element.querySelector('.nav-links__megamenu') as HTMLElement;

        if (!megamenu) {
            return;
        }

        const container = megamenu.offsetParent;
        const containerWidth = container.getBoundingClientRect().width;
        const megamenuWidth = megamenu.getBoundingClientRect().width;

        if (this.direction.isRTL()) {
            const itemPosition = containerWidth - (element.offsetLeft + element.offsetWidth);
            const megamenuPosition = Math.round(Math.min(itemPosition, containerWidth - megamenuWidth));

            megamenu.style.right = megamenuPosition + 'px';
        } else {
            const itemPosition = element.offsetLeft;
            const megamenuPosition = Math.round(Math.min(itemPosition, containerWidth - megamenuWidth));

            megamenu.style.left = megamenuPosition + 'px';
        }
    }

    getAllCategoryList(){
      this.authService.start();
      this.authService.getAllCategoryList().subscribe(
          data => {
            if(data['status'] == 'ok'){
              this.items = [];
              var categoryList = data['result'];
              categoryList.forEach((c, index) => {
                var item = {label: '', id: null, url: '', en: '', zh: '', menu: null};
                item.id = c.id;
                item.url = './shop/' + c.id;
                item.en = c.categoryLocaleList[0].name;
                item.zh = c.categoryLocaleList[1].name;
                if(item.id == 37){
                  this.items.push(item);
                }
              });
            }else if(data['status'] == 'error'){
              this.authService.showToast("danger", data['errorMessage']);
            }else{
              this.authService.showToast("danger", this.authService.getTranslation("message.generalError"));
            }
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }
}
