<div class="site-footer__widget footer-contacts">
    <h5 class="footer-contacts__title" *ngIf="lang == 'en' && authService.en">{{authService.en.footer.contactUs}}</h5>
    <h5 class="footer-contacts__title" *ngIf="lang == 'zh'  && authService.zh">{{authService.zh.footer.contactUs}}</h5>

    <div class="footer-contacts__text">
        <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in feugiat lorem. Pellentque ac placerat tellus. -->
    </div>

    <ul class="footer-contacts__contacts">


        <li><i class="footer-contacts__icon far fa-envelope"></i> paimai@wyoupin.io </li>
        <!-- <li><img class="footer-contacts__icon" src="assets/images/wechat.png"/> wenipaimai88 </li> -->
        <li *ngIf="lang == 'en' && authService.en"><i class="footer-contacts__icon far fa-clock"></i>{{authService.en.footer.contact.openingHours}}</li>
        <li *ngIf="lang == 'zh' && authService.zh"><i class="footer-contacts__icon far fa-clock"></i>{{authService.zh.footer.contact.openingHours}}</li>

    </ul>
</div>
