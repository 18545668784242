import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-block-features',
    templateUrl: './block-features.component.html',
    styleUrls: ['./block-features.component.scss']
})
export class BlockFeaturesComponent implements OnInit{
    @Input() layout: 'classic'|'boxed' = 'classic';
    lang = "";

    constructor(private translate: TranslateService, public authService: AuthService) { }

    ngOnInit(){
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });
    }
}
