<!-- .block-products-carousel -->
<div class="block block-products-carousel" [ngClass]="{'block-products-carousel--loading': loading}" [attr.data-layout]="layout">
    <div  [ngClass]="{'container': !withSidebar}">
        <app-block-header [header]="header" [groups]="groups" [arrows]="true" (next)="carousel.next()" (prev)="carousel.prev()" (groupChange)="groupChange.emit($event)"></app-block-header>

        <div class="block-products-carousel__slider">
            <div class="block-products-carousel__preloader"></div>

            <owl-carousel-o [options]="carouselOptions" appOwlPreventClick #carousel>
                <ng-container *ngFor="let column of columns">
                    <ng-template carouselSlide>
                        <div class="block-products-carousel__column">
                            <div *ngFor="let product of column" class="block-products-carousel__cell">
                                <app-custom-product-card [product]="product"></app-custom-product-card>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</div>
<!-- .block-products-carousel / end -->
