import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../../shared/interfaces/product';
import { RootService } from '../../../shared/services/root.service';
import { AuthService } from '../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-custom-widget-products',
    templateUrl: './custom-widget-products.component.html',
    styleUrls: ['./custom-widget-products.component.scss']
})
export class CustomWidgetProductsComponent implements OnInit{
    @Input() header = '';
    @Input() products: Product[] = [];
    lang = "";

    constructor(public root: RootService,
      private translate: TranslateService,
      public authService: AuthService) { }

      ngOnInit() {
          this.lang = this.authService.getLocale();
          this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.lang = event.lang;
            // try{
            //   this.cd.detectChanges();
            // }catch(err){
            //
            // }
          });

          // this.currency.changes$.pipe(takeUntil(this.destroy$)).subscribe(() => {
          //     this.cd.markForCheck();
          // });
      }
}
