<div class="product-card"  [ngClass]="{
    'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
    'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
    'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
    'product-card--layout--list':                        layout === 'list',
    'product-card--layout--horizontal':                  layout === 'horizontal'
}">
    <!-- <button class="product-card__quickview" type="button" appClick (click)="showQuickview()" [ngClass]="{'product-card__quickview--preload': showingQuickview}">
        <app-icon name="quickview-16" size="16"></app-icon>
        <span class="fake-svg-icon"></span>
    </button> -->

    <!-- <div  class="product-card__badges-list">
        <div *ngIf=" lang == 'en' && authService.en" class="product-card__badge product-card__badge--sale">{{authService.en.productCard.sale}}</div>
        <div *ngIf=" lang == 'zh' && authService.zh" class="product-card__badge product-card__badge--sale">{{authService.zh.productCard.sale}}</div>

    </div> -->

      <div class="product-card__image" >
          <!-- <div class="product-card__sold" *ngIf="product.status != 'ACTIVE' && product.status != 'START'">
            <span *ngIf="product.sold">{{'productCard.sold' | translate}}</span>
            <span *ngIf="!product.sold">{{'auction.END' | translate}}</span>
          </div>
          <div class="product-card__start" *ngIf="product.status == 'START'">
            <span class="blink_me ">{{'auction.START' | translate}}</span>
          </div> -->
          <a  appClick [routerLink]="[product.routerLink]" *ngIf="product.auctionImageList && product.auctionImageList.length"><img [defaultImage]="'/assets/images/placeholder.png'" [lazyLoad]="product.auctionImageList[0].url"/></a>
          <a  appClick [routerLink]="[product.routerLink]" *ngIf="!product.auctionImageList || !product.auctionImageList.length"><img src="/assets/images/placeholder.png" alt=""></a>
      </div>
      <div class="product-card__info">
          <div class="typography product-card__name">
              <a appClick [routerLink]="[product.routerLink]" *ngIf="product.auctionLocaleList && lang == 'en' && product?.auctionLocaleList[0]">{{ product?.auctionLocaleList[0]?.name }}</a>
              <a appClick [routerLink]="[product.routerLink]" *ngIf="product.auctionLocaleList && lang == 'zh' && product?.auctionLocaleList[1]">{{ product?.auctionLocaleList[1]?.name }}</a>
          </div>
          <div class="product-card__action ">
              <div class="product-card__prices" *ngIf="product">
                  <ng-container>
                      <div class=" typography font-size-smaller" *ngIf="product.status == 'ACTIVE' || product.status == 'START'">
                          <span *ngIf="product.status == 'ACTIVE'">{{'productCard.auctionstarts' | translate}} </span>
                          <span *ngIf="product.status == 'START'">{{'productCard.auctionends' | translate}} </span>
                          <div class="product__new-price font-weight-bold">{{parseCountdown(counter$ | async)}}</div>
                      </div>
                  </ng-container>

                  <ng-container *ngIf="product && product.auctionStartingPriceList">
                      <div class="d-flex typography font-size-smaller">
                          <span >{{'productCard.startingprice' | translate}}: </span>

                          <div *ngFor="let arp of product.auctionStartingPriceList" class=" ml-auto font-weight-bold">
                            <div >
                              {{'topbar.' + arp.currency | translate}} {{authService.thousandSeparator(arp.amount)}}
                            </div>
                          </div>
                      </div>
                  </ng-container>

                  <ng-container>
                      <div class="d-flex" *ngIf="product && product.auctionBidList && product.auctionBidList.length > 0">
                          <span class=" font-size-smaller typography">{{'productCard.biddingprice' | translate}}: </span>
                          <div *ngFor="let abp of product.auctionBidList[0].auctionBidPriceList" class="font-size-smaller typography ml-auto font-weight-bold">
                            {{'topbar.' + abp.currency | translate}} {{authService.thousandSeparator(abp.amount)}}
                          </div>
                      </div>
                  </ng-container>

                  <ng-container>
                      <div class="d-flex">
                          <div class=" font-size-smaller typography ">{{'productCard.bidders' | translate}}: </div>
                          <div class="font-size-smaller typography ml-auto font-weight-bold">{{product.totalBidders}}</div>
                      </div>
                  </ng-container>
              </div>
          </div>
        </div>
</div>
