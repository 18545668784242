import { Component, Input } from '@angular/core';
import { BlockProductColumn } from '../../../shared/interfaces/block-product-column';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AuthService } from '../../../auth.service';

@Component({
    selector: 'app-block-product-columns',
    templateUrl: './block-product-columns.component.html',
    styleUrls: ['./block-product-columns.component.scss']
})
export class BlockProductColumnsComponent {
    @Input() columns: BlockProductColumn[] = [];
    lang = "";

    constructor(private translate: TranslateService, public authService: AuthService) { }

    ngOnInit(){
      this.lang = this.authService.getLocale();

      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });
    }
}
