import { Component, OnInit } from '@angular/core';
import { StoreService } from '../../../../shared/services/store.service';
import { AuthService } from '../../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-footer-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit{
    lang = "";

    constructor(public store: StoreService, private translate: TranslateService, public authService: AuthService) {

    }

    ngOnInit(){
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });
    }

}
