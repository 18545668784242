import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-header-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit{
    lang = "";
    search = "";
    constructor(private translate: TranslateService, public authService: AuthService) { }

    ngOnInit(){
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });

      this.authService.removeSearchKeyword$.subscribe(search => {
          this.search = "";
      });
    }

    emitSearch(){
      this.search = this.search.trim();
      if(this.search.length > 0){
        // this.authService.searchProductList(this.search);
        this.authService.router.navigate(['/classic/shop/category-grid-3-columns-sidebar/', this.search]);
      }
    }
}
