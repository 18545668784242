 <div class="container">
    <div class="block block-verticle" >
        <app-block-header [header]="header" [moreParam]="moreParam"></app-block-header>
        <div class="block-wrapper row">
            <div *ngFor="let product of products; trackBy: trackByProduct" class=" block-card col-6 col-md-4 col-lg-3">
                <app-custom-product-card-modified [product]="product"></app-custom-product-card-modified>
            </div>
        </div>
    </div>
</div>
