import { Component } from '@angular/core';

@Component({
    selector: 'app-widget-search',
    templateUrl: './widget-search.component.html',
    styleUrls: ['./widget-search.component.scss']
})
export class WidgetSearchComponent {
    constructor() { }
}
