<div class="mobile-fixed_menu">
    <a class="mobile-fixed_menu-item" href="" routerLink="./home"  [routerLinkActive]="'is-active'" >
        <div class="mobile-fixed_menu-icon  logo">
            <svg-icon src="assets/images/icon/logo.svg" [svgStyle]="{ 'height.px':25 }"></svg-icon>

        </div>
        <span class="mobile-fixed_menu-text">{{'mobileMenu.home' | translate}}</span>
    </a>
    <a class="mobile-fixed_menu-item" href="" routerLink="./shop/category-page" [routerLinkActive]="'is-active'">
        <div class="mobile-fixed_menu-icon">
            <svg-icon src="assets/images/icon/category.svg" [svgStyle]="{ 'height.px':18 }"></svg-icon>
        </div>
        <span class="mobile-fixed_menu-text">{{'mobileMenu.category' | translate}}</span>
    </a>
    <a  *ngIf="authService.loggedIn()" class="mobile-fixed_menu-item" href="" routerLink="./account/bidding" [routerLinkActive]="'is-active'" >
        <div class="mobile-fixed_menu-icon">
            <svg-icon src="assets/images/icon/auction_1.svg" [svgStyle]="{ 'height.px':27 }"></svg-icon>

        </div>
        <span class="mobile-fixed_menu-text">{{'mobileMenu.myBidding' | translate}}</span>
    </a>
    <a *ngIf="!authService.loggedIn()" class="mobile-fixed_menu-item" href="" routerLink="./account/login"  >
        <div class="mobile-fixed_menu-icon">
            <svg-icon src="assets/images/icon/auction_1.svg" [svgStyle]="{ 'height.px':27 }"></svg-icon>

        </div>
        <span class="mobile-fixed_menu-text">{{'mobileMenu.myBidding' | translate}}</span>
    </a>
    <a *ngIf="!authService.loggedIn()" class="mobile-fixed_menu-item" [href]="mallUrl" [routerLinkActive]="'is-active'" [class.disabled]="!this.authService.loadingSso ? null: true">
        <div class="mobile-fixed_menu-icon">
            <svg-icon src="assets/images/icon/mall.svg" [svgStyle]="{ 'height.px':18 }"></svg-icon>
        </div>
        <span class="mobile-fixed_menu-text">{{'mobileMenu.mall' | translate}}</span>
    </a>
    <a *ngIf="authService.loggedIn()" class="mobile-fixed_menu-item" [href]="mallUrl + '/classic/home?token=' + authService.getToken()" [routerLinkActive]="'is-active'" >
      <div class="mobile-fixed_menu-icon">
          <svg-icon src="assets/images/icon/mall.svg" [svgStyle]="{ 'height.px':18 }"></svg-icon>
      </div>
      <span class="mobile-fixed_menu-text">{{'mobileMenu.mall' | translate}}</span>
    </a>

    <a *ngIf="authService.loggedIn()" class="mobile-fixed_menu-item" href="" routerLink="./account/profile" [routerLinkActive]="'is-active'">
        <div class="mobile-fixed_menu-icon">
            <svg-icon src="assets/images/icon/profile.svg" [svgStyle]="{ 'height.px':18 }"></svg-icon>
        </div>
        <span class="mobile-fixed_menu-text">{{'mobileMenu.account' | translate}}</span>
    </a>

    <a *ngIf="!authService.loggedIn()" class="mobile-fixed_menu-item" href="" routerLink="./account/login" [routerLinkActive]="'is-active'">
        <div class="mobile-fixed_menu-icon">
            <svg-icon src="assets/images/icon/profile.svg" [svgStyle]="{ 'height.px':18 }"></svg-icon>
        </div>
        <span class="mobile-fixed_menu-text">{{'mobileMenu.account' | translate}}</span>
    </a>

</div>
