<!-- .menu -->
<ul class="menu" [ngClass]="{'menu--layout--classic': layout === 'classic', 'menu--layout--topbar': layout === 'topbar'}">
    <li *ngFor="let item of items">

        <ng-template #link>
            <div *ngIf="lang == 'en'">{{ item.en }}</div>
            <div *ngIf="lang == 'zh'">{{ item.zh }}</div>
            <app-icon *ngIf="item.items" class="menu__arrow" name="arrow-rounded-right-6x9" size="6x9"></app-icon>
        </ng-template>

        <a *ngIf="!item.external && !item.logout" [routerLink]="item.url" [target]="item.target"><ng-container [ngTemplateOutlet]="link"></ng-container></a>
        <a *ngIf="item.external && !item.logout" [href]="item.url" [target]="item.target"><ng-container [ngTemplateOutlet]="link"></ng-container></a>
        <a *ngIf="!item.external && item.logout" [target]="item.target" (click)="service.logout()"><ng-container [ngTemplateOutlet]="link"></ng-container></a>

        <div *ngIf="item.items" class="menu__submenu">
            <app-header-menu [items]="item.items"></app-header-menu>
        </div>
    </li>
</ul>
<!-- .menu / end -->
