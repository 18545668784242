<div class="block-header">
    <div *ngIf="storeId && brandId" class="cursor-pointer">
      <h4 class="block-header__title clickable" *ngIf="lang == 'en'" (click)="goToBrandMerchantPage()">{{ header.en }}</h4>
      <h4 class="block-header__title clickable" *ngIf="lang == 'zh'" (click)="goToBrandMerchantPage()">{{ header.zh }}</h4>
    </div>
    <div *ngIf="!storeId && !brandId">
      <h4 class="block-header__title" *ngIf="lang == 'en'">{{ header.en }}</h4>
      <h4 class="block-header__title" *ngIf="lang == 'zh'">{{ header.zh }}</h4>
    </div>
    <!-- <div class="block-header__divider"></div> -->

    <button  class="block-header__groups-list" *ngIf="moreParam && moreParam.length > 0" (click)="more()">
        {{'common.more' | translate}}  <i class="fa fa-chevron-right pl-2"></i>
    </button>

    <div *ngIf="arrows" class="block-header__arrows-list">
        <button class="block-header__arrow block-header__arrow--left" type="button" (click)="prev.emit()">
            <app-icon name="arrow-rounded-left-7x11" size="7x11"></app-icon>
        </button>
        <button class="block-header__arrow block-header__arrow--right" type="button" (click)="next.emit()">
            <app-icon name="arrow-rounded-right-7x11" size="7x11"></app-icon>
        </button>
    </div>
</div>
