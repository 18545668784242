<!-- <div class="block block-mall block-mall--layout--{{ layout }}" [ngClass]="{'block-products-carousel--loading': loading}" > -->
<div class="block block-mall">
    <div class="container">
            <div >
                    <!-- <app-block-header [header]="header" [groups]="groups" [arrows]="true" (next)="carousel.next()" (prev)="carousel.prev()" (groupChange)="groupChange.emit($event)"></app-block-header> -->

                    <div class="block-products-carousel__slider">
                        <div class="block-products-carousel__preloader"></div>

                        <!-- <owl-carousel-o [options]="carouselOptions" appOwlPreventClick #carousel> -->
                        <!-- <owl-carousel-o appOwlPreventClick #carousel>
                            <ng-container *ngFor="let column of columns">
                                <ng-template carouselSlide>
                                    <div class="block-products-carousel__column">
                                        <div *ngFor="let product of column" class="block-products-carousel__cell">

                                                <div class="card-deck">
                                                        <div class="card">
                                                          <img src="..." class="card-img-top" alt="...">
                                                          <div class="card-body">
                                                            <h5 class="card-title">Card title</h5>
                                                            <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                                            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                                                          </div>
                                                        </div>
                                                        <div class="card">
                                                          <img src="..." class="card-img-top" alt="...">
                                                          <div class="card-body">
                                                            <h5 class="card-title">Card title</h5>
                                                            <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
                                                            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                                                          </div>
                                                        </div>
                                                        <div class="card">
                                                          <img src="..." class="card-img-top" alt="...">
                                                          <div class="card-body">
                                                            <h5 class="card-title">Card title</h5>
                                                            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
                                                            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                                                          </div>
                                                        </div>
                                                      </div>


                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o> -->
                    </div>
                </div>
    </div>
</div>
