import { Component } from '@angular/core';

@Component({
    selector: 'app-widget-newsletter',
    templateUrl: './widget-newsletter.component.html',
    styleUrls: ['./widget-newsletter.component.scss']
})
export class WidgetNewsletterComponent {
    constructor() { }
}
