import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Product } from '../interfaces/product';
import { CartItem } from '../interfaces/cart-item';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '../../auth.service';
import { ToastrService } from 'ngx-toastr';



interface CartTotal {
    title: string;
    price: number;
    type: 'shipping'|'fee'|'other';
}

interface CartData {
    items: CartItem[];
    quantity: number;
    subtotal: number;
    totals: CartTotal[];
    total: number;
}

@Injectable({
    providedIn: 'root'
})
export class CartService {
    public data: any = {
        items: [],
        quantity: 0,
        subtotal: 0,
        totals: [],
        total: 0,
        totalUsdt: 0,
        totalShippingFee: 0,
        totalFinalUsdt: 0,
        estimateLp: 0,
        totalUsdtPoint: 0,
        totalWeniToken: 0,
        canCheckout: false
    };

    address : any;

    private itemsSubject$: BehaviorSubject<CartItem[]> = new BehaviorSubject(this.data.items);
    private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.quantity);
    private subtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.subtotal);
    private totalsSubject$: BehaviorSubject<CartTotal[]> = new BehaviorSubject(this.data.totals);
    private totalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.total);
    private totalUsdtSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.totalUsdt);
    private totalShippingFeeSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.totalShippingFee);
    private totalFinalUsdtSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.totalFinalUsdt);
    private estimateLpSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.estimateLp);
    private totalUsdtPointSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.totalUsdtPoint);
    private totalWeniTokenSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.totalWeniToken);

    private onAddingSubject$: Subject<any> = new Subject();
    private addFailSubject$: Subject<any> = new Subject();
    private loadingSubject$: Subject<any> = new Subject();


    get items(): ReadonlyArray<CartItem> {
        return this.data.items;
    }

    get quantity(): number {
        return this.data.quantity;
    }

    get canCheckout(): boolean {
        return this.data.canCheckout;
    }

    readonly items$: Observable<CartItem[]> = this.itemsSubject$.asObservable();
    readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
    readonly subtotal$: Observable<number> = this.subtotalSubject$.asObservable();
    readonly totals$: Observable<CartTotal[]> = this.totalsSubject$.asObservable();
    readonly total$: Observable<number> = this.totalSubject$.asObservable();
    readonly totalUsdt$: Observable<number> = this.totalUsdtSubject$.asObservable();
    readonly totalShippingFee$: Observable<number> = this.totalShippingFeeSubject$.asObservable();
    readonly totalFinalUsdt$: Observable<number> = this.totalFinalUsdtSubject$.asObservable();
    readonly estimateLp: Observable<number> = this.estimateLpSubject$.asObservable();
    readonly totalUsdtPoint$: Observable<number> = this.totalUsdtPointSubject$.asObservable();
    readonly totalWeniToken$: Observable<number> = this.totalWeniTokenSubject$.asObservable();

    readonly onAdding$: Observable<any> = this.onAddingSubject$.asObservable();
    readonly addFail$: Observable<any> = this.addFailSubject$.asObservable();
    readonly loading$: Observable<any> = this.loadingSubject$.asObservable();

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        public authService: AuthService,
        private toastr: ToastrService
    ) {
        if (isPlatformBrowser(this.platformId)) {
            // this.load();
            // this.calc();
        }
        this.load();
    }

    add(product: any, quantity: number, options: {name: string; value: string}[] = []): Observable<CartItem> {
        // timer only for demo
        return timer(0).pipe(map(() => {
            var cartProductList = [];
            if(!product.selectedProductAttributeList){
              product.selectedProductAttributeList = [];
            }
            var cartProduct = {
              product: {
                id: product.id
              },
              selectedProductAttributeList: product.selectedProductAttributeList,
              quantity: quantity,
              checkout: true
            };
            cartProductList.push(cartProduct);
            this.updateProductToCart(cartProductList, product);

            let item = this.items.find(eachItem => {
                // if (eachItem.product.id !== product.id || eachItem.options.length !== options.length) {
                if (eachItem.product.id !== product.id) {
                    return false;
                }

                // if (eachItem.options.length) {
                //     for (const option of options) {
                //         if (!eachItem.options.find(itemOption => itemOption.name === option.name && itemOption.value === option.value)) {
                //             return false;
                //         }
                //     }
                // }

                return true;
            });

            if (item) {
                item.quantity += quantity;
            } else {
                product.routerLink = this.getRouterLinkUrl(product);
                item = {product, quantity, options};

                this.data.items.push(item);
            }

            // this.save();
            // this.calc();
            this.load();

            return item;
        }));
    }

    update(updates: {item: CartItem, quantity: number}[]): Observable<void> {
        // timer only for demo
        return timer(0).pipe(map(() => {
            var cartProductList = [];
            updates.forEach(update => {
                const item = this.items.find(eachItem => eachItem === update.item);

                if (item) {
                    item.quantity = update.quantity;

                    if(item.product && item.product.id){
                      var cartProduct = {
                        id: item.id,
                        product: {
                          id: item.product.id
                        },
                        selectedProductAttributeList: item.product.selectedProductAttributeList,
                        quantity: item.quantity,
                        checkout: update.item.checkout
                      };
                      cartProductList.push(cartProduct);
                    }
                }
            });


            if(cartProductList.length > 0){
              this.updateProductToCart(cartProductList, null);
            }

            // this.save();
            // this.calc();
        }));
    }

    updateInCart(updates: {item: CartItem, quantity: number}[]): Observable<void> {
        // timer only for demo
        return timer(0).pipe(map(() => {
            var cartProductList = [];
            updates.forEach(update => {
                const item = this.items.find(eachItem => eachItem === update.item);

                if (item) {
                    item.quantity = update.quantity;

                    if(item.product && item.product.id){
                      var cartProduct = {
                        id: item.id,
                        product: {
                          id: item.product.id
                        },
                        selectedProductAttributeList: item.product.selectedProductAttributeList,
                        quantity: item.quantity,
                        checkout: update.item.checkout
                      };
                      cartProductList.push(cartProduct);
                    }
                }
            });


            if(cartProductList.length > 0){
              this.updateProductInCart(cartProductList);
            }
        }));
    }

    remove(item: CartItem): Observable<void> {
        // timer only for demo
        return timer(0).pipe(map(() => {
            // this.data.items = this.data.items.filter(eachItem => eachItem !== item);

            // this.save();
            // this.calc();

            var cartProductList = [];
            var cartProduct = {
              id: item.id,
              product: {
                id: item.product.id
              },
              quantity: item.quantity,
              checkout: item.checkout
            };
            cartProductList.push(cartProduct);

            this.removeProductFromCart(cartProductList);
        }));
    }

    private calc(): void {
        let quantity = 0;
        let subtotal = 0;

        let totalUsdt = 0;
        let totalShippingFee = 0;
        let estimateLp = 0;
        let totalUsdtPoint = 0;
        let totalWeniToken = 0;

        var addShippingFeeProductIdList = [];

        this.data.canCheckout = false;

        this.data.items.forEach(item => {
          quantity += item.quantity;
            if(item.checkout){
              this.data.canCheckout = true;
              // quantity += item.quantity;

              try{
                if(item.product.salesPrice){
                  subtotal += item.product.salesPrice * item.quantity;
                }
              }catch(err){

              }

              try{
                if(item.product.salesPriceUsdt){
                  totalUsdt += item.product.salesPriceUsdt * item.quantity;
                }
              }catch(err){

              }
              try{
                if(item.product.salesPriceUsdtPoint){
                  totalUsdtPoint += item.product.salesPriceUsdtPoint * item.quantity;
                }
              }catch(err){

              }
              try{
                if(item.product.salesPriceWeniToken){
                  totalWeniToken += item.product.salesPriceWeniToken * item.quantity;
                }
              }catch(err){

              }
              try{
                // if(item.product.shippingFee && !addShippingFeeProductIdList.includes(item.product.id)){
                //   totalShippingFee += item.product.shippingFee;
                //   addShippingFeeProductIdList.push(item.product.id);
                // }
                totalShippingFee += item.product.shippingFee * item.quantity;
              }catch(err){

              }
            }
        });

        const totalFinalUsdt = totalUsdt + (!totalShippingFee ? 0 : totalShippingFee);

        const totals: CartTotal[] = [];
        const total = subtotal;

        this.data.quantity = quantity;
        this.data.subtotal = subtotal;
        this.data.totals = totals;
        this.data.total = total;
        this.data.totalUsdt = totalUsdt;
        this.data.totalShippingFee = totalShippingFee;
        this.data.totalFinalUsdt = totalFinalUsdt;
        this.data.estimateLp = totalUsdt * 33;
        this.data.totalUsdtPoint = totalUsdtPoint;
        this.data.totalWeniToken = totalWeniToken;

        this.itemsSubject$.next(this.data.items);
        this.quantitySubject$.next(this.data.quantity);
        this.subtotalSubject$.next(this.data.subtotal);
        this.totalsSubject$.next(this.data.totals);
        this.totalSubject$.next(this.data.total);
        this.totalUsdtSubject$.next(this.data.totalUsdt);
        this.totalShippingFeeSubject$.next(this.data.totalShippingFee);
        this.totalFinalUsdtSubject$.next(this.data.totalFinalUsdt);
        this.estimateLpSubject$.next(this.data.estimateLp);
        this.totalUsdtPointSubject$.next(this.data.totalUsdtPoint);
        this.totalWeniTokenSubject$.next(this.data.totalWeniToken);
    }

    /* deprecated */
    public save(): void {
        // console.log("save()");
        // console.log(this.data.items);
        localStorage.setItem('cartItems', JSON.stringify(this.data.items));
    }

    public load(): void {
        // const items = localStorage.getItem('cartItems');
        //
        // if (items) {
        //     this.data.items = JSON.parse(items);
        // }

        // this.getCart();
    }

    private getRouterLinkUrl(product){
      var url = '../classic/shop/custom-product/' + product.id;
      return url;
    }

    getCart(){
      this.authService.getCart(false).subscribe(
          data => {
            this.data.items = [];
            if(data['status'] == 'ok'){
              this.authService.cart = data['result'];
              // console.log(this.authService.cart);
              var cartProductList = this.authService.cart.cartProductList;
              cartProductList.forEach(cp => {
                cp.product.routerLink = this.getRouterLinkUrl(cp.product);
                var item = {
                  id: cp.id,
                  product: cp.product,
                  quantity: cp.quantity,
                  checkout: cp.checkout,
                  status: cp.status,
                  selectedProductAttributeList: cp.selectedProductAttributeList
                };

                this.data.items.push(item);
              });
            }

            if(this.requireShippingFeeCheck() && this.authService.token){
              this.getAddressList();
            }else{
              this.calc();
              this.authService.complete();
            }
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }

    updateProductToCart(cartProductList, product){
      // console.log("locale in cartService: " + this.authService.locale);
      this.authService.updateProductToCart(cartProductList).subscribe(
          data => {
            if(data['status'] == 'ok'){
              if(product != null){
                this.onAddingSubject$.next(product);
              }
              this.load();
            }else if(data['status'] == 'error'){
              if(data['errorCode'] == "request.error.product.out.of.stock"){
                this.addFailSubject$.next(data['errorCode']);
              }
              // this.authService.showToast("danger", data['errorMessage']);
            }else{
              console.log(this.authService.getTranslation("message.generalError"));
            }
            this.loadingSubject$.next(product);
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }

    updateProductInCart(cartProductList){
      this.authService.updateProductInCart(cartProductList).subscribe(
          data => {
            if(data['status'] == 'ok'){
              this.load();
            }else if(data['status'] == 'error'){
              this.authService.showToast("danger", data['errorMessage']);
            }else{
              this.authService.showToast("danger", this.authService.getTranslation("message.generalError"));
            }
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }

    removeProductFromCart(cartProductList){
      this.authService.removeProductFromCart(cartProductList).subscribe(
          data => {
            if(data['status'] == 'ok'){
              this.load();
            }else if(data['status'] == 'error'){
              this.authService.showToast("danger", data['errorMessage']);
            }else{
              this.authService.showToast("danger", this.authService.getTranslation("message.generalError"));
            }
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }

    removeCart(){
      this.data.items = [];
      this.authService.cart.cartProductList = [];
      this.calc();
    }

    getAddressList(){
      this.authService.getAddressList().subscribe(
          data => {
            if(data['status'] == 'ok'){
              this.getDefaultAddress(data['result']);
            }else if(data['status'] == 'error'){
              this.authService.showToast("danger", data['errorMessage']);
            }else{
              this.authService.showToast("danger", this.authService.getTranslation("message.generalError"));
            }
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }

    getDefaultAddress(addressList){
      if(addressList.length > 0){
        var found = false;
        addressList.forEach(a => {
          if(a.defaultAddress && !found){
            found = true;
            this.address = a;
          }
        });
        if(!found){
          this.address = null;
          this.calc();
          this.authService.complete();
        }else{
          this.populateShippingFee();
        }
      }else{
        this.address = null;
        this.calc();
        this.authService.complete();
      }
    }

    requireShippingFeeCheck(){
      var found = false;
      try{
        if(this.authService.cart.cartProductList.length > 0){
          this.authService.cart.cartProductList.forEach(cp => {
            if(cp.product.productShippingFeeList.length > 0 && !found){
              found = true;
            }
          });
        }
      }catch(err){
        // console.log(err);
      }
      return found;
    }

    populateShippingFee(){
      try{
        var addShippingFeeProductIdList = [];
        this.authService.cart.cartProductList.forEach(cp => {
          if(cp.product.productShippingFeeList.length > 0){
            cp.product.productShippingFeeList.forEach(psf => {
              if(psf.country.id == this.address.country.id){
                // if(!addShippingFeeProductIdList.includes(cp.product.id)){
                //   cp.product.shippingFee = psf.shippingFee == null ? 0 : psf.shippingFee;
                //   addShippingFeeProductIdList.push(cp.product.id);
                // }else{
                //   cp.product.shippingFee = 0;
                // }
                cp.product.shippingFee = psf.shippingFee == null ? 0 : psf.shippingFee;
              }
            });
          }
        });
      }catch(err){

      }
      this.calc();
      this.authService.complete();
    }

    checkoutChangeAddress(selectedAddress){
      this.address = selectedAddress;
      this.populateShippingFee();
    }

    appendPlusSign(currentCurrency){
      if(currentCurrency == "usdt"){

      }else if(currentCurrency == "weni"){
        if(this.data.totalFinalUsdt > 0){
          return "+";
        }
      }else if(currentCurrency == "usdtPoint"){
        if(this.data.totalFinalUsdt > 0 || this.data.totalWeniToken > 0){
          return "+";
        }
      }else if(currentCurrency == "weniPoint"){
        if(this.data.totalFinalUsdt > 0 || this.data.totalWeniToken > 0 || this.data.totalUsdtPoint > 0){
          return "+";
        }
      }
      return "";
    }

    updateCheckoutFlag(item){
      this.authService.updateCheckoutFlag(item.id, item.checkout).subscribe(
          data => {
            if(data['status'] == 'ok'){
              this.load();
            }else if(data['status'] == 'error'){
              this.authService.showToast("danger", data['errorMessage']);
            }else{
              this.authService.showToast("danger", this.authService.getTranslation("message.generalError"));
            }
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }
}
