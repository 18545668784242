<!-- .block-categories -->
<div class="block block--highlighted block-categories block-categories--layout--{{ layout }}">
    <div class="container">
        <app-block-header [header]="header"></app-block-header>

        <div class="block-categories__list">
            <div *ngFor="let category of categories" class="block-categories__item category-card category-card--layout--{{ layout }}">

                    <div class="category-card__image">

                        <a class="category-card_caption" [routerLink]="category.url"><img [src]="category.image" alt="">
                          <figcaption class="figure-caption">
                            <span *ngIf="lang == 'en'">{{category.category.categoryLocaleList[0].name}}</span>
                            <span *ngIf="lang == 'zh'">{{category.category.categoryLocaleList[1].name}}</span>
                          </figcaption>
                            <!-- <span class="popular-cat-text" [routerLink]="category.url">{{ category.title }}</span>  -->
                        </a>

                    </div>
                    <!-- <div class="category-card__content">
                        <div class="category-card__name">
                            <a [routerLink]="category.url">{{ category.title }}</a>
                        </div>

                        <div class="category-card__all">
                            <a [routerLink]="category.url">Show All</a>
                        </div>
                        <div class="category-card__products">
                            {{ category.products }} Products
                        </div>
                    </div>  -->
                    <!-- <a [routerLink]="category.url"><img [src]="category.image" alt=""></a> -->


            </div>
        </div>
    </div>
</div>
<!-- .block-categories / end -->
