<div class="search" [ngClass]="{'width-70': !authService.loggedIn()}" >
    <form class="search__form" action="">
        <input *ngIf="lang == 'en' && authService.en" class="search__input" name="search" placeholder="{{authService.en.search.search}}" aria-label="Site search" type="text" autocomplete="off" [(ngModel)]="search" [ngModelOptions]="{standalone: true}" (keyup.enter)="emitSearch()">
        <input *ngIf="lang == 'zh' && authService.zh" class="search__input" name="search" placeholder="{{authService.zh.search.search}}" aria-label="Site search" type="text" autocomplete="off" [(ngModel)]="search" [ngModelOptions]="{standalone: true}" (keyup.enter)="emitSearch()">

        <button class="search__button" type="submit" (click)="emitSearch()">
            <app-icon name="search-20" size="20"></app-icon>
        </button>
        <div class="search__border"></div>
    </form>
</div>
