import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-block-mall',
  templateUrl: './block-mall.component.html',
  styleUrls: ['./block-mall.component.sass']
})
export class BlockMallComponent {
 
  constructor() { }

 
}
