import { Injectable, Injector, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest,HttpResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';

import { ToastrService } from "ngx-toastr";
import {TranslateService} from '@ngx-translate/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Subject } from 'rxjs/Subject';
import {Title} from "@angular/platform-browser";
import * as uuid from 'uuid';
import { environment } from './../environments/environment';
import { CookieService } from 'ngx-cookie';

@Directive()
@Injectable()
export class AuthService {

  @Output() filter$: EventEmitter<boolean> = new EventEmitter();
  @Output() limit$: EventEmitter<boolean> = new EventEmitter();
  @Output() page$: EventEmitter<boolean> = new EventEmitter();
  @Output() search$: EventEmitter<boolean> = new EventEmitter();
  @Output() removeSearchKeyword$: EventEmitter<boolean> = new EventEmitter();
  @Output() orderPage$: EventEmitter<boolean> = new EventEmitter();
  @Output() reloadPage$: EventEmitter<boolean> = new EventEmitter();
  @Output() withdrawPage$: EventEmitter<boolean> = new EventEmitter();
  @Output() mobileMenu$: EventEmitter<boolean> = new EventEmitter();
  @Output() changePage$: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshProduct$: EventEmitter<boolean> = new EventEmitter();
  @Output() refreshBalance$: EventEmitter<boolean> = new EventEmitter();

  @Output() wonAuctionPage$: EventEmitter<boolean> = new EventEmitter();
  @Output() biddingPage$: EventEmitter<boolean> = new EventEmitter();
  @Output() paymentPage$: EventEmitter<boolean> = new EventEmitter();
  @Output() bidPaymentPage$: EventEmitter<boolean> = new EventEmitter();

  categoryFilter;
  categoryPage;
  categoryLimit;
  categorySearch;
  orderPage;
  reloadPage;
  withdrawPage;
  categoryStore;
  wonAuctionPage;
  biddingPage;
  paymentPage;
  bidPaymentPage;
  loadingSso = false;

  resetCategoryPageSetting(){
    this.categoryFilter = null;
    this.categoryPage = 1;
    this.categoryLimit = 9;
    this.categorySearch = null;
    this.resetCategoryPageStore();
  }

  resetCategoryPageStore(){
    this.categoryStore = null;
  }

  filterProductList(obj) {
      this.categoryFilter = obj;
      this.filter$.emit(obj);
  }

  limitProductList(limit) {
      this.categoryLimit = limit;
      this.limit$.emit(limit);
  }

  paginateProductList(page) {
      this.categoryPage = page;
      this.page$.emit(page);
  }

  searchProductList(search) {
      this.categorySearch = search;
      this.search$.emit(search);
  }

  removeSearchKeyword(){
    this.categorySearch = "";
    this.removeSearchKeyword$.emit();
  }

  paginateOrderList(page) {
      this.orderPage = page;
      this.orderPage$.emit(page);
  }

  paginateReloadList(page) {
      this.reloadPage = page;
      this.reloadPage$.emit(page);
  }

  paginateWithdrawList(page) {
      this.withdrawPage = page;
      this.withdrawPage$.emit(page);
  }

  paginateWonAuctionList(page) {
      this.wonAuctionPage = page;
      this.wonAuctionPage$.emit(page);
  }

  paginateBiddingList(page) {
      this.biddingPage = page;
      this.biddingPage$.emit(page);
  }

  paginatePaymentList(page) {
      this.paymentPage = page;
      this.paymentPage$.emit(page);
  }

  paginateBidPaymentList(page) {
      this.bidPaymentPage = page;
      this.bidPaymentPage$.emit(page);
  }




  updateMobileMenu() {
      this.mobileMenu$.emit();
  }

  changePage(page) {
      this.changePage$.emit(page);
  }

  refreshProduct(id) {
      this.refreshProduct$.emit(id);
  }

  refreshBalance() {
      this.refreshBalance$.emit();
  }

  locale : string = 'en';
  uuid : string;
  token : string;
  session : string;
  role : string;
  en : any;
  zh : any;
  currency : string = "USD";
  currencySymbol : string = "$";
  coinRateList : any[] = null;
  cart = null;
  loginToCheckout = false;

  /* reload weni & usdt */
  weniValue;
  usdtValue;
  usdtPointValue;
  weniTokenValue;
  otrValue;
  lpValue;
  pvValue;
  ppValue;
  mltValue;
  jdtValue;
  userCoinList = [];

  // url : string = '/api/';
  // wenUrl : string = '/wenmall/';

  url : string = 'http://47.244.18.65/';
  // wenUrl : string = 'http://47.244.18.65:8080/';
  wenUrl : string = environment.apiUrl;
  first = true;

  dataTableChinese : any = {
                          "sProcessing":   "处理中...",
                          "sLengthMenu":   "显示 _MENU_ 项结果",
                          "sZeroRecords":  "没有匹配结果",
                          "sInfo":         "显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项",
                          "sInfoEmpty":    "显示第 0 至 0 项结果，共 0 项",
                          "sInfoFiltered": "(由 _MAX_ 项结果过滤)",
                          "sInfoPostFix":  "",
                          "sSearch":       "搜索:",
                          "sUrl":          "",
                          "sEmptyTable":     "表中数据为空",
                          "sLoadingRecords": "载入中...",
                          "sInfoThousands":  ",",
                          "oPaginate": {
                              "sFirst":    "首页",
                              "sPrevious": "上页",
                              "sNext":     "下页",
                              "sLast":     "末页"
                          },
                          "oAria": {
                              "sSortAscending":  ": 以升序排列此列",
                              "sSortDescending": ": 以降序排列此列"
                          }
                        };


  public currencyObservable = new Subject<string>();
  emitCurrency(val) {
    this.currencyObservable.next(val);
    // console.log("emitCurrency - " + val);
  }

  emit(val) { // your value you want to emit
    this.emitCurrency(val);
    // console.log("emit - " + val);
  }

  constructor(private http: HttpClient, public router: Router, private toastr: ToastrService, private translate: TranslateService,
  private loadingService: LoadingBarService, private titleService:Title, private injector: Injector, public cookie: CookieService) {
    const languagePack = this.injector.get('languagePack');
    this.en = languagePack.en;
    this.zh = languagePack.zh;

    // if(localStorage.getItem("locale") != null){
    // if(this.cookie.get("locale") != null){
    if(this.getCookie("locale") != null){
      // this.locale = localStorage.getItem("locale");
      // this.locale = this.cookie.get("locale");
      this.locale = this.getCookie("locale");
    }


    this.getLocale();
    this.getCurrency();
    this.getToken();
    this.getSession();
    // if(this.isDev()){
    //   this.url = 'http://47.244.18.65/';
    //   this.wenUrl = 'http://47.244.18.65:8080/';
    // }
    // this.getCoinRateList();
  }

  start() {
    this.loadingService.start();
  }

  complete() {
    this.loadingService.complete();
  }

  thousandSeparator(n){
    try{
      return n.toLocaleString();
    }catch(err){
      n;
    }
  }

  // login(phone, password){
  login(email, password){
    this.start();
    this.getSession();
    return this.http.post(this.wenUrl + 'user/login',{email: email, password: password, locale: this.locale, session: this.session});
    // return this.http.post(this.wenUrl + 'user/login',{phone: phone, password: password, locale: this.locale, session: this.session});
  }

  // createUser(phone, password, otp){
  createUser(email, password, otp, name){
    this.start();
    this.getSession();
    return this.http.post(this.wenUrl + 'user/createuser',{email: email, password: password, locale: this.locale, session: this.session, otp: otp, name: name});
    // return this.http.post(this.wenUrl + 'user/createuser',{phone: phone, password: password, locale: this.locale, session: this.session, otp: otp});
  }

  logout() {
    localStorage.removeItem('wenimalltoken');
    // this.cookie.remove("wenimalltoken");
    this.setTokenCookie(null);
    this.router.navigate(['/classic/account/login']);
  }

  setTokenCookie(tok){
    if (tok) {
        document.cookie = "wenimalltoken=" + tok;
    }else{
      document.cookie = "wenimalltoken=null";
    }
    document.cookie = "domain=.weini.shop";
  }

  setLocaleCookie(loc){
    if (loc) {
        document.cookie = "locale=" + loc;
    }else {
      document.cookie = "locale=null";
    }
    document.cookie = "domain=.weini.shop";
  }

  setSessionCookie(ses){
    if (ses) {
        document.cookie = "session=" + ses;
    }else {
      document.cookie = "session=null";
    }
    document.cookie = "domain=.weini.shop";
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  setToken(tok){
    if(tok != undefined && tok.length > 0){
      this.token = tok;
      // console.log("this.locale:" + this.locale);
      // localStorage.setItem('wenimalltoken', this.token);
      // this.cookie.put("wenimalltoken", this.token);
      this.setTokenCookie(this.token);
    }
  }

  getToken(){
    // this.token = localStorage.getItem('wenimalltoken');
    // this.token = this.cookie.get("wenimalltoken");
    this.token = this.getCookie("wenimalltoken");
    if(this.token != null && this.token != 'undefined' && this.token != "null"){
      return this.token;
    }else{
      this.token = localStorage.getItem("wenimalltoken");
      if(this.token != null && this.token != 'undefined' && this.token != "null"){
        return this.token;
      }else{
        this.token = null;
        return this.token;
      }
    }
  }

  setCurrency(currency){
    this.currency = currency;
    if(this.currency == "USD"){
      this.currencySymbol = "$";
    }
    if(this.currency == "MYR"){
      this.currencySymbol = "RM";
    }
    if(this.currency == "CNY"){
      this.currencySymbol = "RMB";
    }
    // localStorage.setItem("currency", currency);
    this.cookie.put("currency", currency);
    // console.log(this.currency);
    // console.log(this.currencySymbol);
    this.emit(currency);
    this.getCurrency();
  }

  getCurrency(){
    // this.currency = localStorage.getItem('currency');
    // this.currency = this.cookie.get("currency");
    if(this.currency != null && this.currency != 'undefined'){
      if(this.currency == "USD"){
        this.currencySymbol = "$";
      }
      if(this.currency == "MYR"){
        this.currencySymbol = "RM";
      }
      if(this.currency == "CNY"){
        this.currencySymbol = "RMB";
      }
    }else{
      // this.currency = "USD";
      // this.currencySymbol = "$";
      this.setCurrency("USD");
    }
    return this.currency;
  }

  signUp(email,password,firstName,lastName,screenName){
    return this.http.post('/lisa/api/signup',{locale: this.locale, email:email, password:password, firstName:firstName, lastName:lastName, screenName:screenName});
  }
  forgotPwd(email){
    return this.http.post('/lisa/api/forgotpassword',{locale: this.locale, email:email});
  }

  //Setting
  changeScreenName(screenName){
    return this.http.post('/lisa/api/changescreenname',{locale: this.locale, token: this.token, screenName: screenName});
  }
  changePwd(currentPassword,newPassword){
    return this.http.post('/lisa/api/changepassword',{locale: this.locale, token: this.token, currentPassword: currentPassword, newPassword: newPassword});
  }

  // get program list
  getProgramList() {
    return this.http.post('/lisa/api/getprogramlist',{locale: this.locale, token: this.token});
  }

  //get contributor list
  getContributorList(){
    return this.http.post('/lisa/api/getcontributorlist',{locale: this.locale, token: this.token});
  }

  // get quiz list
  getQuizList(){
    return this.http.post('/lisa/api/getquizlist',{locale: this.locale, token: this.token});
  }
  getPublicQuizList(){
    return this.http.post('/lisa/api/getquizlist',{locale: this.locale});
  }
  getQuizListById(id){
    return this.http.post('/lisa/api/getquizbyid',{locale: this.locale, token: this.token, id: id});
  }

  // ADD/DELETE -- QUIZ
  addQuiz(topicId){
    return this.http.post('/lisa/api/addquiz',{locale: this.locale, token: this.token, topicId: topicId});
  }
  deleteQuiz(id){
    return this.http.post('/lisa/api/deletequiz',{locale: this.locale, token: this.token, id: id});
  }
  editQuizPublishStatus(id,status){
    return this.http.post('/lisa/api/changequizpublishstatus',{locale: this.locale, token: this.token, quizId: id, publish: status});
  }

  //GET/EDIT -- Contributor Profile
  getContributorProfile(id){
    return this.http.post('/lisa/api/getuserprofile',{locale: this.locale, token: this.token, id: id});
  }
  editContributorProfile(profile){
    profile.locale = this.locale;
    profile.token = this.token;
    return this.http.post('/lisa/api/edituserprofile', profile);
  }

  // ADD/EDIT/DELETE -- QUESTION
  addQuestion(question){
    question.locale = this.locale;
    question.token = this.token;
    return this.http.post('/lisa/api/addquestion', question);
  }
  editQuestion(question){
    question.locale = this.locale;
    question.token = this.token;
    return this.http.post('/lisa/api/editquestion', question);
  }
  deleteQuestion(id){
    return this.http.post('/lisa/api/deletequestion',{locale: this.locale, token: this.token, id: id});
  }

  //connection time out
  connectionTimeOut(){
    this.router.navigate(['/classic/account/login']);
    // localStorage.removeItem('wenimalltoken');
    // this.cookie.remove("wenimalltoken");
    this.setTokenCookie(null);
    // this.alertService.danger('Connection timed out!');
    // this.showToast("danger", "Connection timed out!");
  }

  // socialLogin(userData){
  //   this.http.post('/lisa/api/sociallogin',{locale: this.locale, email: userData.email, id: userData.id, image: userData.image, name: userData.name, provider: userData.provider}).subscribe(
  //     data => {
  //       if(data['status'] != 'error'){
  //         console.log(data);
  //         localStorage.setItem('token', data['result'].user.token);
  //         localStorage.setItem('user', JSON.stringify(data['result'].user));
  //         localStorage.setItem("locale", this.locale);
  //
  //         console.log('Login success...');
  //         this.router.navigate(['/index']);
  //       }else{
  //         //alert(data['errorMessage']);
  //         this.spinner.hide();
  //         this.alertService.danger(data['errorMessage']);
  //       }
  //     },
  //     error => console.log(error)
  //   );
  // }

  getRole(){
    // this.role = localStorage.getItem('role');
    this.role = this.cookie.get("role");
    if(this.role != null || this.role != 'undefined'){
      return this.role;
    }else{
      this.connectionTimeOut();
      // this.router.navigate(['/login']);
    }
  }

  isProd(){
    var hostname = window.location.hostname;
    var isProd = false;
    if(hostname == "http://47.244.18.65"){
      //test and localhost environment
      isProd = true;
    }else{
      //production environment
      isProd = false;
    }
    // console.log("isProd : " + isProd);
    return isProd;
  }

  isTest(){
    var hostname = window.location.hostname;
    var isTest = false;
    if(hostname == "http://47.244.18.65"){
      //test and localhost environment
      isTest = true;
    }else{
      //production environment
      isTest = false;
    }
    // console.log("isTest : " + isTest);
    return isTest;
  }

  isDev(){
    var hostname = window.location.hostname;
    var isDev = false;
    if(hostname == "localhost"){
      //test and localhost environment
      isDev = true;
    }else{
      //production environment
      isDev = false;
    }
    // console.log("isDev : " + isDev);
    return isDev;
  }

  showToast(alertType, message){
    // this.toastr.info(
    //   '<span class="now-ui-icons travel_info"></span> ' + message,
    //   "",
    //   {
    //     toastClass: "alert alert-" + alertType + " alert-with-icon"
    //   }
    // );
    if(alertType == "info"){
      this.toastr.info(
        message
      );
    }else if(alertType == "success"){
      this.toastr.success(
        message
      );
    }else if(alertType == "danger" || alertType == "error"){
      this.toastr.error(
        message
      );
    }
  }

  getTranslation(message){
    return this.translate.instant(message);
  }

  setLocale(lang){
    // console.log("setLocale(" + lang + ")");
    if(lang != undefined && lang.length > 0){
      this.locale = lang;
      // console.log("this.locale:" + this.locale);
      // localStorage.setItem('locale', this.locale);
      // this.cookie.put("locale", this.locale);
      this.setLocaleCookie(this.locale);
      this.translate.use(this.locale);
      this.titleService.setTitle(this.getTranslation("common.title"));
    }
  }

  getLocale(){
    // this.locale = localStorage.getItem('locale');
    // this.locale = this.cookie.get("locale");
    this.locale = this.getCookie("locale");
    if(this.locale != null && this.locale != 'undefined' && this.locale != "null"){

    }else{
      this.locale = "zh";
    }
    this.translate.use(this.locale);
    if(this.locale == "zh"){
      this.titleService.setTitle("唯你拍卖");
    }else if(this.locale == "en"){
      this.titleService.setTitle("Weini Auction");
    }
    return this.locale;
  }

  getDateStringFromObj(obj){
    var date = "";
    try{
      date = obj.year.toString() + "-" + obj.month.toString() + "-" + obj.day.toString();
    }catch(err){

    }
    return date;
  }

  canCallApi(){
    var url = this.router.url;
    // console.log(url);
    if(url == "/login" || url == "/forgotpassword" || url == "/changepassword"){
      return false;
    }else{
      return true;
    }
  }

  loggedIn(){
    var token = this.getToken();
    if(token != null && token != 'undefined'){
      // console.log("logged in");
      return true;
    }else{
      // console.log("logged out");
      return false;
    }
  }

  getAllProductList(){
    return this.http.post(this.wenUrl + 'product/all',{locale: this.locale});
  }

  getProductById(id){
    this.getToken();
    var tok = null;
    if(this.token){
      tok = this.token;
    }
    return this.http.post(this.wenUrl + 'auction/getbyid',{locale: this.locale, id: id, token: tok});
  }

  getAllCategoryList(){
    return this.http.post(this.wenUrl + 'category/all',{locale: this.locale});
  }

  getCategoryById(id){
    return this.http.post(this.wenUrl + 'category/getbyid',{locale: this.locale, id: id});
  }

  /* deprecated */
  getProductListByCategory(categoryId, page, limit){
    return this.http.post(this.wenUrl + 'product/getbycategory',{locale: this.locale, categoryId: categoryId, page: page, limit: limit});
  }

  getHomeProductList(){
    this.getToken();
    var tok = this.token;
    if(!tok){
      tok = null;
    }
    return this.http.post(this.wenUrl + 'auction/home',{token: tok, locale: this.locale});
  }

  getProductList(page, limit, categoryList, brandList, search, fromPriceUsdt, toPriceUsdt, fromPriceWeniPoint, toPriceWeniPoint, fromPriceOtr, toPriceOtr, fromPriceLp, toPriceLp, fromPriceUsdp, toPriceUsdp, store, status, currency, type){
    this.getToken();
    var tok = this.token;
    if(!tok){
      tok = null;
    }
    return this.http.post(this.wenUrl + 'auction/list',{token: tok, locale: this.locale, page: page, limit: limit, categoryList: categoryList, brandList: brandList, search: search, fromUsdt: fromPriceUsdt, toUsdt: toPriceUsdt, fromWeniPoint: fromPriceWeniPoint, toWeniPoint: toPriceWeniPoint, fromOtr: fromPriceOtr, toOtr: toPriceOtr, fromLp: fromPriceLp, toLp: toPriceLp, fromUsdp: fromPriceUsdp, toUsdp: toPriceUsdp, merchant: store, status: status, currency: currency, type: type});
  }

  /* cart */
  updateProductToCart(cartProductList){
    this.getSession();
    this.getToken();

    try{
      if(this.cart != null){
        if(cartProductList.length == 1){
          var cpList = this.cart.cartProductList;
          if(cpList == null){
            cpList = new Array();
          }
          var newCp = cartProductList[0];
          var found = false;
          cpList.forEach(cp => {
            if(cp.product.id == newCp.product.id && !found){
              //determine whether the 2 cart products have the same product attributes
              var sameAttributes = true;
              cp.selectedProductAttributeList.forEach(spa => {
                var found1 = false;
                newCp.selectedProductAttributeList.forEach(spa1 => {
                  if(spa1.productAttribute.id == spa.productAttribute.id && !found1){
                    found1 = true;
                  }
                });
                if(!found1){
                  sameAttributes = false;
                }
              });

              if(sameAttributes) {//if same product with same product attributes
                found = true;
                cp.quantity += newCp.quantity;
  						}else {//else if same product but different product attributes
  							found = false;
  						}
            }
          });
          if(!found){
            cpList.push(newCp);
          }
          cartProductList = cpList;
        }
      }
    }catch(err){

    }

    return this.http.post(this.wenUrl + 'cart/updateproduct',{locale: this.locale, token: this.token, session: this.session, cartProductList: cartProductList});
  }

  updateProductInCart(cartProductList){
    this.getSession();
    this.getToken();

    // console.log(cartProductList);

    return this.http.post(this.wenUrl + 'cart/updateproduct',{locale: this.locale, token: this.token, session: this.session, cartProductList: cartProductList});
  }

  removeProductFromCart(cartProductList){
    this.getSession();
    this.getToken();

    try{
      if(this.cart != null){
        if(cartProductList.length == 1){
          var cpList = this.cart.cartProductList;
          if(cpList == null){
            cpList = new Array();
          }
          var newCp = cartProductList[0];
          var newCpList = new Array();
          cpList.forEach(cp => {
            // if(cp.product.id == newCp.product.id){
            if(cp.id == newCp.id){

            }else{
              newCpList.push(cp);
            }
          });
          cartProductList = newCpList;
        }
      }
    }catch(err){

    }

    return this.http.post(this.wenUrl + 'cart/updateproduct',{locale: this.locale, token: this.token, session: this.session, cartProductList: cartProductList});
  }

  getCart(auto){
    this.getSession();
    this.getToken();
    if(auto){
      this.http.post(this.wenUrl + 'cart/get',{locale: this.locale, token: this.token, session: this.session}).subscribe(
          data => {
            if(data['status'] == 'ok'){
              // console.log(data);
              this.cart = data['result'];
            }
            this.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              // this.showToast("danger", error['error'].message);
            }
            this.complete();
          }
        );
    }else{
      return this.http.post(this.wenUrl + 'cart/get',{locale: this.locale, token: this.token, session: this.session});
    }
  }

  changePassword(oldPassword, newPassword, forgotPassword, token){
    return this.http.post(this.wenUrl + 'user/changepassword',{locale: this.locale, token: token, oldPassword: oldPassword, newPassword: newPassword, forgotPassword: forgotPassword});
  }

  // forgotPassword(phone){
  forgotPassword(email){
    this.getToken();
    return this.http.post(this.wenUrl + 'user/forgotpassword',{locale: this.locale, email: email});
    // return this.http.post(this.wenUrl + 'user/forgotpassword',{locale: this.locale, phone: phone});
  }

  validPasswordFormat(password){
    // var regx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
    // return regx.test(this.regPassword);

    var validated =  true;
      if(password.length < 8)
          validated = false;
      // if(!/\d/.test(this.regPassword))
      //     validated = false;
      // if(!/[a-z]/.test(this.regPassword))
      //     validated = false;
      // if(!/[A-Z]/.test(this.regPassword))
      //     validated = false;
      // if(/[^0-9a-zA-Z]/.test(this.regPassword))
      //     validated = false;

    return validated;
  }

  samePassword(password1, password2){
    if(password1 == password2){
      return true;
    }
  }


  constructFilterFromResponse(filter){
    const resultFilter = [];
    resultFilter.push({
        name: 'USDT',
        en: 'USDT',
        zh: 'USDT',
        type: 'priceusdt',
        options: {
            min: filter.minPriceUsdt,
            max: filter.maxPriceUsdt,
            from: filter.fromPriceUsdt,
            to: filter.toPriceUsdt
        }
    });

    resultFilter.push({
        name: 'WENI Point',
        en: 'WENI Point',
        zh: 'WENI 点数',
        type: 'pricewenipoint',
        options: {
            min: filter.minPriceWeniPoint,
            max: filter.maxPriceWeniPoint,
            from: filter.fromPriceWeniPoint,
            to: filter.toPriceWeniPoint
        }
    });

    resultFilter.push({
        name: 'OTR',
        en: 'OTR',
        zh: 'OTR',
        type: 'priceotr',
        options: {
            min: filter.minPriceOtr,
            max: filter.maxPriceOtr,
            from: filter.fromPriceOtr,
            to: filter.toPriceOtr
        }
    });

    resultFilter.push({
        name: 'LP',
        en: 'LP',
        zh: 'LP',
        type: 'pricelp',
        options: {
            min: filter.minPriceLp,
            max: filter.maxPriceLp,
            from: filter.fromPriceLp,
            to: filter.toPriceLp
        }
    });

    resultFilter.push({
        name: 'USDT Point',
        en: 'USDP',
        zh: 'USDP',
        type: 'priceusdp',
        options: {
            min: filter.minPriceUsdp,
            max: filter.maxPriceUsdp,
            from: filter.fromPriceUsdp,
            to: filter.toPriceUsdp
        }
    });

    var brands = [];
    filter.brandList.forEach(b => {
      var checked = false;
      filter.selectedBrandList.forEach(sb => {
          if(b.id == sb.id){
            checked = true;
          }
      });

      var item = {
        id: b.id,
        label: b.name,
        en: b.name,
        zh: b.name,
        count: 0,
        checked: checked,
        disabled: false
      };

      brands.push(item);
    });

    if(brands.length > 0){
      resultFilter.push({
            name: 'Brand',
            en: 'Brand',
            zh: '品牌',
            type: 'checkbox',
            options: {
                items: brands
            }
        });
    }
    return resultFilter;
  }

  getAttributeLocale(attribute, locale){
    var result = "";
    try{
      attribute.attributeLocaleList.forEach(al => {
        if(locale == "en" && al.language.id == 1){
          result = al.name;
        }else if(locale == "zh" && al.language.id == 2){
          result = al.name;
        }
      });
    }catch(err){

    }
    return result;
  }

  getAttributeValueLocale(av, locale){
    var result = "";
    try{
      av.attributeValueLocaleList.forEach(avl => {
        if(locale == "en" && avl.language.id == 1){
          result = avl.name;
        }else if(locale == "zh" && avl.language.id == 2){
          result = avl.name;
        }
      });
    }catch(err){

    }
    return result;
  }

  getSession(){
    // this.session = localStorage.getItem('session');
    // this.session = this.cookie.get("session");
    this.session = this.getCookie("session");
    if(this.session != null && this.session != 'undefined'){
      return this.session;
    }
  }

  newSession(){
    this.session = uuid.v4();
    // localStorage.setItem('session', this.session);
    // this.cookie.put("session", this.session);
    this.setSessionCookie(this.session);
  }

  getAllCountries(){
    return this.http.post(this.wenUrl + 'country/getall',{locale: this.locale});
  }

  getAddressList(){
    return this.http.post(this.wenUrl + 'address/getbyuser',{locale: this.locale, token: this.token});
  }

  createAddress(address){
    this.getToken();
    address.locale = this.locale;
    address.token = this.token;
    // address.postcode = String(address.postcode);
    return this.http.post(this.wenUrl + 'address/create',address);
  }

  updateAddress(address){
    this.getToken();
    address.locale = this.locale;
    address.token = this.token;
    // address.postcode = String(address.postcode);
    return this.http.post(this.wenUrl + 'address/update',address);
  }

  deleteAddress(address){
    this.getToken();
    address.locale = this.locale;
    address.token = this.token;
    // address.postcode = String(address.postcode);
    return this.http.post(this.wenUrl + 'address/delete',address);
  }

  createOrder(address){
    return this.http.post(this.wenUrl + 'order/create',{locale: this.locale, token: this.token, address: address});
  }

  getProfile(){
    return this.http.post(this.wenUrl + 'user/getprofile',{locale: this.locale, token: this.token});
  }

  createReload(reloadType, amount){
    var weni = false;
    var usdt = false;
    var usdtPoint = false;
    var weniToken = false;
    var pp = false;
    var mlt = false;
    var jdt = false;
    var coinCode = null;

    if(reloadType == 'WENI_POINT'){
      weni = true;
    }else if(reloadType == 'USDT'){
      usdt = true;
    }else if(reloadType == 'USDT_POINT'){
      usdtPoint = true;
    }else if(reloadType == 'WENI_TOKEN'){
      weniToken = true;
    }else if(reloadType == 'PP'){
      pp = true;
    }else if(reloadType == 'MLT'){
      mlt = true;
    }else if(reloadType == 'JDT'){
      jdt = true;
    }else{
      coinCode = reloadType;
    }
    return this.http.post(this.wenUrl + 'reload/create',{locale: this.locale, token: this.token, weni: weni, usdt: usdt, usdtPoint: usdtPoint, weniToken: weniToken, amount: amount, pp: pp, mlt: mlt, jdt: jdt, coinCode: coinCode});
  }

  cancelReload(){
    return this.http.post(this.wenUrl + 'reload/cancel',{locale: this.locale, token: this.token});
  }

  checkReload(){
    return this.http.post(this.wenUrl + 'reload/check',{locale: this.locale, token: this.token});
  }

  checkToken(){
    return this.http.post(this.wenUrl + 'user/checktoken',{locale: this.locale, token: this.token});
  }

  getOrderList(page, limit){
    return this.http.post(this.wenUrl + 'order/list',{locale: this.locale, token: this.token, page: page, limit: limit});
  }

  getLatestSetting(){
    return this.http.post(this.wenUrl + 'setting/getlatest', {locale: this.locale, token: this.token});
  }

  requestOtp(email){
    return this.http.post(this.wenUrl + 'user/requestotp',{locale: this.locale, email: email});
  }

  resendOtp(email){
    return this.http.post(this.wenUrl + 'user/resendotp',{locale: this.locale, email: email});
  }

  updateUser(name){
    return this.http.post(this.wenUrl + 'user/update',{locale: this.locale, token: this.token, name: name});
  }

  getReloadList(page, limit, status, currency, fromDate, toDate){
    return this.http.post(this.wenUrl + 'reload/list',{locale: this.locale, token: this.token, page: page, limit: limit, status: status, currency: currency, fromDate: fromDate, toDate: toDate});
  }

  createWithdraw(withdraw){
    return this.http.post(this.wenUrl + 'withdraw/create',{locale: this.locale, token: this.token, withdraw: withdraw});
  }

  getWithdrawList(page, limit){
    return this.http.post(this.wenUrl + 'withdraw/list',{locale: this.locale, token: this.token, page: page, limit: limit});
  }

  cancelWithdraw(withdraw){
    return this.http.post(this.wenUrl + 'withdraw/cancel',{locale: this.locale, token: this.token, withdraw: withdraw});
  }

  updateCheckoutFlag(id, checkout){
    return this.http.post(this.wenUrl + 'cart/updatecheckoutflag',{locale: this.locale, id: id, checkout: checkout});
  }

  merchantStorePage(store){
    return this.http.post(this.wenUrl + 'auction/merchantstorepage',{locale: this.locale, merchant: store});
  }

  sso(){
    return this.http.post(this.wenUrl + 'user/sso',{locale: this.locale});
  }

  checkSso(sessionId){
    return this.http.post(this.wenUrl + 'user/checksso',{locale: this.locale, sessionId: sessionId});
  }

  appendPlusSignProduct(product, currentCurrency){
    if(currentCurrency == "usdt"){

    }else if(currentCurrency == "weni"){
      if(product.salesPriceUsdt > 0){
        return "+";
      }
    }else if(currentCurrency == "usdtPoint"){
      if(product.salesPriceUsdt > 0 || product.salesPriceWeniToken > 0){
        return "+";
      }
    }else if(currentCurrency == "weniPoint"){
      if(product.salesPriceUsdt > 0 || product.salesPriceWeniToken > 0 || product.salesPriceUsdtPoint > 0){
        return "+";
      }
    }
    return "";
  }

  appendPlusSignOrderProduct(op, currentCurrency){
    if(currentCurrency == "usdt"){

    }else if(currentCurrency == "weni"){
      if(op.totalUsdt > 0){
        return "+";
      }
    }else if(currentCurrency == "usdtPoint"){
      if(op.totalUsdt > 0 || op.totalWeniToken > 0){
        return "+";
      }
    }else if(currentCurrency == "weniPoint"){
      if(op.totalUsdt > 0 || op.totalWeniToken > 0 || op.totalUsdtPoint > 0){
        return "+";
      }
    }
    return "";
  }

  appendPlusSignOrder(o, currentCurrency){
    if(currentCurrency == "usdt"){

    }else if(currentCurrency == "weni"){
      if(o.usdt > 0){
        return "+";
      }
    }else if(currentCurrency == "usdtPoint"){
      if(o.usdt > 0 || o.weniToken > 0){
        return "+";
      }
    }else if(currentCurrency == "weniPoint"){
      if(o.usdt > 0 || o.weniToken > 0 || o.usdtPoint > 0){
        return "+";
      }
    }
    return "";
  }

  createPayment(auctionId, type, confirm, auctionPrice){
    this.getToken();
    var auction = {
      id: auctionId
    };
    return this.http.post(this.wenUrl + 'payment/create',{locale: this.locale, token: this.token, auction: auction, type: type, confirm: confirm, auctionPrice: auctionPrice});
  }

  getWonAuctionList(page, limit, status, currency, fromDate, toDate){
    this.getToken();
    return this.http.post(this.wenUrl + 'auction/getwonauctionlist',{locale: this.locale, token: this.token, page: page, limit: limit, status: status, currency: currency, fromDate: fromDate, toDate: toDate});
  }

  getBiddingList(page, limit, status, currency, fromDate, toDate){
    this.getToken();
    return this.http.post(this.wenUrl + 'auction/getbiddinglist',{locale: this.locale, token: this.token, page: page, limit: limit, status: status, currency: currency, fromDate: fromDate, toDate: toDate});
  }

  handlePayment(id, status, address){
    this.getToken();
    return this.http.post(this.wenUrl + 'auction/handlepayment',{locale: this.locale, token: this.token, id: id, status: status, address: address});
  }

  getAssetList(){
    return this.http.post(this.wenUrl + 'asset/list',{locale: this.locale});
  }

  getPaymentList(type, page, limit, status, currency, fromDate, toDate){
    this.getToken();
    return this.http.post(this.wenUrl + 'payment/list',{locale: this.locale, token: this.token, type: type, page: page, limit: limit, status: status, currency: currency, fromDate: fromDate, toDate: toDate});
  }

  getRandomList(limit, status, notIdList){
    this.getToken();
    var tok = this.token;
    if(!tok){
      tok = null;
    }
    return this.http.post(this.wenUrl + 'auction/randomlist',{locale: this.locale, token: tok, limit: limit, status: status, notIdList: notIdList});
  }

  getMoreList(limit, status, notIdList, type){
    this.getToken();
    var tok = this.token;
    if(!tok){
      tok = null;
    }
    return this.http.post(this.wenUrl + 'auction/morelist',{locale: this.locale, token: tok, limit: limit, status: status, notIdList: notIdList, type: type});
  }

  parseAuction(auction){
    if(auction.type == null){
      auction.type = "NORMAL";
    }
    if(auction.type == "WAIT"){
      if(auction.auctionBidList.length == 0){
        auction.auctionEndDate = new Date(auction.auctionStartDate).getTime() + (auction.inactiveDuration * 60 * 1000);
      }else{
        auction.auctionEndDate = new Date(auction.firstAuctionBid.createdDate).getTime() + (auction.duration * 60 * 1000);
      }
    }else if(auction.type == "FOREVER"){
      if(auction.auctionBidList.length == 0){
        auction.auctionEndDate = new Date(auction.auctionStartDate).getTime() + (auction.inactiveDuration * 60 * 1000);
      }else{
        auction.auctionEndDate = new Date(auction.auctionBidList[0].createdDate).getTime() + (auction.duration * 60 * 1000);
      }
    }
    return auction;
  }

  parseAuctionList(auctionList){
    auctionList.forEach(auction => {
      auction = this.parseAuction(auction);
    })
    return auctionList;
  }

  getCoinList(){
    return this.http.post(this.wenUrl + 'coin/all',{locale: this.locale});
  }

}
