import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Inject, HostListener } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthService } from '../../../auth.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-auction-price',
  templateUrl: './auction-price.component.html',
  styleUrls: ['./auction-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuctionPriceComponent implements OnInit {

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
     if(event.key == "."){
       event.returnValue = false;
       event.preventDefault();
     }else if(event.key == "-"){
       event.returnValue = false;
       event.preventDefault();
     }
  }

  lang = "";
  name = "";
  showUsdt = false;
  showWeniPoint = false;
  showOtr = false;
  showLp = false;
  showUsdp = false;
  usdt = null;
  weniPoint = null;
  otr = null;
  lp = null;
  usdp = null;

  showDynamic = false;
  apList = [];


  constructor(public dialogRef: MatDialogRef<AuctionPriceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public authService: AuthService, private translate: TranslateService,
    private ref: ChangeDetectorRef) {
      if(this.data.result.result.usdt){
        this.showUsdt = true;
        this.usdt = this.data.result.result.usdt;
      }
      if(this.data.result.result.weniPoint){
        this.showWeniPoint = true;
        this.weniPoint = this.data.result.result.weniPoint;
      }
      if(this.data.result.result.otr){
        this.showOtr = true;
        this.otr = this.data.result.result.otr;
      }
      if(this.data.result.result.lp){
        this.showLp = true;
        this.lp = this.data.result.result.lp;
      }
      if(this.data.result.result.usdp){
        this.showUsdp = true;
        this.usdp = this.data.result.result.usdp;
      }
      if(this.data.result.result.apList && this.data.result.result.apList.length > 0){
        this.showDynamic = true;
        this.data.result.result.apList.forEach(ap => {
          this.apList.push(ap);
        });
      }
    }

  ngOnInit() {
    this.lang = this.authService.getLocale();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
    });

  }

  cancel(): void {
    this.dialogRef.close();
  }

  pay() {
    if(!this.validation()){
      this.authService.showToast("danger", this.authService.getTranslation("message.validAmountRequired"));
      return;
    }
    this.dialogRef.close({
      type: this.data.type
    });
  }

  validation(){
    // console.log("usdt : " + this.usdt + " vs " + this.data.result.result.usdt);
    // console.log("weniPoint : " + this.weniPoint + " vs " + this.data.result.result.weniPoint);
    // console.log("otr : " + this.otr + " vs " + this.data.result.result.otr);
    // console.log("lp : " + this.lp + " vs " + this.data.result.result.lp);
    // console.log("usdp : " + this.usdp + " vs " + this.data.result.result.usdp);

    if(this.usdt && this.usdt > 0){
      if(!this.data.result.result.usdt || this.data.result.result.usdt < this.usdt){
        return false;
      }
    }
    if(this.weniPoint && this.weniPoint > 0){
      if(!this.data.result.result.weniPoint || this.data.result.result.weniPoint < this.weniPoint){
        return false;
      }
    }
    if(this.otr && this.otr > 0){
      if(!this.data.result.result.otr || this.data.result.result.otr < this.otr){
        return false;
      }
    }
    if(this.lp && this.lp > 0){
      if(!this.data.result.result.lp || this.data.result.result.lp < this.lp){
        return false;
      }
    }
    if(this.usdp && this.usdp > 0){
      if(!this.data.result.result.usdp || this.data.result.result.usdp < this.usdp){
        return false;
      }
    }
    if(this.showDynamic){
      var result = true;
      if(this.data.result.result.apList.length == 0){
        result = false;
      }
      this.data.result.result.apList.forEach((ap, index) => {
        if(!ap.amount || ap.amount < this.apList[index].amount){
          result = false;
        }
      });
      if(!result){
        return false;
      }
    }
    return true;
  }

  validateVal(currency){
    if(currency == "USDT"){
      try{
        if(this.data.result.result.usdt < 0){
          this.data.result.result.usdt = 0;
        }
      }catch(err){
        this.data.result.result.usdt = 0;
      }
    }else if(currency == "WENI_POINT"){
      try{
        if(this.data.result.result.weniPoint < 0){
          this.data.result.result.weniPoint = 0;
        }
      }catch(err){
        this.data.result.result.weniPoint = 0;
      }
    }else if(currency == "OTR"){
      try{
        if(this.data.result.result.otr < 0){
          this.data.result.result.otr = 0;
        }
      }catch(err){
        this.data.result.result.otr = 0;
      }
    }else if(currency == "LP"){
      try{
        if(this.data.result.result.lp < 0){
          this.data.result.result.lp = 0;
        }
      }catch(err){
        this.data.result.result.lp = 0;
      }
    }else if(currency == "USDT_POINT"){
      try{
        if(this.data.result.result.usdp < 0){
          this.data.result.result.usdp = 0;
        }
      }catch(err){
        this.data.result.result.usdp = 0;
      }
    }else {
      try{
        this.data.result.result.apList.forEach(ap => {
          try{
            if(ap.amount < 0){
              ap.amount = 0;
            }
          }catch(err){
            ap.amount = 0;
          }
        });
      }catch(err){

      }
    }
  }
}
