import { Component, OnInit, OnDestroy } from '@angular/core';
import { CurrencyService } from '../../../../shared/services/currency.service';
import { AuthService } from '../../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';


@Component({
    selector: 'app-header-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {
    short = "EN";
    languages = [
        {name: 'English', image: 'language-1', locale: 'en', short: 'EN'},
        {name: '中文',  image: 'language-2', locale: 'zh', short: '中文'},

    ];

    currencies = [

        {name: 'Wen Wencoins',      url: '', code: 'WEN', symbol: 'Wen'},

    ];

    menuItemsEn = [
        // {label: 'Dashboard',     url: './account/dashboard'},
        // {label: 'Edit Profile',  url: './account/profile'},
        {label: 'Edit Profile', url: './account/profile'},
        {label: 'Reload', url: './account/reload'},
        {label: 'Reload History', url: './account/reload-history'},
        {label: 'Deposit History', url: './account/deposit'},
        {label: 'Bid History', url: './account/bid'},
        {label: 'My Biddings', url: './account/bidding'},
        {label: 'My Won Auctions', url: './account/orders'},
       // {label: 'Addresses',     url: './account/addresses'},
        {label: 'Withdrawal', url: './account/withdraw'},
        {label: 'Password',      url: './account/password'},
        {label: 'Logout',        url: './account/login', logout: true}
    ];

    menuItemsZh = [
        // {label: '仪表板',     url: './account/dashboard'},
        // {label: 'Edit Profile',  url: './account/profile'},

        {label: '编辑个人资料', url: './account/profile'},
        {label: '充值', url: './account/reload'},
          {label: '充值历史', url: './account/reload-history'},
          {label: '保证金历史', url: './account/deposit'},
          {label: '竞标历史', url: './account/bid'},
          {label: '我的竞拍', url: './account/bidding'},
        {label: '我的获拍', url: './account/orders'},
        //{label: '地址',     url: './account/addresses'},
        {label: '提款', url: './account/withdraw'},
        {label: '密碼',      url: './account/password'},
        {label: '登出',        url: './account/login', logout: true}
    ];

    menuItems = [];
    lang = "";
    weni;
    usdt;
    interval;

    constructor(
        public currencyService: CurrencyService,
        private translate: TranslateService, public authService: AuthService
    ) {
      this.authService.refreshBalance$.subscribe(obj => {
         this.getProfile();
       });
    }

    ngOnInit(){
      if(this.authService.loggedIn()){
        this.getProfile();
        this.startInterval();
      }
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });

      if(this.lang == "en"){
        this.short = "EN";
        this.menuItems = this.menuItemsEn;
      }else if(this.lang == "zh"){
        this.short = "中文";
        this.menuItems = this.menuItemsZh;
      }
    }

    ngOnDestroy() {
      this.stopInterval();
    }

    setCurrency(currency): void {
        this.currencyService.options = {
            code: currency.code,
            display: currency.symbol,
        };
    }

    changeLanguage(language) {
      this.lang = language;
      this.authService.setLocale(language);
      if(this.lang == "en"){
        this.short = "EN";
        this.menuItems = this.menuItemsEn;
      }else if(this.lang == "zh"){
        this.short = "中文";
        this.menuItems = this.menuItemsZh;
      }
    }

    getProfile(){
      this.authService.start();
      this.authService.getProfile().subscribe(
          data => {
            if(data['status'] == 'ok'){
              this.authService.weniValue = data['result'].weni;
              this.authService.usdtValue = data['result'].usdt;
              this.authService.usdtPointValue = data['result'].usdtPoint;
              this.authService.weniTokenValue = data['result'].weniToken;
              this.authService.otrValue = data['result'].otr;
              this.authService.lpValue = data['result'].lp;
              this.authService.pvValue = data['result'].pv;
              this.authService.ppValue = data['result'].pp;
              this.authService.mltValue = data['result'].mlt;
              this.authService.jdtValue = data['result'].jdt;
              this.authService.userCoinList = data['result'].userCoinList;
            }else if(data['status'] == 'error'){
              if(data['errorCode'] == 'request.error.token.invalid'){
                this.authService.connectionTimeOut();
              }else{
                // this.authService.showToast("danger", data['errorMessage']);
              }
            }else{
              this.authService.showToast("danger", this.authService.getTranslation("message.generalError"));
            }
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }

    startInterval(){
      this.interval = setInterval(()=>{
        this.getProfile();
      }, 5000);
    }

    stopInterval(){
      clearInterval(this.interval);
    }
}
