import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timecounter',
  templateUrl: './timecounter.component.html',
  styleUrls: ['./timecounter.component.sass']
})
export class TimecounterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
