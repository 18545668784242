import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { Product } from '../../interfaces/product';
import { WishlistService } from '../../services/wishlist.service';
import { CompareService } from '../../services/compare.service';
import { QuickviewService } from '../../services/quickview.service';
import { RootService } from '../../services/root.service';
import { CurrencyService } from '../../services/currency.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'app-custom-product-card-modified',
  templateUrl: './custom-product-card-modified.component.html',
  styleUrls: ['./custom-product-card-modified.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomProductCardModifiedComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject();

  @Input() product: any;
    @Input() layout: 'grid-sm'|'grid-nl'|'grid-lg'|'list'|'horizontal'|null = null;

    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;
    showingQuickview = false;
    lang = "";

    attributeList: any[] = [];
    counter$: Observable<number>;
    count = null;
    day = false;

    constructor(
        private cd: ChangeDetectorRef,
        public root: RootService,
        public cart: CartService,
        public wishlist: WishlistService,
        public compare: CompareService,
        public quickview: QuickviewService,
        public currency: CurrencyService,
        private translate: TranslateService,
        public authService: AuthService
    ) {

    }

    detectChange(){
      if(!this.cd['destroyed']){
       this.cd.detectChanges();
      }
    }

    ngOnInit() {
        this.lang = this.authService.getLocale();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
          this.lang = event.lang;
          try{
            this.cd.detectChanges();
          }catch(err){

          }
        });

        this.currency.changes$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.cd.markForCheck();
        });

        try{
          var d1 = new Date();
          if(this.product.timestamp){
            d1 = new Date(this.product.timestamp);
          }
          var d2 = null;
          if(this.product.status == "ACTIVE"){
              if(this.product.auctionStartDate){
                d2 = new Date(this.product.auctionStartDate);
              }
          }else if(this.product.status == "START"){
              if(this.product.auctionEndDate){
                d2 = new Date(this.product.auctionEndDate);
              }
          }
          if(d2){
            var dif = d2.getTime() - d1.getTime();
            dif = dif / 1000;
            if(dif < 0){
              dif = 0;
            }
            this.count = dif;
            if(this.count > 86400){
              this.count -= 86400;
              this.day = true;
            }else{
              this.day = false;
            }
            // this.counter$ = timer(0,1000).pipe(
            //    take(this.count),
            //    map(() => --this.count)
            //  );
          }
        }catch(err){

        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.cd.detach();
    }

    parseCountdown(cd){
      var day = null;
      var hour = null;
      var min = null;
      var sec = null;
      if(cd < 60) { //seconds
        sec = cd;
      }else if(cd < 3600) { // minutes and seconds
        min = cd / 60;
        sec = cd % 60;

      }else if(cd < 86400) { //hours, minutes and seconds
        hour = cd / 3600;
  			var cd2 = cd % 3600;
  			min = cd2 / 60;
  			sec = cd2 % 60;
      }else { //days, hours, minutes, and seconds
        day = cd / 86400;
  			var cd2 = cd % 86400;
  			hour = cd2 / 3600;
  			var cd3 = cd2 % 3600;
  			min = cd3 / 60;
  			sec = cd3 % 60;
      }

      var str = "";
      if(this.lang == 'en'){
        if(day && day >= 1){
          str += Math.floor(day) + "d";
        }
        if(hour && hour >= 1){
          str += " " + Math.floor(hour) + "h";
        }
        if(min && min >= 1){
          str += " " + Math.floor(min) + "m";
        }
        if(sec && sec >= 1){
          str += " " + Math.floor(sec) + "s";
        }
      }else if(this.lang == 'zh'){
        if(day && day >= 1){
          str += Math.floor(day) + " 天";
        }
        if(hour && hour >= 1){
          str += " " + Math.floor(hour) + " 时";
        }
        if(min && min >= 1){
          str += " " + Math.floor(min) + " 分";
        }
        if(sec && sec >= 1){
          str += " " + Math.floor(sec) + " 秒";
        }
      }
      return str;
    }
}
