import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AuthService } from '../../../auth.service';

@Component({
    selector: 'app-custom-block-products',
    templateUrl: './custom-block-products.component.html',
    styleUrls: ['./custom-block-products.component.scss'],
  	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomBlockProductsComponent implements OnInit, OnDestroy{
    @Input() header: {};
    @Input() layout: 'large-first'|'large-last' = 'large-first';
    @Input() products: any[] = [];
    @Input() bannerType: 'SIDE'|'USDT'|'USDP';
    @Input() banner;
    @Input() moreParam = null;


    get large(): any {
        if (this.layout === 'large-first' && this.products.length > 0) {
            return this.products[0];
        } else if (this.layout === 'large-last' && this.products.length > 0) {
            return this.products[0];
        }

        return null;
    }

    get smalls(): any[] {
        if (this.layout === 'large-first') {
            return this.products.slice(0, 6);
        } else  {
            return this.products.slice(0, 6);
        }
    }

    constructor(private authService: AuthService,
    private changeDetectorRefs: ChangeDetectorRef) {
      // this.getAssetList();
    }

    ngOnInit(){
      this.detectChange();
    }

    ngOnDestroy() {
      this.changeDetectorRefs.detach();
    }

    detectChange(){
      if(!this.changeDetectorRefs['destroyed']){
       this.changeDetectorRefs.detectChanges();
      }
    }

    getAssetList(){
      this.authService.start();
      this.authService.getAssetList().subscribe(
          data => {
            if(data['status'] == 'ok'){
              var list = data['result'];
              list.forEach(a => {
                if(a.type == this.bannerType){
                  if(!this.banner){
                    this.banner = a;
                  }
                }
              });
            }
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              // this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }

    trackByProduct(product){
      return product && product.id;
    }

}
