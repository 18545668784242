import { Component, OnInit } from '@angular/core';
import { CartService } from '../../../../shared/services/cart.service';
import { AuthService } from '../../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-mobile-fixed-menu',
  templateUrl: './mobile-fixed-menu.component.html',
  styleUrls: ['./mobile-fixed-menu.component.sass'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class MobileFixedMenuComponent implements OnInit {
  mobileView = false;
  mallUrl : string = environment.mallUrl;
  onResize(event) {
    this.validateView(event.target.innerWidth);
  }
  validateView(width){
    if(width < 576){
      this.mobileView = true;
      this.accountUrl = "./account/profile"
    }else{
      this.mobileView = false;
    }
  }
  
  lang = "";
  searchh = "";
  accountUrl = "./account/login";
    constructor(
     
        public cart: CartService,
        public authService: AuthService,
        public translate: TranslateService
    ) { }


  ngOnInit() {
    this.validateView(window.innerWidth);
  }

}
