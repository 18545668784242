export const categories = [
    {title: 'Shoes & Bags	', url: '../shop/30', products: 572, image: 'assets/images/categories/bags.jpg', subcategories: [
       {title: 'Screwdrivers', url: '../shop'},
        {title: 'Milling Cutters', url: '../shop'},
        {title: 'Sanding Machines', url: '../shop'},
        {title: 'Wrenches', url: '../shop'},
        {title: 'Drills', url: '../shop'}
    ]},
    {title: 'Sports & Outdoors	', url: '../shop/31', products: 134, image: 'assets/images/categories/watches.jpg', subcategories: [
        {title: 'Screwdrivers', url: '../shop'},
        {title: 'Hammers', url: '../shop'},
        {title: 'Spanners', url: '../shop'},
        {title: 'Handsaws', url: '../shop'},
        {title: 'Paint Tools', url: '../shop'}
    ]},
    {title: 'Fashion Accessories	', url: '../shop/29', products: 301, image: 'assets/images/categories/accessories.jpg', subcategories: [
        {title: 'Lathes', url: '../shop'},
        {title: 'Milling Machines', url: '../shop'},
        {title: 'Grinding Machines', url: '../shop'},
        {title: 'CNC Machines', url: '../shop'},
        {title: 'Sharpening Machines', url: '../shop'}
    ]},
    {title: 'TV & Home Appliances	', url: '../shop/32', products: 79, image: 'assets/images/categories/shoeshats.jpg', subcategories: [
        {title: 'Generators', url: '../shop'},
        {title: 'Compressors', url: '../shop'},
        {title: 'Winches', url: '../shop'},
        {title: 'Plasma Cutting', url: '../shop'},
        {title: 'Electric Motors', url: '../shop'}
    ]},
    {title: 'Ladies Fashion	', url: '../shop/27', products: 366, image: 'assets/images/categories/womenfashion.jpg', subcategories: [
        {title: 'Tape Measure', url: '../shop'},
        {title: 'Theodolites', url: '../shop'},
        {title: 'Thermal Imagers', url: '../shop'},
        {title: 'Calipers', url: '../shop'},
        {title: 'Levels', url: '../shop'}
    ]},
    {title: 'Beauty Care', url: '../shop/39', products: 81, image: 'assets/images/categories/mensfashion.jpg', subcategories: [
        {title: 'Winter Workwear', url: '../shop'},
        {title: 'Summer Workwear', url: '../shop'},
        {title: 'Helmets', url: '../shop'},
        {title: 'Belts and Bags', url: '../shop'},
        {title: 'Work Shoes', url: '../shop'}
    ]},

    {title: 'Beauty Care', url: '../shop/39', products: 81, image: 'assets/images/categories/beauty.jpg', subcategories: [
        {title: 'Winter Workwear', url: '../shop'},
        {title: 'Summer Workwear', url: '../shop'},
        {title: 'Helmets', url: '../shop'},
        {title: 'Belts and Bags', url: '../shop'},
        {title: 'Work Shoes', url: '../shop'}
    ]},

    {title: 'Beauty Care', url: '../shop/39', products: 81, image: 'assets/images/categories/healthcare.jpg', subcategories: [
        {title: 'Winter Workwear', url: '../shop'},
        {title: 'Summer Workwear', url: '../shop'},
        {title: 'Helmets', url: '../shop'},
        {title: 'Belts and Bags', url: '../shop'},
        {title: 'Work Shoes', url: '../shop'}
    ]},


];
