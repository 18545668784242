import { Component, OnInit } from '@angular/core';
import { CartService } from '../../../../shared/services/cart.service';
import { CartItem } from '../../../../shared/interfaces/cart-item';
import { RootService } from '../../../../shared/services/root.service';
import { AuthService } from '../../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-header-dropcart',
    templateUrl: './dropcart.component.html',
    styleUrls: ['./dropcart.component.scss']
})
export class DropcartComponent implements OnInit{
    removedItems: CartItem[] = [];
    lang = "";

    constructor(
        public cart: CartService,
        public root: RootService,
        private translate: TranslateService,
        public authService: AuthService
    ) {
    }

    remove(item: CartItem): void {
        if (this.removedItems.includes(item)) {
            return;
        }

        this.removedItems.push(item);
        this.cart.remove(item).subscribe({complete: () => this.removedItems = this.removedItems.filter(eachItem => eachItem !== item)});
    }

    ngOnInit(){
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });
    }

    checkout(){
      if(!this.authService.loggedIn()){
        this.authService.loginToCheckout = true;
        this.authService.router.navigate(['/classic/account/login']);
      }else{
        this.authService.loginToCheckout = false;
        this.authService.router.navigate(['/classic/shop/checkout']);
      }
    }
}
