<!-- .topbar -->
<div class="site-header__topbar topbar">
    <div class="topbar__container container">
        <div class="topbar__row">
            <div class="topbar__item topbar__item--link" *ngIf="authService.loggedIn()">
                <a class="topbar-link">{{'topbar.otr' | translate}}: <b *ngIf="authService.otrValue"> {{authService.otrValue}} </b><b *ngIf="!authService.otrValue"> 0 </b></a>

            </div>
            <div class="topbar__item topbar__item--link" *ngIf="authService.loggedIn()">
                <a class="topbar-link">{{'topbar.lp' | translate}}:<b *ngIf="authService.lpValue"> {{authService.lpValue}} </b><b *ngIf="!authService.lpValue"> 0 </b></a>
            </div>

            <div class="topbar__item topbar__item--link" *ngIf="authService.loggedIn()">
                <a class="topbar-link">{{'topbar.pv' | translate}}:<b *ngIf="authService.pvValue"> {{authService.pvValue}} </b><b *ngIf="!authService.pvValue"> 0 </b></a>
            </div>
            <!-- <div class="topbar__item topbar__item--link" *ngIf="authService.loggedIn()">
                <a class="topbar-link" routerLink="./account/reload"> {{'topbar.reload' | translate}}</a>
            </div> -->


            <div class="topbar__spring"></div>
            <div class="topbar__item topbar__item--link" *ngIf="!authService.loggedIn()">
                <a class="topbar-link" routerLink="./account/login" *ngIf="lang == 'en' && authService.en">  {{authService.en.login.login}} / {{authService.en.login.register}}</a>
                <a class="topbar-link" routerLink="./account/login" *ngIf="lang == 'zh' && authService.zh">  {{authService.zh.login.login}} / {{authService.zh.login.register}}</a>
            </div>

            <div class=" topbar__item site-header__phone " *ngIf="authService.loggedIn()">
                <!-- <div class="site-header__phone-title" *ngIf="lang == 'en' && authService.en">{{authService.en.header.customerService}}</div>
                <div class="site-header__phone-title" *ngIf="lang == 'zh' && authService.zh">{{authService.zh.header.customerService}}</div>

                <div class="site-header__phone-number">{{ store.primaryPhone }}</div> -->
                <div class="dropdown pr-2">
                    <div class=" dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {{'reload.balance' | translate}}
                    </div>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                      <a class="dropdown-item">{{'topbar.usdt' | translate}}: <b *ngIf="authService.usdtValue"> {{authService.usdtValue}} </b><b *ngIf="!authService.usdtValue"> 0 </b></a>

                      <a class="dropdown-item">{{'topbar.weni' | translate}}: <b *ngIf="authService.weniTokenValue"> {{authService.weniTokenValue}} </b><b *ngIf="!authService.weniTokenValue"> 0 </b></a>

                      <a class="dropdown-item">{{'topbar.weniPoints' | translate}}: <b *ngIf="authService.weniValue"> {{authService.weniValue}} </b><b *ngIf="!authService.weniValue"> 0 </b></a>

                      <a class="dropdown-item">{{'topbar.usdtPoints' | translate}}: <b *ngIf="authService.usdtPointValue"> {{authService.usdtPointValue}} </b><b *ngIf="!authService.usdtPointValue"> 0 </b></a>

                      <a class="dropdown-item">{{'topbar.pp' | translate}}: <b *ngIf="authService.ppValue"> {{authService.ppValue}} </b><b *ngIf="!authService.ppValue"> 0 </b></a>

                      <a class="dropdown-item">{{'topbar.mlt' | translate}}: <b *ngIf="authService.mltValue"> {{authService.mltValue}} </b><b *ngIf="!authService.mltValue"> 0 </b></a>

                      <a class="dropdown-item">{{'topbar.jdt' | translate}}: <b *ngIf="authService.jdtValue"> {{authService.jdtValue}} </b><b *ngIf="!authService.jdtValue"> 0 </b></a>

                      <div *ngFor="let pc of authService.userCoinList">
                        <a class="dropdown-item"><span *ngIf="lang =='en'">{{pc.nameEn}}</span><span *ngIf="lang == 'zh'">{{pc.nameZh}}</span>: <b> {{pc.amount}} </b></a>
                      </div>
                    </div>
                  </div>
                </div>

            <div class="topbar__item" *ngIf="authService.loggedIn()">
                <div class="topbar-dropdown" appDropdown="topbar-dropdown--opened" #accountDropdown="appDropdown">
                    <button class="topbar-dropdown__btn" type="button" (click)="accountDropdown.toggle()">
                        <div *ngIf="lang == 'en' && authService.en">{{authService.en.topbar.myAccount}}</div>
                        <div *ngIf="lang == 'zh' && authService.zh">{{authService.zh.topbar.myAccount}}</div>
                        <app-icon name="arrow-rounded-down-7x5" size="7x5"></app-icon>
                    </button>

                    <div class="topbar-dropdown__body">
                        <app-header-menu layout="topbar" [items]="menuItems"></app-header-menu>
                    </div>
                </div>
              </div>

              <!-- <div class="topbar__item">
                <div class="topbar-dropdown" appDropdown="topbar-dropdown--opened" #currencyDropdown="appDropdown">
                    <button class="topbar-dropdown__btn" type="button" (click)="currencyDropdown.toggle()">
                        Currency: <span class="topbar__item-value">{{ currencyService.options.code }}</span>
                        <app-icon name="arrow-rounded-down-7x5" size="7x5"></app-icon>
                    </button>

                    <div class="topbar-dropdown__body">
                        <ul class="menu menu--layout--topbar">
                            <li *ngFor="let currency of currencies">
                                <button type="button" (click)="setCurrency(currency); currencyDropdown.close();">
                                    {{ currency.name }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
            <div class="topbar__item">
                <div class="topbar-dropdown" appDropdown="topbar-dropdown--opened" #languageDropdown="appDropdown">
                    <button class="topbar-dropdown__btn" type="button" (click)="languageDropdown.toggle()" *ngIf="lang == 'en' && authService.en">
                        {{authService.en.topbar.language}}: <span class="topbar__item-value">{{short}}</span>
                        <app-icon name="arrow-rounded-down-7x5" size="7x5"></app-icon>
                    </button>
                    <button class="topbar-dropdown__btn" type="button" (click)="languageDropdown.toggle()" *ngIf="lang == 'zh' && authService.zh">
                        {{authService.zh.topbar.language}}: <span class="topbar__item-value">{{short}}</span>
                        <app-icon name="arrow-rounded-down-7x5" size="7x5"></app-icon>
                    </button>

                    <div class="topbar-dropdown__body">
                        <ul class="menu menu--layout--topbar menu--with-icons">
                            <li *ngFor="let language of languages">
                                <button type="button" (click)="changeLanguage(language.locale); languageDropdown.toggle();">
                                    <span class="menu__icon">
                                        <img [src]="'assets/images/languages/'+language.image+'.png'"
                                             [srcset]="'assets/images/languages/'+language.image+'.png 1x,' +
                                                       'assets/images/languages/'+language.image+'@2x.png 2x'" alt="">
                                    </span>
                                    {{ language.name }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- .topbar / end -->
