import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CartService } from '../../../shared/services/cart.service';
import { Product } from '../../../shared/interfaces/product';
import { WishlistService } from '../../../shared/services/wishlist.service';
import { CompareService } from '../../../shared/services/compare.service';
import { QuickviewService } from '../../../shared/services/quickview.service';
import { RootService } from '../../../shared/services/root.service';
import { CurrencyService } from '../../../shared/services/currency.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { BlockHeaderGroup } from '../../../shared/interfaces/block-header-group';

export type Layout = 'grid'|'grid-with-features'|'list';

@Component({
  selector: 'app-block-product-custom-verticle',
  templateUrl: './block-product-custom-verticle.component.html',
  styleUrls: ['./block-product-custom-verticle.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockProductCustomVerticleComponent implements OnInit, OnDestroy  {
  private destroy$: Subject<void> = new Subject();

  @Input() header: {};
  @Input() grid: 'grid-3-sidebar'|'grid-4-full'|'grid-5-full' = 'grid-5-full';
  @Input() rows = 1;
  @Input() products: Product[] = [];
  @Input() groups: BlockHeaderGroup[] = [];
  @Input() withSidebar = false;
  @Input() loading = false;
  @Input() offcanvas: 'always'|'mobile' = 'mobile';
  @Input() page;
  @Input() limit;
  @Input() total;
  @Input() finalPage;
  @Input() layout: Layout = 'grid';

  columns: Product[][] = [];

  @Input() product: any;
  @Input() moreParam = null;

  addingToCart = false;
  addingToWishlist = false;
  addingToCompare = false;
  showingQuickview = false;
  lang = "";

  attributeList: any[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    public root: RootService,
    public cart: CartService,
    public wishlist: WishlistService,
    public compare: CompareService,
    public quickview: QuickviewService,
    public currency: CurrencyService,
    private translate: TranslateService,
    public authService: AuthService) { }

    ngOnInit() {
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
        try{
          this.cd.detectChanges();
        }catch(err){

        }
      });

      this.currency.changes$.pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.cd.markForCheck();
      });

      // console.log("custom-product-card");
      // console.log(this.product);
    }

    detectChange(){
      if(!this.cd['destroyed']){
       this.cd.detectChanges();
      }
    }

    setLayout(value: Layout): void {
      this.layout = value;
  }

  limitProductList(){
    this.authService.limitProductList(this.limit);
  }

        ngOnDestroy(): void {
          this.destroy$.next();
          this.destroy$.complete();
          this.cd.detach();
      }

      addToCart(): void {
        if (this.addingToCart) {
            return;
        }

        this.addingToCart = true;
        this.cart.add(this.product, 1).subscribe({
            complete: () => {
                this.addingToCart = false;
                this.cd.markForCheck();
            }
        });
    }

    addToWishlist(): void {
      if (this.addingToWishlist) {
          return;
      }

      this.addingToWishlist = true;
      this.wishlist.add(this.product).subscribe({
          complete: () => {
              this.addingToWishlist = false;
              this.cd.markForCheck();
          }
      });
  }
  addToCompare(): void {
    if (this.addingToCompare) {
        return;
    }

    this.addingToCompare = true;
    this.compare.add(this.product).subscribe({
        complete: () => {
            this.addingToCompare = false;
            this.cd.markForCheck();
        }
    });
  }

  showQuickview(): void {
    if (this.showingQuickview) {
        return;
    }
    this.showingQuickview = true;
    this.product.custom = true;
    this.quickview.show(this.product).subscribe({
        complete: () => {
            this.showingQuickview = false;
            this.cd.markForCheck();
        }
    });
  }

  cartOrQuickview(){
    if(this.canAddToCart()){
      this.addToCart();
    }else{
      this.showQuickview();
    }
  }

  canAddToCart(){
    if(this.attributeList.length > 0){
      return false;
    }else{
      return true;
    }
  }

      goToMerchant(storeId){
        this.authService.router.navigate(['/classic/shop/merchant', storeId]);
      }

  trackByProduct(product){
    return product && product.id;
  }

}
