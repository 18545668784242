<div class="widget-products widget">
    <h4 class="widget__title">{{ header }}</h4>
    <div class="widget-products__list">
        <div *ngFor="let product of products|slice:0:5" class="widget-products__item">
            <div class="widget-products__image">
                <a [routerLink]="[product.routerLink]" *ngIf="product.productImageList && product.productImageList.length"><img [src]="product.productImageList[0].url" alt=""></a>
            </div>
            <div class="widget-products__info">
                <div class="widget-products__name">
                    <a [routerLink]="[product.routerLink]" *ngIf="product.productLocaleList && lang == 'en' && product?.productLocaleList[0]">{{ product?.productLocaleList[0]?.name }}</a>
                    <a [routerLink]="[product.routerLink]" *ngIf="product.productLocaleList && lang == 'zh' && product?.productLocaleList[1]">{{ product?.productLocaleList[1]?.name }}</a>

                </div>
                <!-- <div class="widget-products__prices">
                    <ng-container *ngIf="product.compareAtPrice">
                        <span class="widget-products__new-price">{{ product.salesPrice|currencyFormat }}</span>{{ ' ' }}
                        <span class="widget-products__old-price">{{ product.regularPrice|currencyFormat }}</span>
                    </ng-container>
                    <ng-container *ngIf="!product.compareAtPrice">
                        {{ product.salesPrice|currencyFormat }}
                    </ng-container>
                </div> -->
                <div class="widget-products__prices">
                    <ng-container *ngIf="product && product.regularPriceUsdt">
                        <!-- <span *ngIf="product.salesPriceUsdt" class="product-card__new-price">USDT {{ product.salesPriceUsdt }}</span>{{ ' ' }} -->
                        <span *ngIf="product.regularPriceUsdt" class="product-card__old-price font-size-smaller">USDT {{ product.regularPriceUsdt }}</span>
                    </ng-container>


                    <ng-container *ngIf="product && !product.regularPriceUsdt">
                      <span *ngIf="product.salesPriceUsdt">USDT {{ product.salesPriceUsdt }}</span>{{ ' ' }}
                    </ng-container>
                </div>

                <div class="widget-products__prices">
                    <ng-container *ngIf="product && product.regularPriceUsdt">
                        <span *ngIf="product.salesPrice" class="product-card__new-price font-size-smaller">USDT {{ product.salesPriceUsdt }} + {{ product.salesPrice }} {{'topbar.weniPoints' | translate}}</span>{{ ' ' }}
                        <span *ngIf="product.regularPrice" class="product-card__old-price font-size-smaller">USDT {{ product.regularPriceUsdt }} + {{ product.regularPrice }} {{'topbar.weniPoints' | translate}}</span>
                    </ng-container>


                    <ng-container *ngIf="product && !product.regularPriceUsdt">
                      <span *ngIf="product.salesPrice">{{ product.salesPrice }} {{'topbar.weniPoints' | translate}}</span>{{ ' ' }}
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
