import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { MobileMenuItem } from '../../../../shared/interfaces/mobile-menu-item';
import { AuthService } from '../../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-mobile-links',
    templateUrl: './mobile-links.component.html',
    styleUrls: ['./mobile-links.component.scss']
})
export class MobileLinksComponent implements OnInit{
    @Input() links: MobileMenuItem[] = [];
    @Input() level = 0;

    @Output() itemClick: EventEmitter<MobileMenuItem> = new EventEmitter();
    lang = "";

    constructor(private translate: TranslateService,
    public authService: AuthService) {
      // var divider = {
      //   type: "divider"
      // }
      // var engObj = {
      //   en: "English",
      //   zh: "English",
      //   type: "button"
      // };
      // var zhObj = {
      //   en: "中文",
      //   zh: "中文",
      //   type: "button"
      // };
      // this.links.push(divider);
      // this.links.push(engObj);
      // this.links.push(zhObj);
    }

    onItemClick(item: MobileMenuItem): void {
        this.itemClick.emit(item);
    }

    logoutClick(item: MobileMenuItem): void {
        this.authService.logout();
        this.itemClick.emit(item);
    }

    ngOnInit(){
      // console.log(this.links);
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });
    }

    changeLanguage(language) {
      this.lang = language;
      this.authService.setLocale(language);
      // if(this.lang == "en"){
      //   this.short = "EN";
      //   this.menuItems = this.menuItemsEn;
      // }else if(this.lang == "zh"){
      //   this.short = "中文";
      //   this.menuItems = this.menuItemsZh;
      // }
    }
}
