import { /*LOCALE_ID, */NgModule } from '@angular/core';
// import { registerLocaleData } from '@angular/common';
// import localeIt from '@angular/common/locales/it';
//
// registerLocaleData(localeIt, 'it');

// modules (angular)
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// modules (third-party)
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';

// modules
import { AppRoutingModule } from './app-routing.module';
import { BlocksModule } from './modules/blocks/blocks.module';
import { FooterModule } from './modules/footer/footer.module';
import { HeaderModule } from './modules/header/header.module';
import { MobileModule } from './modules/mobile/mobile.module';
import { SharedModule } from './shared/shared.module';
import { WidgetsModule } from './modules/widgets/widgets.module';



// components
import { AppComponent } from './app.component';
import { RootComponent } from './components/root/root.component';

// pages
import { PageHomeOneComponent } from './pages/page-home-one/page-home-one.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PageHomeTwoComponent } from './pages/page-home-two/page-home-two.component';

import { ScrollEventModule } from 'ngx-scroll-event';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { BrowserXhr, HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { Injector, APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';

import { AuthService } from './auth.service';
import { QRCodeModule } from 'angular2-qrcode';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { MatButtonModule, MatDialogModule } from '@angular/material';

// import { CookieService } from 'ngx-cookie-service';
import { CookieModule } from 'ngx-cookie';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CountdownModule } from 'ngx-countdown';
import { NgxSimpleCountdownModule } from 'ngx-simple-countdown';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



const languagePack = {
  en: {
    "common": {
      "title": "Weini Auction",
      "save": "Save",
      "cancel": "Cancel",
      "delete": "Delete",
      "update": "Update",
      "pay": "Pay",
      "all": "All",
      "currency": "Currency",
      "more": "More"
    },
    "topbar": {
      "aboutUs": "About Us",
      "contacts": "Contacts",
      "storeLocation": "Store Location",
      "trackOrder": "Track Order",
      "blog": "Blog",
      "myAccount": "My Account",
      "language": "Language",
      "dashboard": "Dashboard",
      "editProfile": "Edit Profile",
      "orderHistory": "Order History",
      "myBiddings": "My Biddings",
      "auctionHistory":"My Won Auctions",
      "addresses": "Addresses",
      "password": "Password",
      "logout": "Log Out",
      "navigation": "Navigation",
      "weniPoints": "WENI Points",
      "usdt": "USDT (ERC20)",
      "reload": "Reload",
      "address": "Address",
      "reloadHistory": "Reload History",
      "otr": "Ordinary Token Reward (OTR)",
      "lp" : "Learning Point (LP)",
      "usdtPoints": "USDP",
      "weni": "WENI",
      "withdrawal": "Withdrawal",
      "USDT": "USDT",
      "WENI_POINT": "WENI Point",
      "OTR": "OTR",
      "LP": "LP",
      "USDT_POINT": "USDP",
      "depositHistory": "Deposit History",
      "WENI": "WENI",
      "bidHistory": "Bid History"
    },
    "search": {
      "search": "Search over 10,000 products"
    },
    "header": {
      "customerService": "Customer Service"
    },
    "login": {
      "login": "Login",
      "email": "Email Address",
      "password": "Password",
      "forgot": "Forgot Password?",
      "rememberMe": "Remember Me",
      "enterEmail": "Enter Email",
      "register": "Register",
      "repeatPassword": "Repeat Password",
      "myAccount": "My Account",
      "send": "Send",
      "otp": "OTP",
      "resend": "Resend",
      "sec": "Sec",
      "name": "Name",
      "emailOtp": "Email OTP",
      "refresh": "Refresh",
      "loginWenEco": "Login With WEN ECO"
    },
    "home": {
      "featuredProducts": "Featured Products",
      "popularAuctionItem" : "Popular  Auction Item",
      "liveAuctionItem": "Live Auction",
        "endAuctionItem": "Remaining time",
      "bestsellers": "Bestsellers",
      "popularCategories": "Popular Categories",
      "newArrivals": "New Arrivals",
      "latestNews": "Latest News",
      "topRatedProducts": "Top Rated Products",
      "specialOffers": "Special Offers",
      "goToTop": "Go To Top",
      "superLowDeal": "Super Low Deal",
      "wenProduct": "WEN Product",
      "weiniSpecial": "WEINI MART",
      "pendingAuction": "Pending Auctions",
      "startAuction": "On Going Auctions",
      "endAuction": "Ended Auctions",
      "pendingUsdtHeader": "USDT Preview Session",
      "startUsdtHeader": "On Going USDT Products",
      "pendingUsdpHeader": "USDP Preview Session",
      "startUsdpHeader": "On Going USDP Products"
    },
    "footer": {
      "contactUs": "Contact Us",
      "infoTxt": "Information",
      "info": {
        "aboutUs": "About Us",
        "deliveryInfo": "Delivery Information",
        "privacyPolicy": "Privacy Policy",
        "brands": "Brands",
        "contactUs": "Contact Us",
        "returns": "Returns",
        "siteMap": "Site Map",
        "afterSalesService": "After Sales Service",
        "tnc": "Terms & Conditions"
      },
      "myAccountTxt": "My Account",
      "myAccount": {
        "storeLocation": "Store Location",
        "orderHistory": "Order History",
        "wishlist": "Wish List",
        "newsletter": "Newsletter",
        "specials": "Specials",
        "giftCards": "Gift Cards",
        "Affiliate": "Affiliate"
      },
      "newsletter": "Newsletter",
      "subscribe": "Subscribe",
      "contact": {
        "address": "Hunan, China",
        "openingHours": "Mon-Sat: 9am - 7pm"
      }
    },
    "productCard": {
      "auction": "Auction",
      "hot": "Hot",
      "new": "New",
      "sold":"Sold",
      "bidders":"Bid(s)",
      "auctionends":"Time Remaining",
      "auctionstarts": "Starts Soon",
      "startingprice": "Starting Price",
      "biddingprice": "Current bid",
      "availability": "Availability",
      "inStock": "In Stock",
      "bidNow": "Bid Now",
      "raiseBid": "Raise Bid",
      "brand": "Brand",
      "sku": "SKU",
      "color": "Color",
      "material": "Material",
      "quantity": "Quantity",
      "metal": "Metal",
      "wood": "Wood",
      "plastic": "Plastic",
      "white": "White",
      "yellow": "Yellow",
      "red": "Red",
      "blue": "Blue",
      "outOfStock": "Out Of Stock",
      "free": "FREE SHIPPING",
      "shippingFee": "Shipping Fee",
      "pcsInStock": "pcs in stock",
      "soldBy": "Sold By",
      "left": "remaining",
      "bidRaisePrice": "Bid Raise Price",
      "payDeposit": "Pay Deposit"
    },
    "shopSidebar": {
      "filters": "Filters",
      "latestProducts": "Latest Products",
      "filter": "Filter",
      "reset": "Reset"
    },
    "departments": {
      "shopByCategory": "Shop By Category"
    },
    "departmentsData": {
      "womensFashion": "Women's Fashion",
      "mensFashion": "Men's Fashion",
      "fashionAccessories": "Fashion Accessories",
      "shoesAndBags": "Shoes & Bags",
      "sportsAndOutdoors": "Sports & Outdoors",
      "homeAndLifestyle": "Home & Lifestyle",
      "electronic": "Electronic",
      "tvAndHomeAppliances": "TV & Home Appliances",
      "babiesAndToys": "Babies & Toys",
      "healthAndBeauty": "Health & Beauty",
      "weniSpecial": "WEINI MART",
      "home": "Home",
      "womensFashionSub": {
        "newArrival": "New Arrival",
        "tShirt": "T-Shirt",
        "shirt": "Shirt",
        "jacketCoat": "Jacket/Coat",
        "dress": "Dress",
        "casualPants": "Casual Pants"
      }
    },
     "category":{
      "bags":"Bags",
      "ladiesFashion":"Ladies Fashion",
      "mensFashion":"Mens Fashion",
      "accessories":"Accessories",
      "watches":"Watches",
      "shoes":"Shoes",
      "beautyCare":"Beauty Care",
      "healthCare":"Health Care"

    },
    "mobileMenu":{
      "home":"Home",
      "category":"Category",
      "cart":"Cart",
      "auction":"Auction" ,
      "account":"Account" ,
      "mall":"Mall",
      "myBidding":"My Bidding",
      "weiniMall": "WEINI PREMIUM"
    },
    "blockFeatures": {
      "freeShipping": "Free Shipping",
      "freeShippingDesc": "For orders from 50 Weni coins",
      "support": "Support 24/7",
      "supportDesc": "Call us anytime",
      "safety": "100% Safety",
      "safetyDesc": "Only secure payments",
      "hotOffers": "Hot Offers",
      "hotOffersDesc": "Discounts up to 90%"
    },
    "product": {
      "relatedProducts": "Related Products",
      "description": "Description",
      "topBidder":"Top Bidder",
      "specification": "Specification",
      "top5Bidder": "Top 5 Bidders",
      "status": "Status",
      "price": "Price",
      "bidder": "Bidder",
      "time": "Time",
      "lead": "Lead",
      "out": "Out",
      "lotDetail": "Lot Detail",
      "notesOfMargin": "Notes of Margin",
      "promiseOfSale": "Promise of Sale",
      "auctionLotParameter": "Auction Lot Parameter",
      "top5Bids": "Top 5 Bids",
      "belowReservePrice": "Below Reserve Price",
      "yourBid": "Your Current Bid is",
      "mayLike": "You May Also Like",
      "leadSuccess": "Lead"
    },
    "side": {
      "showing": "Showing",
      "products": "products",
      "sortBy": "Sort By",
      "show": "Show"
    },
    "cart": {
      "empty": "Your shopping cart is empty!",
      "subtotal": "Subtotal",
      "total": "Total",
      "viewCart": "View Cart",
      "checkout": "Checkout",
      "image": "Image",
      "product": "Product",
      "price": "Price",
      "deposit":"Deposit",
      "quantity": "Quantity",
      "continueShopping": "Continue Shopping",
      "updateCart": "Update Cart",
      "cartTotals": "Cart Totals",
      "proceed": "Proceed to Checkout",
      "continue": "Continue",
      "coupon": "Coupon Code",
      "applyCoupon": "Apply Coupon",
      "transactionSuccessful": "Your Transaction is Successful",
      "pointsEarn": "The total rewards you'll earn from this purchase is",
      "attribute": "Attribute",
      "status" : "Status"
    },
    "menu": {
      "title": "Menu"
    },
    "checkout": {
      "returningCustomer": "Existing customer?",
      "clickLogin": "Click here to login",
      "billingDetails": "Billing Details",
      "firstName": "First Name",
      "lastName": "Last Name",
      "companyName": "Company Name",
      "optional": "Optional",
      "country": "Country",
      "countryPlaceholder": "Select a country...",
      "streetAddress": "Street Address",
      "address": "Apartment, suite, unit etc.",
      "city": "City",
      "state": "State",
      "postcode": "Postcode",
      "email": "Email Address",
      "phone": "Phone",
      "createAccount": "Create an account?",
      "shippingDetails": "Shipping Details",
      "shipDifferentAddress": "Ship to a different address?",
      "orderNotes": "Order notes",
      "yourOrder": "Your Order",
      "product": "Product",
      "total": "Total",
      "subtotal": "Subtotal",
      "iHaveRead": "I have read and agree to the website",
      "tnc": "terms and conditions",
      "placeBid": "Place Bid"
    },
    "password": {
      "currentPassword": "Current Password",
      "newPassword": "New Password",
      "reenterNewPassword": "Reenter New Password",
      "change": "Change",
      "changePassword": "Change Password"
    },
    "address": {
      "addAddress": "New Address",
      "updateAddress": "Update Address",
      "deleteAddress": "Delete Address",
      "addressLine1": "Address",
      "addressLine2": "Address Line 2",
      "postalCode": "Postal Code",
      "state": "State",
      "country": "Country",
      "default": "Default",
      "manageAddress": "Manage Address",
      "selectOtherAddress": "Select Other Address"
    },
    "reload": {
      "reloadAccount": "Reload Account",
      "balance": "Balance",
      "reloadAmount": "Reload Amount",
      "confirm": "Confirm",
      "amount": "Amount",
      "youAreReloading": "You are reloading",
      "useScanFunction": "Please Scan your QR code to proceed.",
      "useScanFunction-2": "Please Click on the QR code to proceed",
      "other": "Other",
      "congratsReloaded": "Congratulations! You have successfully reloaded",
      "newWeniPoints": "Your new WENI points is",
      "newUsdt": "Your new USDT is",
      "happyShopping": "Happy shopping!",
      "balanceInsufficientWeni": "Sorry, you have insufficient balance in your WEN ECO App.",
      "balanceInsufficientUsdt": "Sorry, you have insufficient balance in your ERC20.",
      "checkBalance": "Please check your balance before reloading",
      "backToHome": "Back To Home",
      "reloadNo": "Reload No.",
      "COMPLETE": "Success",
      "CANCELLED": "Cancelled",
      "newUsdtPoints": "Your new USDP is",
      "newWeni": "Your new WENI is"
    },
    "message": {
      "invalidPasswordFormat": "Password requires a lowercase letter, an uppercase letter, a number and a minimum of 8 characters",
      "differentPassword": "Please confirm that your password is identical",
      "changePasswordSuccess": "Changed password successfully",
      "requestForgotPasswordEmailSuccess": "An email will be sent to you shortly",
      "deleteConfirmationQuery": "Are you sure you want to delete it?",
      "confirmReadTnc": "Please confirm that you've read the terms & conditions",
      "addressLine1NotFound": "Please enter address",
      "stateNotFound": "Please enter state",
      "postcodeNotFound": "Please enter postcode",
      "enterReloadAmount":"Please enter reload amount",
      "addedToCart": "added to cart!",
      "selectAddress": "Please select an address",
      "invalidPhone": "Please enter your phone number",
      "invalidOTP": "Please enter your OTP",
      "requestForgotPasswordSuccess": "A temporary password will be sent to you shortly",
      "nameNotFound": "Please enter your name",
      "updatedSuccessfully": "Updated successfully",
      "enterAmount": "Please enter amount",
      "selectToken": "Please select token",
      "enterWenEcoId": "Please enter WENECO UID",
      "withdrawRequested": "Successfully requested for withdrawal",
      "productOutOfStock": "Product out of stock",
      "request.info.price.required": "Amount is ",
      "request.info.price.required.change": "Amount has been updated to ",
      "doYouWantToPay": "Do you want to proceed with the payment?",
      "DEPOSIT": "Deposit",
      "BID": "Bid",
      "validAmountRequired": "Please enter a valid bid"
    },
    "order": {
      "orderNo": "Order No.",
      "date": "Date",
      "status": "Status",
      "WAIT": "Waiting for shipping",
      "SHIPPED": "Shipped",
      "REFUNDED": "Refunded",
      "auctionItem":"Auction Item",
      "auctionPrice":"Auction Price",
      "depositPaid": "Deposit Paid"
    },
    "withdraw": {
      "balance": "Balance",
      "withdrawAmount": "Withdraw Amount",
      "confirm": "Confirm",
      "amount": "Amount",
      "backToHome": "Back To Home",
      "withdrawNo": "Withdraw No.",
      "APPROVED": "Approved",
      "CANCELLED": "Cancelled",
      "token": "Token",
      "wenEcoId": "WENECO UID",
      "withdraw": "Withdraw",
      "withdrawHistory": "Withdrawal History",
      "PENDING": "Pending",
      "REJECTED": "Rejected",
      "action": "Action",
      "wenEcoPaymentId": "WENECO Payment ID",
      "rejectionReason": "Rejection Reason"
    },
    "merchant":{
      "allProducts": "All Products"
    },
    "errorCode": {
      "request.error.product.out.of.stock": "Product out of stock"
    },
    "auction": {
      "ACTIVE": "Pending",
      "START": "On Going",
      "END": "Ended",
      "CONFIRM": "Confirmed",
      "REJECT": "Rejected",
      "SHIPPED": "Shipped",
      "bidStatus" : "Bid Status",
      "confirm": "Confirm Purchase",
      "reject": "Reject"
    },
    "deposit": {
      "INACTIVE": "Refunded",
      "ACTIVE": "Paid"
    }
  },
  zh: {
    "common": {
      "title": "唯你拍卖",
      "save": "保存",
      "cancel": "取消",
      "delete": "删除",
      "update": "编辑",
      "pay": "付",
      "all": "所有",
      "currency": "货币",
      "more": "更多"
    },
    "topbar": {
      "aboutUs": "关于唯你拍卖",
      "contacts": "联系我们",
      "storeLocation": "商店位置",
      "trackOrder": "货物追踪",
      "blog": "部落格",
      "myAccount": "我的账号",
      "language": "语言",
      "dashboard": "仪表板",
      "editProfile": "编辑个人资料",
      "orderHistory": "订单历史",
      "myBiddings": "我的竞拍",
      "auctionHistory":"我的获拍",
      "addresses": "地址",
      "password": "密码",
      "logout": "登出",
      "navigation": "导航",
      "weniPoints": "WENI 点数",
      "usdt": "USDT (ERC20)",
      "reload": "充值",
      "address": "地址",
      "reloadHistory": "充值历史",
      "otr": "原始证 (OTR)",
      "lp" : "金典动源 (LP)",
      "usdtPoints": "USDP",
      "weni": "WENI",
      "withdrawal": "提款",
      "USDT": "USDT",
      "WENI_POINT": "WENI 点数",
      "OTR": "OTR",
      "LP": "LP",
      "USDT_POINT": "USDP",
      "depositHistory": "保证金历史",
      "WENI": "WENI",
      "bidHistory": "竞标历史"
    },
    "search": {
      "search": "搜索产品"
    },
    "header": {
      "customerService": "客户服务"
    },
    "login": {
      "login": "登入",
      "email": "电子邮箱",
      "password": "密码",
      "forgot": "忘记密码?",
      "rememberMe": "记住账号",
      "enterEmail": "输入电子邮件",
      "register": "注册",
      "repeatPassword": "重复输入密码",
      "myAccount": "我的账号",
      "send": "发送",
      "otp": "OTP",
      "resend": "重发",
      "sec": "秒",
      "name": "名字",
      "emailOtp": "电子邮箱OTP",
      "refresh": "刷新",
      "loginWenEco": "使用 WEN ECO 登录"
    },
    "home": {
      "featuredProducts": "精选单品",
      "popularAuctionItem" : "热门拍卖品",
      "liveAuctionItem": "现场拍卖",
      "endAuctionItem": "拍卖结束",
      "bestsellers": "畅销单品",
      "popularCategories": "热门产品",
      "newArrivals": "新品到货",
      "latestNews": "最新消息",
      "topRatedProducts": "最高评价",
      "specialOffers": "特别优惠",
      "goToTop": "回到顶部",
      "superLowDeal": "超低折扣",
      "wenProduct": "生态定制",
      "weiniSpecial": "唯你专区",
      "pendingAuction": "待拍卖",
      "startAuction": "拍卖进行中",
      "endAuction": "拍卖结束",
      "pendingUsdtHeader": "USDT预告专场",
      "startUsdtHeader": "USDT产品拍卖进行中",
      "pendingUsdpHeader": "USDP预告专场",
      "startUsdpHeader": "USDP产品拍卖进行中"
    },
    "footer": {
      "contactUs": "联系我们",
      "infoTxt": "信息",
      "info": {
        "aboutUs": "关于唯你拍卖",
        "deliveryInfo": "物流资讯",
        "privacyPolicy": "唯你拍卖隐私权政策",
        "brands": "品牌",
        "contactUs": "联系我们",
        "returns": "退货规则",
        "siteMap": "网站地图",
        "afterSalesService": "售后问题",
        "tnc": "唯你拍卖隐私权政策"
      },
      "myAccountTxt": "我的账号",
      "myAccount": {
        "storeLocation": "商店位置",
        "orderHistory": "订单历史",
        "wishlist": "收藏夹",
        "newsletter": "时事通讯",
        "specials": "特别",
        "giftCards": "预付礼卡",
        "Affiliate": "加入会员"
      },
      "newsletter": "时事通讯",
      "subscribe": "订阅",
      "contact": {
        "address": "湖南，中国",
        "openingHours": "周一至周六 9am - 7pm"
      }
    },
    "productCard": {
      "auction": "拍卖",
      "hot": "热",
      "new": "新",
      "sold":"已卖出",
      "bidders":"竞拍人数",
      "auctionends":"距离结束",
      "auctionstarts": "即将开始",
      "startingprice": "起拍价",
      "biddingprice": "当前价",
      "availability": "库存",
      "inStock": "有货",
      "raiseBid": "提高出价",
      "bidNow": "现在出价",
      "brand": "品牌",
      "sku": "SKU",
      "color": "颜色",
      "material": "材料",
      "quantity": "数量",
      "metal": "金属",
      "wood": "木",
      "plastic": "塑料",
      "white": "白",
      "yellow": "黄",
      "red": "红",
      "blue": "蓝",
      "outOfStock": "缺货",
      "free": "免运费",
      "shippingFee": "运输费",
      "pcsInStock": "pcs in stock",
      "soldBy": "卖家",
      "left": "剩下",
      "bidRaisePrice": "加价幅度",
      "payDeposit": "支付保证金"
    },
    "shopSidebar": {
      "filters": "筛选",
      "latestProducts": "最新产品",
      "filter": "过滤",
      "reset": "重置"
    },
    "departments": {
      "shopByCategory": "按类别购物"
    },
    "departmentsData": {
      "womensFashion": "女装精品",
      "mensFashion": "男装",
      "fashionAccessories": "饰品/配件",
      "shoesAndBags": "鞋品/箱包",
      "sportsAndOutdoors": "运动/户外",
      "homeAndLifestyle": "居家/家纺",
      "electronic": "手机/数码",
      "tvAndHomeAppliances": "家用电器",
      "babiesAndToys": "母婴/玩具",
      "healthAndBeauty": "美妆/医药保健",
      "weniSpecial": "唯你专区",
      "home": "首页",
      "womensFashionSub": {
        "newArrival": "女装新品",
        "tShirt": "T恤",
        "shirt": "衬衫",
        "jacketCoat": "时髦外套",
        "dress": "连衣裙",
        "casualPants": "休闲裤"
      }
    },
    "blockFeatures": {
      "freeShipping": "免运费",
      "freeShippingDesc": "最低消费50枚WEN",
      "support": "支持24/7",
      "supportDesc": "随时致电我们",
      "safety": "100％安全",
      "safetyDesc": "仅安全付款",
      "hotOffers": "热门优惠",
      "hotOffersDesc": "折扣高达90％"
    },
    "product": {
      "relatedProducts": "相关产品",
      "description": "描述",
      "topBidder":"最高出价者",
      "specification": "规格",
      "top5Bidder": "前5名竞拍人数",
      "status": "状态",
      "price": "价钱",
      "bidder": "竞拍者",
      "time": "时间",
      "lead": "暂时领先",
      "out": "竞标落败",
      "lotDetail": "拍卖品详细信息",
      "notesOfMargin": "保证金票据",
      "promiseOfSale": "销售承诺",
      "auctionLotParameter": "拍卖批次参数",
      "top5Bids": "前5个出价",
      "belowReservePrice": "低于底价",
      "yourBid": "你的投标价",
      "mayLike": "您可能也喜欢这些拍卖",
      "leadSuccess": "竞标成功"
    },
    "side": {
      "showing": "显示中",
      "products": "产品展示",
      "sortBy": "排序方式",
      "show": "显示"
    },
    "cart": {
      "empty": "你的购物车是空的!",
      "subtotal": "小计",
      "total": "总数",
      "viewCart": "查看购物车",
      "checkout": "结算",
      "image": "图片",
      "product": "产品",
      "price": "价钱",
      "deposit":"保证金",
      "quantity": "数量",
      "continueShopping": "继续购物",
      "updateCart": "更新购物车",
      "cartTotals": "购物车总数",
      "proceed": "进行结算",
      "continue": "继续",
      "coupon": "优惠劵代码",
      "applyCoupon": "申请优惠券",
      "transactionSuccessful": "您的交易成功",
      "pointsEarn": "您从此次购买中获得的酬劳是",
      "attribute": "产品属性",
      "status": "状态"
    },
    "menu": {
      "title": "菜单"
    },

    "category":{
      "bags":"包袋",
      "ladiesFashion":"女士时装",
      "mensFashion":"男士时尚",
      "accessories":" 配件",
      "watches":"手表",
      "shoes":"鞋子",
      "beautyCare":"美容保养",
      "healthCare":"卫生保健 "
    },
    "mobileMenu":{
      "home":"首页",
      "category":"类别",
      "cart":"购物车",
      "auction":"拍卖" ,
      "account":"帐户" ,
      "mall":"唯你优品 ",
      "myBidding":"我的出价",
      "weiniMall": "唯你优品"
    },
    "checkout": {
      "returningCustomer": "已是会员?",
      "clickLogin": "按此登入",
      "billingDetails": "账单细节",
      "firstName": "姓氏",
      "lastName": "名称",
      "companyName": "企业名称",
      "optional": "可选",
      "country": "国家",
      "countryPlaceholder": "选择国家地区...",
      "streetAddress": "街道",
      "address": "公寓/大厦/单位",
      "city": "城市",
      "state": "省/区",
      "postcode": "邮政编码邮政编码",
      "email": "邮箱地址",
      "phone": "手机号码",
      "createAccount": "注册账户?",
      "shippingDetails": "收货地址",
      "shipDifferentAddress": "寄送至新地址?",
      "orderNotes": "订购说明",
      "yourOrder": "您的订单",
      "product": "商品",
      "total": "合计",
      "subtotal": "小计",
      "iHaveRead": "我已阅读并同意网站相关",
      "tnc": "条款和条例",
      "placeOrder": "地方出价"
    },
    "password": {
      "currentPassword": "当前密码",
      "newPassword": "最新密码",
      "reenterNewPassword": "再次输入密码",
      "change": "更换",
      "changePassword": "更换密码"
    },
    "address": {
      "addAddress": "新地址",
      "updateAddress": "更新地址",
      "deleteAddress": "删除地址",
      "addressLine1": "地址",
      "addressLine2": "地址第二行",
      "postalCode": "邮编",
      "state": "省/区",
      "country": "国家",
      "default": "默认",
      "manageAddress": "管理地址",
      "selectOtherAddress": "选择其他地址"
    },
    "reload": {
      "reloadAccount": "充值账号",
      "balance": "余额",
      "reloadAmount": "充值金额",
      "confirm": "确认",
      "amount": "金额",
      "youAreReloading": "您已选择充值",
      "useScanFunction": "请登入WEN ECO App使用“扫描” 功能.",
      "useScanFunction-2": "请点击二维码充值",
      "other": "其他",
      "congratsReloaded": "恭喜您！您已成功充值",
      "newWeniPoints": "您的最新余额是",
      "newUsdt": "您的最新余额是",
      "happyShopping": "购物愉快!",
      "balanceInsufficientWeni": "抱歉，您的WEN ECO 余额不足。",
      "balanceInsufficientUsdt": "抱歉，您的ERC20 余额不足。",
      "checkBalance": "请在充值前确保您有足够的余额",
      "backToHome": "返回主页",
      "reloadNo": "充值号",
      "COMPLETE": "成功",
      "CANCELLED": "取消",
      "newUsdtPoints": "您的最新余额是",
      "newWeni": "您的最新余额是"
    },
    "message": {
      "invalidPasswordFormat": "注册密码必须以英数字混和，最低8码",
      "differentPassword": "请输入相同新密码",
      "changePasswordSuccess": "密码更换成功",
      "requestForgotPasswordEmailSuccess": "我们会尽快发送电子邮箱给您",
      "deleteConfirmationQuery": "你确定要删除吗",
      "confirmReadTnc": "请确认您已阅读条款和条例",
      "addressLine1NotFound": "请输入地址",
      "stateNotFound": "请输入省/区",
      "postcodeNotFound": "请输入邮编",
      "enterReloadAmount":"请输入充值金额",
      "addedToCart": "已加入购物车!",
      "selectAddress": "请选择一个地址",
      "invalidPhone": "请输入手机号",
      "invalidOTP": "请输入OTP",
      "requestForgotPasswordSuccess": "密码即将发送给您",
      "nameNotFound": "请输入你的名字",
      "updatedSuccessfully": "更新成功",
      "enterAmount": "请输入金额",
      "selectToken": "请选择代币",
      "enterWenEcoId": "请输入 WENECO UID",
      "withdrawRequested": "成功要求提款",
      "productOutOfStock": "产品缺货",
      "request.info.price.required": "金额是 ",
      "request.info.price.required.change": "金额已更新为 ",
      "doYouWantToPay": "您要继续付款吗",
      "DEPOSIT": "保证金",
      "BID": "下标",
      "validAmountRequired": "请输入有效的出价"
    },
    "order": {
      "orderNo": "订单号",
      "date": "日期",
      "status": "动态",
      "WAIT": "待发货",
      "SHIPPED": "已发货",
      "REFUNDED": "退款成功",
      "auctionItem": "拍卖品",
      "auctionPrice": "拍卖价",
      "depositPaid": "保证金已付"
    },
    "withdraw": {
      "balance": "余额",
      "withdrawAmount": "提款金额",
      "confirm": "确认",
      "amount": "金额",
      "backToHome": "返回主页",
      "withdrawNo": "提款号",
      "APPROVED": "已批准",
      "CANCELLED": "取消",
      "token": "代币",
      "wenEcoId": "WENECO UID",
      "withdraw": "提款",
      "withdrawHistory": "提款历史",
      "PENDING": "待定",
      "REJECTED": "拒绝",
      "action": "操作",
      "wenEcoPaymentId": "WENECO 交易ID",
      "rejectionReason": "拒绝理由"
    },
    "merchant":{
      "allProducts": "所有产品"
    },
    "errorCode": {
      "request.error.product.out.of.stock": "产品缺货"
    },
    "auction": {
      "ACTIVE": "待定",
      "START": "拍卖中",
      "END": "已结束",
      "CONFIRM": "已确认",
      "REJECT": "拒绝",
      "SHIPPED": "已发货",
      "bidStatus" : "下标状态",
      "confirm": "确认购买",
      "reject": "拒绝"
    },
    "deposit": {
      "INACTIVE": "已退款",
      "ACTIVE": "已付费"
    }
  }
};














// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
  // return new TranslateHttpLoader(http, './assets/i18n/', '.json');

   // return new TranslateHttpLoader(
   //   http,
   //    // '../dist/assets/i18n/', // or whatever path you're using
   //    '../assets/i18n/', // or whatever path you're using
   //   '.json'
   //  );
}

export function createTranslateLoader(http: HttpClient) {
    // return new TranslateHttpLoader(http, './assets/i18n/', '.json');
   // return new TranslateHttpLoader(http, '../dist/assets/i18n/', '.json');
   return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

// export function appInitializerFactory(translate: TranslateService, injector: Injector) {
//   return () => new Promise<any>((resolve: any) => {
//     const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
//     locationInitialized.then(() => {
//       translate.setDefaultLang('en');
//       translate.getTranslation("zh").subscribe(res => {
//         // authService.zh = res;
//         languagePack.zh = res;
//         translate.getTranslation("en").subscribe(res1 => {
//           // authService.en = res;
//           languagePack.en = res1;
//           console.log("appModule languagePack : ");
//           console.log(languagePack);
//           resolve(null);
//         });
//       });
//     });
//   });
// }

@NgModule({
    declarations: [
        // components
        AppComponent,
        RootComponent,
        // pages
        PageHomeOneComponent,
        PageNotFoundComponent,
        PageHomeTwoComponent
    ],
    imports: [
        // modules (angular)
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        CountdownModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,

        // modules (third-party)
        CarouselModule,
        ToastrModule.forRoot(),
        // modules
        AppRoutingModule,
        BlocksModule,
        FooterModule,
        HeaderModule,
        MobileModule,
        SharedModule,
        WidgetsModule,
        ScrollEventModule,
        FormsModule,
        HttpClientModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
          }
        }),
        QRCodeModule,
        MatButtonModule,
        MatDialogModule,
        CookieModule.forRoot(),
        LazyLoadImageModule,
        InfiniteScrollModule,
        CountdownModule,
        NgxSimpleCountdownModule,
        FontAwesomeModule,

    ],
    providers: [
        // { provide: LOCALE_ID, useValue: 'it' }
      // {
      //   provide: APP_INITIALIZER,
      //   useFactory: appInitializerFactory,
      //   deps: [TranslateService, Injector],
      //   multi: true
      // },
      AuthService,
      { provide: 'languagePack', useValue: languagePack},
      {provide: LocationStrategy, useClass: PathLocationStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
