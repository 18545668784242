import { AfterViewInit, Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CartService } from './shared/services/cart.service';
import { CompareService } from './shared/services/compare.service';
import { WishlistService } from './shared/services/wishlist.service';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { CurrencyService } from './shared/services/currency.service';
import {TranslateService} from '@ngx-translate/core';
import { AuthService } from './auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [AuthService]
})
export class AppComponent implements AfterViewInit, OnInit {
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private router: Router,
        private toastr: ToastrService,
        private cart: CartService,
        private compare: CompareService,
        private wishlist: WishlistService,
        private zone: NgZone,
        private scroller: ViewportScroller,
        private currency: CurrencyService,
        private translate: TranslateService, public authService: AuthService
    ) {
        // this language will be used as a fallback when a translation isn't found in the current language
        // translate.setDefaultLang('en');

         // the lang to use, if the lang isn't available, it will use the current loader to get them
         // var lang = this.authService.getLocale();
         // if(lang == "en" || lang == "zh"){
         //   translate.use(lang);
         // }
         // translate.use('en');
    }

    ngOnInit(): void {
        // properties of the CurrencyFormatOptions interface fully complies
        // with the arguments of the built-in pipe "currency"
        // https://angular.io/api/common/CurrencyPipe

        //generate uuid for session if not found
        var session = this.authService.getSession();
        if(session == null){
          // console.log("session null, generating new session uuid");
          this.authService.newSession();
        }else{
          // console.log("there's session (" + session + ")");
        }

        // this.authService.getCart(true);

        this.currency.options = {
            code: 'WENI ',
            // display: 'symbol',
            // digitsInfo: '1.2-2',
            // locale: 'en-US'
        };

        this.router.events.subscribe((event) => {
            if ((event instanceof NavigationEnd)) {
                this.scroller.scrollToPosition([0, 0]);
            }
        });
        // this.cart.onAdding$.subscribe(product => {
        //     var productName = product.name;
        //     var text = "";
        //     if(this.authService.locale == "en"){
        //       text = this.authService.en.message.addedToCart;
        //       productName = product.productLocaleList[0].name;
        //     }else if(this.authService.locale == "zh"){
        //       text = this.authService.zh.message.addedToCart;
        //       productName = product.productLocaleList[1].name;
        //     }
        //     this.toastr.success(`"${productName}" ${text}`);
        // });
        // this.cart.addFail$.subscribe(errorCode => {
        //     var text = "";
        //     if(this.authService.locale == "en"){
        //       text = this.authService.en.errorCode[errorCode];
        //     }else if(this.authService.locale == "zh"){
        //       text = this.authService.zh.errorCode[errorCode];
        //     }
        //     this.toastr.warning(`${text}`);
        // });
        // this.compare.onAdding$.subscribe(product => {
        //     this.toastr.success(`Product "${product.name}" added to compare!`);
        // });
        // this.wishlist.onAdding$.subscribe(product => {
        //     this.toastr.success(`Product "${product.name}" added to wish list!`);
        // });
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            // this.zone.runOutsideAngular(() => {
            //     setTimeout(() => {
            //         const preloader = document.querySelector('.site-preloader');
            //
            //         preloader.addEventListener('transitionend', (event: TransitionEvent) => {
            //             if (event.propertyName === 'opacity') {
            //                 preloader.remove();
            //             }
            //         });
            //         preloader.classList.add('site-preloader__fade');
            //     }, 300);
            // });
        }
    }

}
