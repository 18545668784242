import { Component, Inject, Input, PLATFORM_ID, OnInit, EventEmitter, Output } from '@angular/core';
import { ProductFilter } from '../../../shared/interfaces/product-filter';
import { isPlatformBrowser } from '@angular/common';
import { DirectionService } from '../../../shared/services/direction.service';
import { AuthService } from '../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ShopSidebarService } from '../../shop/services/shop-sidebar.service';


@Component({
    selector: 'app-widget-filters',
    templateUrl: './widget-filters.component.html',
    styleUrls: ['./widget-filters.component.scss']
})
export class WidgetFiltersComponent implements OnInit{
    @Input() filters: ProductFilter[] = [];
    @Input() offcanvas: 'always'|'mobile' = 'mobile';

    isPlatformBrowser = isPlatformBrowser(this.platformId);
    rightToLeft = false;
    lang = "";

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private direction: DirectionService,
        private translate: TranslateService, public authService: AuthService,
        public sidebar: ShopSidebarService
    ) {
        this.rightToLeft = this.direction.isRTL();
    }

    ngOnInit(){
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });
    }

    filter(){
      this.sidebar.close();
      this.authService.filterProductList(this.filters);
    }

    resetFilter(){
      this.filters.forEach(filter => {
        try{
          if(filter.type == 'checkbox'){
            filter.options.items.forEach(item => {
              if(item.checked){
                item.checked = false;
              }
            });
          }
          else if(filter.type == 'pricewenipoint' || filter.type == 'priceusdt' || filter.type == 'priceotr' || filter.type == 'pricelp' || filter.type == 'priceusdp'){
            filter.options.from = filter.options.min;
            filter.options.to = filter.options.max;
          }
        }catch(err){

        }
      })
    }
}
