import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { CustomQuickviewService } from '../../services/custom-quickview.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Product } from '../../interfaces/product';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-custom-quickview',
    templateUrl: './custom-quickview.component.html',
    styleUrls: ['./custom-quickview.component.scss']
})
export class CustomQuickviewComponent implements OnInit, AfterViewInit, OnDestroy {
    private destroy$: Subject<void> = new Subject();

    @ViewChild('modal', { read: TemplateRef }) template: TemplateRef<any>;

    modalRef: BsModalRef;
    product: Product;

    constructor(
        private quickview: CustomQuickviewService,
        private modalService: BsModalService
    ) {

    }

    ngOnInit(){
      // console.log("ngOnInit()");
    }

    ngAfterViewInit(): void {
        // console.log("ngAfterViewInit()");
        // this.quickview.show$.pipe(takeUntil(this.destroy$)).subscribe(product => {
        //     console.log("inside");
        //     if (this.modalRef) {
        //         this.modalRef.hide();
        //     }
        //
        //     this.product = product;
        //     this.modalRef = this.modalService.show(this.template, {class: 'modal-dialog-centered modal-xl'});
        // });
        //
        // this.quickview.hide$.pipe(takeUntil(this.destroy$)).subscribe(product => {
        //     console.log("hiding quickview");
        //     if (this.modalRef) {
        //         this.modalRef.hide();
        //     }
        // });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
