<div detect-scroll (onScroll)="handleScroll($event)" [bottomOffset]="600"  [topOffset]="" >
<div #top>
  <app-block-slideshow [withDepartments]="true" [assetList]="slideList"></app-block-slideshow>
</div>

<div class="container">
  <nav class="pb-4">
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <!-- <a *ngIf="(startUsdtList && startUsdtList.length > 0) || (startUsdpList && startUsdpList.length > 0) || (pendingUsdtList && pendingUsdtList.length > 0) || (pendingUsdpList && pendingUsdpList.length > 0)" class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#auction-1" role="tab" aria-controls="nav-home" aria-selected="true">{{'auction.NORMAL' | translate}}</a> -->
      <a *ngIf="(waitStartUsdtList && waitStartUsdtList.length > 0) || (waitStartUsdpList && waitStartUsdpList.length > 0) || (waitPendingUsdtList && waitPendingUsdtList.length > 0) || (waitPendingUsdpList && waitPendingUsdpList.length > 0)" class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#auction-2" role="tab" aria-controls="nav-profile" aria-selected="false">{{'auction.WAIT' | translate}}</a>
      <a *ngIf="(foreverStartUsdtList && foreverStartUsdtList.length > 0) || (foreverStartUsdpList && foreverStartUsdpList.length > 0) || (foreverPendingUsdtList && foreverPendingUsdtList.length > 0) || (foreverPendingUsdpList && foreverPendingUsdpList.length > 0)" class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#auction-3" role="tab" aria-controls="nav-contact" aria-selected="false">{{'auction.FOREVER' | translate}}</a>
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <div [ngClass]="{'show': normalTab, 'active': normalTab}" class="tab-pane fade" id="auction-1" role="tabpanel" aria-labelledby="nav-home-tab">
      <div *ngIf="startUsdtList && startUsdtList.length > 0">
        <app-custom-block-products-large-first  [header]="startUsdtHeader" [products]="startUsdtList"  bannerType="USDT" moreParam="status-START--currency-USDT"></app-custom-block-products-large-first >
        <!-- <app-custom-block-products [header]="startUsdtHeader" bannerType="USDT" [products]="startUsdtList" layout="large-first" moreParam="status-START--currency-USDT--type-NORMAL" [banner]="usdtBanner"></app-custom-block-products> -->
      </div>

      <div *ngIf="startUsdpList && startUsdpList.length > 0">
        <!-- <app-custom-block-products-large-first  [header]="startUsdpHeader" [products]="startUsdpList"  bannerType="USDP" moreParam="status-START--currency-USDT_POINT"></app-custom-block-products-large-first > -->
        <app-custom-block-products [header]="startUsdpHeader" bannerType="USDP" [products]="startUsdpList" layout="large-last" moreParam="status-START--currency-USDT_POINT--type-NORMAL" [banner]="usdpBanner"></app-custom-block-products>
      </div>

      <div *ngIf="pendingUsdtList && pendingUsdtList.length > 0">
        <app-block-product-custom-verticle [header]="pendingUsdtHeader" [products]="pendingUsdtList" layout="grid-5" moreParam="status-ACTIVE--currency-USDT--type-NORMAL"></app-block-product-custom-verticle>
      </div>

      <div *ngIf="pendingUsdpList && pendingUsdpList.length > 0">
        <app-block-product-custom-verticle [header]="pendingUsdpHeader" [products]="pendingUsdpList" moreParam="status-ACTIVE--currency-USDT_POINT--type-NORMAL"></app-block-product-custom-verticle>
      </div>

      <!-- <div *ngIf="randomStartList && randomStartList.length > 0">
        <div
            class="search-results"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            (scrolled)="getRandomList('START')"
          >
          <app-block-product-custom-verticle [header]="mayLikeHeader"  [products]="randomStartList" ></app-block-product-custom-verticle>
        </div>
      </div> -->
    </div>
    <div [ngClass]="{'show': waitTab, 'active': waitTab}" class="tab-pane fade" id="auction-2" role="tabpanel" aria-labelledby="nav-profile-tab">
        <div *ngIf="waitStartUsdtList && waitStartUsdtList.length > 0">
          <!-- <app-custom-block-products-large-first  [header]="startUsdtHeader" [products]="startUsdtList"  bannerType="USDT" moreParam="status-START--currency-USDT"></app-custom-block-products-large-first > -->
          <app-custom-block-products [header]="startUsdtHeader" bannerType="USDT" [products]="waitStartUsdtList" layout="large-last" moreParam="status-START--currency-USDT--type-WAIT" [banner]="usdtBanner"></app-custom-block-products>
        </div>

        <div *ngIf="waitStartUsdpList && waitStartUsdpList.length > 0">
          <!-- <app-custom-block-products-large-first  [header]="startUsdpHeader" [products]="startUsdpList"  bannerType="USDP" moreParam="status-START--currency-USDT_POINT"></app-custom-block-products-large-first > -->
          <app-custom-block-products [header]="startUsdpHeader" bannerType="USDP" [products]="waitStartUsdpList" layout="large-first" moreParam="status-START--currency-USDT_POINT--type-WAIT" [banner]="usdpBanner"></app-custom-block-products>
        </div>

        <div *ngIf="waitPendingUsdtList && waitPendingUsdtList.length > 0">
          <app-block-product-custom-verticle [header]="pendingUsdtHeader" [products]="waitPendingUsdtList" layout="grid-5" moreParam="status-ACTIVE--currency-USDT--type-WAIT"></app-block-product-custom-verticle>
        </div>

        <div *ngIf="waitPendingUsdpList && waitPendingUsdpList.length > 0">
          <app-block-product-custom-verticle [header]="pendingUsdpHeader" [products]="waitPendingUsdpList" moreParam="status-ACTIVE--currency-USDT_POINT--type-WAIT"></app-block-product-custom-verticle>
        </div>

        <!-- <div *ngIf="randomStartList && randomStartList.length > 0">
          <div
              class="search-results"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              (scrolled)="getRandomList('START')"
            >
            <app-block-product-custom-verticle [header]="mayLikeHeader"  [products]="randomStartList" ></app-block-product-custom-verticle>
          </div>
        </div> -->
      </div>
    <div [ngClass]="{'show': foreverTab, 'active': foreverTab}" class="tab-pane fade" id="auction-3" role="tabpanel" aria-labelledby="nav-contact-tab">
        <div *ngIf="foreverStartUsdtList && foreverStartUsdtList.length > 0">
          <!-- <app-custom-block-products-large-first  [header]="startUsdtHeader" [products]="startUsdtList"  bannerType="USDT" moreParam="status-START--currency-USDT"></app-custom-block-products-large-first > -->
          <app-custom-block-products [header]="startUsdtHeader" bannerType="USDT" [products]="foreverStartUsdtList" layout="large-first" moreParam="status-START--currency-USDT--type-FOREVER" [banner]="usdtBanner"></app-custom-block-products>
        </div>

        <div *ngIf="foreverStartUsdpList && foreverStartUsdpList.length > 0">
          <!-- <app-custom-block-products-large-first  [header]="startUsdpHeader" [products]="startUsdpList"  bannerType="USDP" moreParam="status-START--currency-USDT_POINT"></app-custom-block-products-large-first > -->
          <app-custom-block-products [header]="startUsdpHeader" bannerType="USDP" [products]="foreverStartUsdpList" layout="large-last" moreParam="status-START--currency-USDT_POINT--type-FOREVER" [banner]="usdpBanner"></app-custom-block-products>
        </div>

        <div *ngIf="foreverPendingUsdtList && foreverPendingUsdtList.length > 0">
          <app-block-product-custom-verticle [header]="pendingUsdtHeader" [products]="foreverPendingUsdtList" layout="grid-5" moreParam="status-ACTIVE--currency-USDT--type-FOREVER"></app-block-product-custom-verticle>
        </div>

        <div *ngIf="foreverPendingUsdpList && foreverPendingUsdpList.length > 0">
          <app-block-product-custom-verticle [header]="pendingUsdpHeader" [products]="foreverPendingUsdpList" moreParam="status-ACTIVE--currency-USDT_POINT--type-FOREVER"></app-block-product-custom-verticle>
        </div>

        <!-- <div *ngIf="randomStartList && randomStartList.length > 0">
          <div
              class="search-results"
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="50"
              (scrolled)="getRandomList('START')"
            >
            <app-block-product-custom-verticle [header]="mayLikeHeader"  [products]="randomStartList" ></app-block-product-custom-verticle>
          </div>
        </div> -->

    </div>
  </div>

</div>




<!-- <div *ngIf="randomActiveList && randomActiveList.length > 0">
  <div
      class="search-results"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="getRandomList('ACTIVE')"
    >
    <app-block-product-custom-verticle [header]="pendingHeader"  [products]="randomActiveList" ></app-block-product-custom-verticle>
  </div>
</div> -->



<!-- <div>
  <div *ngIf="pendingList && pendingList.length > 0 && pendingList.length < 6">
    <app-custom-block-products-carousel [header]="pendingHeader" layout="horizontal"
                              [loading]="loading"
                              [products]="pendingList"
                              [groups]="featuredProducts.groups"
                              (groupChange)="featuredProducts.groupChange($event)">
    </app-custom-block-products-carousel>
  </div>

  <div *ngIf="pendingList && pendingList.length >= 6" class="container coloured-section">
    <app-custom-block-products-carousel [header]="pendingHeader" layout="grid-4"
                                [loading]="loading"
                                [products]="pendingList"
                                [groups]="featuredProducts.groups"
                                (groupChange)="featuredProducts.groupChange($event)">
     </app-custom-block-products-carousel>
 </div>
</div> -->

<!-- <div>
  <div *ngIf="startList && startList.length > 0 && startList.length < 6">
    <app-custom-block-products-carousel [header]="startHeader" layout="horizontal"
                              [loading]="loading"
                              [products]="startList"
                              [groups]="featuredProducts.groups"
                              (groupChange)="featuredProducts.groupChange($event)">
    </app-custom-block-products-carousel>
  </div>

  <div *ngIf="startList && startList.length >= 6" class="container coloured-section">
    <app-custom-block-products-carousel [header]="startHeader" layout="horizontal"
                                [loading]="loading"
                                [products]="startList"
                                [groups]="featuredProducts.groups"
                                (groupChange)="featuredProducts.groupChange($event)">
     </app-custom-block-products-carousel>
 </div>
</div> -->

<!-- <div>
  <div *ngIf="endList && endList.length > 0 && endList.length < 6">
    <app-custom-block-products-carousel [header]="endHeader" layout="horizontal"
                              [loading]="loading"
                              [products]="endList"
                              [groups]="featuredProducts.groups"
                              (groupChange)="featuredProducts.groupChange($event)">
    </app-custom-block-products-carousel>
  </div>

  <div *ngIf="endList && endList.length >= 6" class="container coloured-section">
    <app-custom-block-products-carousel [header]="endHeader" layout="horizontal"
                                [loading]="loading"
                                [products]="endList"
                                [groups]="featuredProducts.groups"
                                (groupChange)="featuredProducts.groupChange($event)">
     </app-custom-block-products-carousel>
 </div>
</div> -->


<!-- <div *ngIf="pendingList && pendingList.length > 0 && pendingList.length < 6">
        <app-custom-block-products-carousel
            [header]="liveAuctionItemHeader"
            layout="horizontal"
            [loading]="loading"
            [products]="list2"
            [groups]="featuredProducts.groups"
            (groupChange)="featuredProducts.groupChange($event)">
      </app-custom-block-products-carousel>
</div>

<div *ngIf="weiniSpecialList && weiniSpecialList.length > 0 && weiniSpecialList.length < 6">
  <app-custom-block-products-carousel
      [header]="endAuctionItemHeader"
      layout="horizontal"
      [loading]="loading"
      [products]="list2"
      [groups]="featuredProducts.groups"
      (groupChange)="featuredProducts.groupChange($event)">
</app-custom-block-products-carousel>
</div> -->




    <!-- <app-block-products header="Bestsellers" layout="large-first" [products]="products"></app-block-products>

    <app-custom-block-products  [header]="bestsellersHeader" layout="large-first" [products]="list2"></app-custom-block-products> -->


    <!-- <div>
    <app-block-posts header="{{'home.latestNews' | translate}}" layout="list-sm" [posts]="posts"></app-block-posts>
    </div> -->

    <!-- <div #trp>
    <app-custom-block-product-columns [columns]="columns"></app-custom-block-product-columns>
    </div> -->
    <!-- <app-block-features></app-block-features> -->

    <!-- <div #bs1>
    <app-custom-block-products  [header]="bestsellersHeader" layout="large-first" [products]="productList"></app-custom-block-products>
    </div> -->

</div>
