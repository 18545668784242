import { Component, OnDestroy, OnInit, /*ChangeDetectorRef, ChangeDetectionStrategy*/ } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MobileMenuService } from '../../../../shared/services/mobile-menu.service';
import { mobileMenu } from '../../../../../data/mobile-menu';
import { MobileMenuItem } from '../../../../shared/interfaces/mobile-menu-item';
import { AuthService } from '../../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileMenuComponent implements OnDestroy, OnInit {
    private destroy$: Subject<any> = new Subject();

    isOpen = false;
    // links: MobileMenuItem[] = mobileMenu;
    items = [];
    lang = "";

    constructor(public mobilemenu: MobileMenuService,
      private translate: TranslateService,
      public authService: AuthService,
    /*private changeDetectorRefs: ChangeDetectorRef*/) {
    this.authService.mobileMenu$.subscribe(page => {
        this.getAllCategoryList();
    });
  }

    ngOnInit(): void {
        this.getProfile();
        this.mobilemenu.isOpen$.pipe(takeUntil(this.destroy$)).subscribe(isOpen => this.isOpen = isOpen);
        this.lang = this.authService.getLocale();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
          this.lang = event.lang;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onItemClick(event: MobileMenuItem): void {
        if (event.type === 'link') {
            this.mobilemenu.close();
        }

        // if (event.data && event.data.language) {
        //     console.log(event.data.language); // change language
        // }
    }

    getAllCategoryList(){
      this.authService.start();
      this.authService.getAllCategoryList().subscribe(
          data => {
            if(data['status'] == 'ok'){
              this.items = [];
              var categoryList = data['result'];

              categoryList.forEach((c, index) => {
                var item = {type: 'link', label: '', id: null, url: '', en: '', zh: '', children: []};
                item.id = c.id;
                item.url = './shop/' + c.id;
                item.en = c.categoryLocaleList[0].name;
                item.zh = c.categoryLocaleList[1].name;

                if(c.id == 37){
                  this.items.push(item);
                }
              });

              categoryList.forEach((c, index) => {
                var item = {type: 'link', label: '', id: null, url: '', en: '', zh: '', children: []};
                item.id = c.id;
                item.url = './shop/' + c.id;
                item.en = c.categoryLocaleList[0].name;
                item.zh = c.categoryLocaleList[1].name;

                if(index == 0){
                  // item.children = [
                  //     {type: 'link', label: 'Dresses', url: './shop', en: 'Dresses', zh: '裙子', children: [
                  //         {type: 'link', label: 'New Arrival', url: './shop', en: 'New Arrival', zh: '新品推荐'},
                  //         {type: 'link', label: 'Discounted', url: './shop', en: 'Discounted', zh: '折扣商品'},
                  //         {type: 'link', label: 'Bestseller', url: './shop', en: 'Bestseller', zh: '畅销商品'}
                  //     ]},
                  //     {type: 'link', label: 'Coats & Jackets', url: './shop', en: 'Coats & Jackets', zh: '夹克外套', children: [
                  //         {type: 'link', label: 'New Arrival', url: './shop', en: 'New Arrival', zh: '新品推荐'},
                  //         {type: 'link', label: 'Discounted', url: './shop', en: 'Discounted', zh: '折扣商品'},
                  //         {type: 'link', label: 'Bestseller', url: './shop', en: 'Bestseller', zh: '畅销商品'}
                  //     ]},
                  //     {type: 'link', label: 'Tops', url: './shop', en: 'Tops', zh: '上衣', children: [
                  //         {type: 'link', label: 'New Arrival', url: './shop', en: 'New Arrival', zh: '新品推荐'},
                  //         {type: 'link', label: 'Discounted', url: './shop', en: 'Discounted', zh: '折扣商品'},
                  //         {type: 'link', label: 'Bestseller', url: './shop', en: 'Bestseller', zh: '畅销商品'}
                  //     ]},
                  //     {type: 'link', label: 'Shorts', url: './shop', en: 'Shorts', zh: '短裤', children: [
                  //         {type: 'link', label: 'New Arrival', url: './shop', en: 'New Arrival', zh: '新品推荐'},
                  //         {type: 'link', label: 'Discounted', url: './shop', en: 'Discounted', zh: '折扣商品'},
                  //         {type: 'link', label: 'Bestseller', url: './shop', en: 'Bestseller', zh: '畅销商品'}
                  //     ]},
                  //     {type: 'link', label: 'Jeans', url: './shop', en: 'Jeans', zh: '牛仔', children: [
                  //         {type: 'link', label: 'New Arrival', url: './shop', en: 'New Arrival', zh: '新品推荐'},
                  //         {type: 'link', label: 'Discounted', url: './shop', en: 'Discounted', zh: '折扣商品'},
                  //         {type: 'link', label: 'Bestseller', url: './shop', en: 'Bestseller', zh: '畅销商品'}
                  //     ]},
                  //     {type: 'link', label: 'Skirts', url: './shop', en: 'Skirts', zh: '裙子', children: [
                  //         {type: 'link', label: 'New Arrival', url: './shop', en: 'New Arrival', zh: '新品推荐'},
                  //         {type: 'link', label: 'Discounted', url: './shop', en: 'Discounted', zh: '折扣商品'},
                  //         {type: 'link', label: 'Bestseller', url: './shop', en: 'Bestseller', zh: '畅销商品'}
                  //     ]},
                  //     {type: 'link', label: 'Lingerie', url: './shop', en: 'Lingerie', zh: '男女内衣', children: [
                  //         {type: 'link', label: 'New Arrival', url: './shop', en: 'New Arrival', zh: '新品推荐'},
                  //         {type: 'link', label: 'Discounted', url: './shop', en: 'Discounted', zh: '折扣商品'},
                  //         {type: 'link', label: 'Bestseller', url: './shop', en: 'Bestseller', zh: '畅销商品'}
                  //     ]}
                  // ];


                  // item.children = {
                  //     type: 'megamenu',
                  //     size: 'xl',
                  //     image: 'assets/images/megamenu/megamenu-1.jpg',
                  //     columns: [
                  //         {size: 3, items: [
                  //             {label: 'Dresses', url: './shop', en: 'Dresses', zh: '裙子', items: [
                  //                 {label: 'New Arrival', url: './shop', en: 'New Arrival', zh: '新品推荐'},
                  //                 {label: 'Discounted', url: './shop', en: 'Discounted', zh: '折扣商品'},
                  //                 {label: 'Bestseller', url: './shop', en: 'Bestseller', zh: '畅销商品'},
                  //             ]},
                  //             {label: 'Coats & Jackets', url: './shop', en: 'Coats & Jackets', zh: '夹克外套', items: [
                  //               {label: 'New Arrival', url: './shop', en: 'New Arrival', zh: '新品推荐'},
                  //               {label: 'Discounted', url: './shop', en: 'Discounted', zh: '折扣商品'},
                  //               {label: 'Bestseller', url: './shop', en: 'Bestseller', zh: '畅销商品'},
                  //             ]}
                  //         ]},
                      //     {size: 3, items: [
                      //         {label: 'Tops', url: './shop', en: 'Tops', zh: '上衣',  items: [
                      //           {label: 'New Arrival', url: './shop', en: 'New Arrival', zh: '新品推荐'},
                      //           {label: 'Discounted', url: './shop', en: 'Discounted', zh: '折扣商品'},
                      //           {label: 'Bestseller', url: './shop', en: 'Bestseller', zh: '畅销商品'},
                      //         ]},
                      //         {label: 'Shorts', url: './shop', en: 'Shorts', zh: '短裤', items: [
                      //           {label: 'New Arrival', url: './shop', en: 'New Arrival', zh: '新品推荐'},
                      //           {label: 'Discounted', url: './shop', en: 'Discounted', zh: '折扣商品'},
                      //           {label: 'Bestseller', url: './shop', en: 'Bestseller', zh: '畅销商品'},
                      //         ]}
                      //     ]},
                      //     {size: 3, items: [
                      //         {label: 'Jeans', url: './shop', en: 'Jeans', zh: '牛仔', items: [
                      //           {label: 'New Arrival', url: './shop', en: 'New Arrival', zh: '新品推荐'},
                      //           {label: 'Discounted', url: './shop', en: 'Discounted', zh: '折扣商品'},
                      //           {label: 'Bestseller', url: './shop', en: 'Bestseller', zh: '畅销商品'},
                      //         ]},
                      //         {label: 'Skirts', url: './shop', en: 'Skirts', zh: '裙子', items: [
                      //           {label: 'New Arrival', url: './shop', en: 'New Arrival', zh: '新品推荐'},
                      //           {label: 'Discounted', url: './shop', en: 'Discounted', zh: '折扣商品'},
                      //           {label: 'Bestseller', url: './shop', en: 'Bestseller', zh: '畅销商品'},
                      //         ]}
                      //     ]},
                      //     {size: 3, items: [
                      //         {label: 'Lingerie', url: './shop', en: 'Lingerie', zh: '男女内衣', items: [
                      //           {label: 'New Arrival', url: './shop', en: 'New Arrival', zh: '新品推荐'},
                      //           {label: 'Discounted', url: './shop', en: 'Discounted', zh: '折扣商品'},
                      //           {label: 'Bestseller', url: './shop', en: 'Bestseller', zh: '畅销商品'},
                      //         ]},
                      //
                      //     ]}
                      // ]
                  // };
                }
                // if(item.id == 38 || item.id == 39 || item.id == 40){
                //
                // }else{
                //   this.items.push(item);
                // }
                if(c.id != 37){
                  this.items.push(item);
                }
              });

              var divider = {
                type: "link"
              }
              var engObj = {
                en: "English",
                zh: "English",
                type: "language",
                image: 'language-1',
                locale: 'en'
              };
              var zhObj = {
                en: "中文",
                zh: "中文",
                type: "language",
                image: 'language-2',
                locale: 'zh'
              };
              this.items.push(divider);
              this.items.push(engObj);
              this.items.push(zhObj);

              if(!this.authService.loggedIn()){
                this.items.push(divider);
              }

              //login/register
              var loginRegObj = {
                en: "Login / Register",
                zh: "登入 / 注册",
                type: "link",
                url: './account/login',
                requireLogout: true
              };
              this.items.push(loginRegObj);

              var weniObj = {
                en: "WENI Points : " + (this.authService.weniValue ? this.authService.weniValue : "0"),
                zh: "WENI 点数 : " + (this.authService.weniValue ? this.authService.weniValue : "0"),
                type: "link",
                url: '',
                requireLogin: true
              };

              var usdtPointObj = {
                en: "USDT Points : " + (this.authService.usdtPointValue ? this.authService.usdtPointValue : "0"),
                zh: "USDT 点数 : " + (this.authService.usdtPointValue ? this.authService.usdtPointValue : "0"),
                type: "link",
                url: '',
                requireLogin: true
              };

              var usdtObj = {
                en: "USDT (ERC20) : " + (this.authService.usdtValue ? this.authService.usdtValue : "0"),
                zh: "USDT (ERC20) : " + (this.authService.usdtValue ? this.authService.usdtValue : "0"),
                type: "link",
                url: '',
                requireLogin: true
              };

              var weniTokenObj = {
                en: "WENI : " + (this.authService.weniTokenValue ? this.authService.weniTokenValue : "0"),
                zh: "WENI : " + (this.authService.weniTokenValue ? this.authService.weniTokenValue : "0"),
                type: "link",
                url: '',
                requireLogin: true
              };

              var otrObj = {
                en: "Ordinary Token Reward (OTR) : " + (this.authService.otrValue ? this.authService.otrValue : "0"),
                zh: "原始证 (OTR) : " + (this.authService.otrValue ? this.authService.otrValue : "0"),
                type: "link",
                url: '',
                requireLogin: true
              };

              var lpObj = {
                en: "Learning Point (LP) : " + (this.authService.lpValue ? this.authService.lpValue : "0"),
                zh: "金典动源 (LP) : " + (this.authService.lpValue ? this.authService.lpValue : "0"),
                type: "link",
                url: '',
                requireLogin: true
              };

              this.items.push(usdtObj);
              // this.items.push(weniTokenObj);
              this.items.push(weniObj);
              // this.items.push(usdtPointObj);

              this.items.push(otrObj);
              this.items.push(lpObj);


              //reload / order history / addresses / password / logout
              var myAccountObj = {type: 'link', label: 'myAccount', id: null, url: '', en: 'My Account', zh: '我的账号', requireLogin: true, children: []};

              var profileObj = {
                en: "Edit Profile",
                zh: "编辑个人资料",
                type: "link",
                url: './account/profile',
                requireLogin: true
              };
              var reloadObj = {
                en: "Reload",
                zh: "充值",
                type: "link",
                url: './account/reload',
                requireLogin: true
              };
              var reloadHistoryObj = {
                en: "Reload History",
                zh: "充值历史",
                type: "link",
                url: './account/reload-history',
                requireLogin: true
              };
              var myBiddingsObj = {
                en: "My Biddings",
                zh: "我的竞拍",
                type: "link",
                url: './account/bidding',
                requireLogin: true
              };
              var myWonAuctionsObj = {
                en: "My Won Auctions",
                zh: "我的获拍",
                type: "link",
                url: './account/orders',
                requireLogin: true
              };
              var withdrawObj = {
                en: "Withdrawal",
                zh: "提款",
                type: "link",
                url: './account/withdraw',
                requireLogin: true
              };
              // var addressesObj = {
              //   en: "Addresses",
              //   zh: "地址",
              //   type: "link",
              //   url: './account/addresses',
              //   requireLogin: true
              // };
              var passwordObj = {
                en: "Password",
                zh: "密码",
                type: "link",
                url: './account/password',
                requireLogin: true
              };
              var logoutObj = {
                en: "Logout",
                zh: "登出",
                type: "link",
                url: './account/login',
                requireLogin: true
              };

              myAccountObj.children = [
                profileObj,
                reloadObj,
                reloadHistoryObj,
                myBiddingsObj,
                myWonAuctionsObj,
                withdrawObj,
                passwordObj,
                logoutObj
              ];

              // this.items.push(profileObj);
              // this.items.push(reloadObj);
              // this.items.push(orderHistoryObj);
              // this.items.push(addressesObj);
              // this.items.push(passwordObj);
              // this.items.push(logoutObj);
              this.items.push(myAccountObj);
              // this.changeDetectorRefs.detectChanges();
            }else if(data['status'] == 'error'){
              this.authService.showToast("danger", data['errorMessage']);
            }else{
              this.authService.showToast("danger", this.authService.getTranslation("message.generalError"));
            }
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }

    getProfile(){
      this.authService.start();
      this.authService.getProfile().subscribe(
          data => {
            if(data['status'] == 'ok'){
              this.authService.weniValue = data['result'].weni;
              this.authService.usdtValue = data['result'].usdt;
              this.authService.otrValue = data['result'].otr;
              this.authService.lpValue = data['result'].lp;
            }else if(data['status'] == 'error'){
              if(data['errorCode'] == 'request.error.token.invalid'){
                this.authService.connectionTimeOut();
              }else{
                // this.authService.showToast("danger", data['errorMessage']);
              }
            }else{
              this.authService.showToast("danger", this.authService.getTranslation("message.generalError"));
            }
            this.getAllCategoryList();
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }
}
