import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../../auth.service';

@Component({
  selector: 'app-custom-block-products-large-first',
  templateUrl: './custom-block-products-large-first.component.html',
  styleUrls: ['./custom-block-products-large-first.component.sass']
})
export class CustomBlockProductsLargeFirstComponent {

  @Input() header: {};
  @Input() layout: 'large-first'|'large-last' = 'large-first';
  @Input() products: any[] = [];
  @Input() bannerType: 'SIDE'|'USDT'|'USDP';
  @Input() moreParam = null;

  banner;

  get large(): any {
      if (this.layout === 'large-first' && this.products.length > 0) {
          return this.products[0];
      } else if (this.layout === 'large-last' && this.products.length > 6) {
          return this.products[6];
      }

      return null;
  }

  get smalls(): any[] {
      if (this.layout === 'large-first') {
          return this.products.slice(0, 6);
      } else  {
          return this.products.slice(0, 6);
      }
  }

  constructor(private authService: AuthService) {
    this.getAssetList();
  }

  getAssetList(){
    this.authService.start();
    this.authService.getAssetList().subscribe(
        data => {
          if(data['status'] == 'ok'){
            var list = data['result'];
            list.forEach(a => {
              if(a.type == this.bannerType){
                if(!this.banner){
                  this.banner = a;
                }
              }
            });
          }
          this.authService.complete();
        },
        error => {
          if(error['error'].errorCode == 400){
            // this.authService.showToast("danger", error['error'].message);
          }
          this.authService.complete();
        }
      );
  }

}
