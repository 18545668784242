import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// modules (third-party)
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ModalModule } from 'ngx-bootstrap/modal';

// directives
import { ClickDirective } from './directives/click.directive';
import { CollapseContentDirective, CollapseDirective, CollapseItemDirective } from './directives/collapse.directive';
import { DepartmentsAreaDirective } from './directives/departments-area.directive';
import { DropdownDirective } from './directives/dropdown.directive';
import { FakeSlidesDirective } from './directives/fake-slides.directive';
import { OwlPreventClickDirective } from './directives/owl-prevent-click.directive';

// components
import { AlertComponent } from './components/alert/alert.component';
import { IconComponent } from './components/icon/icon.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PostCardComponent } from './components/post-card/post-card.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductComponent } from './components/product/product.component';
import { QuickviewComponent } from './components/quickview/quickview.component';
import { RatingComponent } from './components/rating/rating.component';



// pipes
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth.service';


import { CustomProductCardComponent } from './components/custom-product-card/custom-product-card.component';
import { CustomProductComponent } from './components/custom-product/custom-product.component';
import { CustomQuickviewComponent } from './components/custom-quickview/custom-quickview.component';
import { NoticeComponent } from './dialog/notice/notice.component';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { TimecounterComponent } from './components/timecounter/timecounter.component';
import { DialogRaiseBidComponent } from './dialog-raise-bid/dialog-raise-bid.component';
import { AuctionPriceComponent } from './dialog/auction-price/auction-price.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CustomProductCardModifiedComponent } from './components/custom-product-card-modified/custom-product-card-modified.component';
import { CountdownModule } from 'ngx-countdown';
import { NgxSimpleCountdownModule } from 'ngx-simple-countdown';




@NgModule({
    declarations: [
        // directives
        ClickDirective,
        CollapseContentDirective,
        CollapseDirective,
        CollapseItemDirective,
        DepartmentsAreaDirective,
        DropdownDirective,
        FakeSlidesDirective,
        OwlPreventClickDirective,
        // components
        AlertComponent,
        IconComponent,
        InputNumberComponent,
        LoadingBarComponent,
        PageHeaderComponent,
        PaginationComponent,
        PostCardComponent,
        ProductCardComponent,
        ProductComponent,
        QuickviewComponent,
        RatingComponent,
        // pipes
        CurrencyFormatPipe,
        CustomProductCardComponent,
        CustomProductComponent,
        CustomQuickviewComponent,
        NoticeComponent,
        TimecounterComponent,
        DialogRaiseBidComponent,
        AuctionPriceComponent,
        CustomProductCardModifiedComponent


    ],
    imports: [
        // modules (angular)
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        // modules (third-party)
        CarouselModule,
        ModalModule.forRoot(),
        TranslateModule,
        MatButtonModule,
        MatDialogModule,
        LazyLoadImageModule,
        CountdownModule,
        NgxSimpleCountdownModule

    ],
    exports: [
        // directives
        ClickDirective,
        CollapseContentDirective,
        CollapseDirective,
        CollapseItemDirective,
        DepartmentsAreaDirective,
        DropdownDirective,
        FakeSlidesDirective,
        OwlPreventClickDirective,
        // components
        AlertComponent,
        IconComponent,
        InputNumberComponent,
        LoadingBarComponent,
        PageHeaderComponent,
        PaginationComponent,
        PostCardComponent,
        ProductCardComponent,
        ProductComponent,
        QuickviewComponent,
        RatingComponent,
        // pipes
        CurrencyFormatPipe,
        TranslateModule,
        CustomProductCardComponent,
        CustomProductCardModifiedComponent,
        CustomProductComponent,
        CustomQuickviewComponent,
        NoticeComponent,
        AuctionPriceComponent
    ],entryComponents: [
        NoticeComponent
    ]
})
export class SharedModule {
  // constructor(private translate: TranslateService, public authService: AuthService) {
  //       // console.log("sharedModule currentLang is " + translate.currentLang);
  //       // console.log("sharedModule getBrowserLang() is " + translate.getBrowserLang());
  //       console.log("sharedModule authService.locale is " + authService.getLocale());
  //
  //       // translate.addLangs(['en', 'zh']);
  //       // translate.setDefaultLang(authService.getLocale());
  //       translate.use(authService.getLocale());
  //
  //       console.log("sharedModule getDefaultLang is " + translate.getDefaultLang());
  //       console.log("sharedModule currentLang is " + translate.currentLang);
  //   }
}
