import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges, OnInit {
    @Input() siblings = 1;
    @Input() current;
    @Input() total = 1;
    @Input() type = "product";
    @Input() currentTemp = 1;

    @Output() pageChange: EventEmitter<number> = new EventEmitter();

    pages: number[] = [];

    constructor(
      private translate: TranslateService,
    public authService: AuthService) {

    }

    ngOnInit(): void {
        // this.current = this.currentTemp;
        // this.current = 2;
        // console.log("page in pagination is " + this.current);
        this.calc();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.calc();
    }

    setPage(value: number): void {
        if (value < 1 || value > this.total || value === this.current) {
            return;
        }

        this.current = value;
        this.pageChange.emit(this.current);
        this.calc();
        if(this.type == "product"){
          this.paginateProductList();
        }else if(this.type == "order"){
          this.paginateOrderList();
        }else if(this.type == "reload"){
          this.paginateReloadList();
        }else if(this.type == "withdraw"){
          this.paginateWithdrawList();
        }else if(this.type == "wonAuction"){
          this.paginateWonAuctionList();
        }else if(this.type == "bidding"){
          this.paginateBiddingList();
        }else if(this.type == "deposit"){
          this.paginatePaymentList();
        }else if(this.type == "bid"){
          this.paginateBidPaymentList();
        }
    }

    trackByFn(index: number): number {
        return index;
    }

    private calc(): void {
        const min = Math.max(1, this.current - this.siblings - Math.max(0, this.siblings - this.total + this.current));
        const max = Math.min(this.total, min + this.siblings * 2);

        this.pages = [];

        for (let i = min; i <= max; i++) {
            this.pages.push(i);
        }
    }

    paginateProductList(){
      this.authService.paginateProductList(this.current);
    }

    paginateOrderList(){
      this.authService.paginateOrderList(this.current);
    }

    paginateReloadList(){
      this.authService.paginateReloadList(this.current);
    }

    paginateWithdrawList(){
      this.authService.paginateWithdrawList(this.current);
    }

    paginateWonAuctionList(){
      this.authService.paginateWonAuctionList(this.current);
    }

    paginateBiddingList(){
      this.authService.paginateBiddingList(this.current);
    }

    paginatePaymentList(){
      this.authService.paginatePaymentList(this.current);
    }

    paginateBidPaymentList(){
      this.authService.paginateBidPaymentList(this.current);
    }
}
