<div class="site-footer__widget footer-links">
    <h5 class="footer-links__title" *ngIf="lang == 'en'">{{ header.en }}</h5>
    <h5 class="footer-links__title" *ngIf="lang == 'zh'">{{ header.zh }}</h5>
    <ul class="footer-links__list">
        <li *ngFor="let link of links" class="footer-links__item">
          <a [routerLink]="link.url" class="footer-links__link" *ngIf="lang == 'en'">{{ link.en }}</a>
          <a [routerLink]="link.url" class="footer-links__link" *ngIf="lang == 'zh'">{{ link.zh }}</a>
        </li>
    </ul>
</div>
