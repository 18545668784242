
<div class="container">
    <div class="block-category">
        <div class="block-category-item">
            <div class="block-category-img">

            </div>
            <div class="block-category-text">居家生活</div>
        </div>

        <div class="block-category-item">
            <div class="block-category-img">

            </div>
            <div class="block-category-text">居家生活</div>
        </div>
        <div class="block-category-item">
            <div class="block-category-img">

            </div>
            <div class="block-category-text">居家生活</div>
        </div>
        <div class="block-category-item">
            <div class="block-category-img">

            </div>
            <div class="block-category-text">居家生活</div>
        </div>
        <div class="block-category-item">
            <div class="block-category-img">

            </div>
            <div class="block-category-text">居家生活</div>
        </div>
        <div class="block-category-item">
            <div class="block-category-img">

            </div>
            <div class="block-category-text">居家生活</div>
        </div>
        <div class="block-category-item">
            <div class="block-category-img">

            </div>
            <div class="block-category-text">居家生活</div>
        </div>
        <div class="block-category-item">
            <div class="block-category-img">

            </div>
            <div class="block-category-text">居家生活</div>
        </div>
        <div class="block-category-item">
            <div class="block-category-img">

            </div>
            <div class="block-category-text">居家生活</div>
        </div>
        <div class="block-category-item">
            <div class="block-category-img">

            </div>
            <div class="block-category-text">居家生活</div>
        </div>
    </div>
</div>