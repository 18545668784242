import { Component, Input, OnInit } from '@angular/core';
import { Megamenu } from '../../../../shared/interfaces/megamenu';
import { AuthService } from '../../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-header-megamenu',
    templateUrl: './megamenu.component.html',
    styleUrls: ['./megamenu.component.scss']
})
export class MegamenuComponent implements OnInit{
    @Input() menu: Megamenu;
    @Input() departments = false;
    lang = "";

    constructor(private translate: TranslateService,
    public authService: AuthService) { }

    ngOnInit(){
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });
    }


}
