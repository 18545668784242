<!-- .block-features -->
<div class="block block-features block-features--layout--{{ layout }}">
    <div class="container">
        <div class="block-features__list">
            <div class="block-features__item">
                <div class="block-features__icon">
                    <app-icon name="fi-free-delivery-48" size="48"></app-icon>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title" *ngIf="lang == 'en' && authService.en">{{authService.en.blockFeatures.freeShipping}}</div>
                    <div class="block-features__title" *ngIf="lang == 'zh' && authService.zh">{{authService.zh.blockFeatures.freeShipping}}</div>
                    <div class="block-features__subtitle" *ngIf="lang == 'en' && authService.en">{{authService.en.blockFeatures.freeShippingDesc}}</div>
                    <div class="block-features__subtitle" *ngIf="lang == 'zh' && authService.zh">{{authService.zh.blockFeatures.freeShippingDesc}}</div>
                </div>
            </div>
            <div class="block-features__divider"></div>
            <div class="block-features__item">
                <div class="block-features__icon">
                    <app-icon name="fi-24-hours-48" size="48"></app-icon>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title" *ngIf="lang == 'en' && authService.en">{{authService.en.blockFeatures.support}}</div>
                    <div class="block-features__title" *ngIf="lang == 'zh' && authService.zh">{{authService.zh.blockFeatures.support}}</div>

                    <div class="block-features__subtitle" *ngIf="lang == 'en' && authService.en">{{authService.en.blockFeatures.supportDesc}}</div>
                    <div class="block-features__subtitle" *ngIf="lang == 'zh' && authService.zh">{{authService.zh.blockFeatures.supportDesc}}</div>

                </div>
            </div>
            <div class="block-features__divider"></div>
            <div class="block-features__item">
                <div class="block-features__icon">
                    <app-icon name="fi-payment-security-48" size="48"></app-icon>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title" *ngIf="lang == 'en' && authService.en">{{authService.en.blockFeatures.safety}}</div>
                    <div class="block-features__title" *ngIf="lang == 'zh' && authService.zh">{{authService.zh.blockFeatures.safety}}</div>

                    <div class="block-features__subtitle" *ngIf="lang == 'en' && authService.en">{{authService.en.blockFeatures.safetyDesc}}</div>
                    <div class="block-features__subtitle" *ngIf="lang == 'zh' && authService.zh">{{authService.zh.blockFeatures.safetyDesc}}</div>
                </div>
            </div>
            <div class="block-features__divider"></div>
            <div class="block-features__item">
                <div class="block-features__icon">
                    <app-icon name="fi-tag-48" size="48"></app-icon>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title" *ngIf="lang == 'en' && authService.en">{{authService.en.blockFeatures.hotOffers}}</div>
                    <div class="block-features__title" *ngIf="lang == 'zh' && authService.zh">{{authService.zh.blockFeatures.hotOffers}}</div>

                    <div class="block-features__subtitle" *ngIf="lang == 'en' && authService.en">{{authService.en.blockFeatures.hotOffersDesc}}</div>
                    <div class="block-features__subtitle" *ngIf="lang == 'zh' && authService.zh">{{authService.zh.blockFeatures.hotOffersDesc}}</div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- .block-features / end -->
