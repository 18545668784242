<!-- .block-banner -->
<div class="block-banner "style="height:72px;">
  
        <a href="" class="block-banner__body h-100">
            <div class="block-banner__image block-banner__image--desktop img-thumbnail" style="background-image: url('assets/images/banners/banner-1.jpg')"></div>
            <div class="block-banner__image block-banner__image--mobile img-thumbnail" style="background-image: url('assets/images/banners/banner-1-mobile.jpg')"></div>
        </a>
  
</div>
<!-- .block-banner / end -->
