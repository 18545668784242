<ng-template #modal>
    <div class="quickview">
        <button class="quickview__close" type="button" appClick (click)="modalRef.hide()">
            <app-icon name="cross-20" size="20"></app-icon>
        </button>

        <app-product *ngIf="!custom" [product]="product" layout="quickview"></app-product>
        <app-custom-product *ngIf="custom" [product]="product" layout="quickview" (closeQuickview)="hideModal()"></app-custom-product>
    </div>
</ng-template>
