<div class="site-footer">
    <div class="container">
        <div class="site-footer__widgets">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <app-footer-contacts></app-footer-contacts>
                </div>
                <div class="col-12 col-md-3 col-lg-4">
                    <app-footer-links [header]="infoHeader" [links]="infoItems"></app-footer-links>
                </div>
                <!-- <div class="col-6 col-md-3 col-lg-2">
                    <app-footer-links [header]="myAccountHeader" [links]="myAccountItems"></app-footer-links>
                </div> -->
                <div class="col-12 col-md-12 col-lg-4">
                    <app-footer-newsletter class="site-footer__widget"></app-footer-newsletter>
                </div>
            </div>
        </div>

        <div class="site-footer__bottom">
            <div class="site-footer__copyright">
                    &copy; Copyright 2020, Weini Auction
            </div>
            <!-- <div class="site-footer__payments">
                <img src="assets/images/payments.png" alt="">
            </div> -->
        </div>
    </div>
</div>
