import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthService } from '../../../auth.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoticeComponent implements OnInit {
  lang = "";
  name = "";

  constructor(public dialogRef: MatDialogRef<NoticeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public authService: AuthService, private translate: TranslateService,
    private ref: ChangeDetectorRef) {

    }

  ngOnInit() {
    this.lang = this.authService.getLocale();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
    });

  }

  cancel(): void {
    this.dialogRef.close();
  }
}
