import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { departments } from '../../../../../data/header-departments';
import { DepartmentsService } from '../../../../shared/services/departments.service';
import { NavigationLink } from '../../../../shared/interfaces/navigation-link';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '../../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-header-departments',
    templateUrl: './departments.component.html',
    styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {
    private destroy$: Subject<any> = new Subject();

    //items: NavigationLink[] = departments;
    items = [
      // {label: 'womensFashion', url: './shop', en: '', zh: '', menu: {
      //     type: 'menu',
      //     items: [
      //         {label: 'newArrival', url: './shop', en: '', zh: ''},
      //         {label: 'tShirt', url: './shop', en: '', zh: ''},
      //         {label: 'shirt', url: './shop', en: '', zh: ''},
      //         {label: 'jacketCoat', url: './shop', en: '', zh: ''},
      //         {label: 'dress', url: './shop', en: '', zh: ''},
      //         {label: 'casualPants', url: './shop', en: '', zh: ''}
      //     ]
      // }},
      // {label: 'womensFashion', url: './shop', en: '', zh: '', menu: {
      //     type: 'megamenu',
      //     size: 'xl',
      //     image: 'assets/images/megamenu/megamenu-1.jpg',
      //     columns: [
      //         {size: 3, items: [
      //             {label: 'Power Tools', url: './shop', items: [
      //                 {label: 'Engravers', url: './shop'},
      //                 {label: 'Drills', url: './shop'},
      //                 {label: 'Wrenches', url: './shop'},
      //                 {label: 'Plumbing', url: './shop'},
      //                 {label: 'Wall Chaser', url: './shop'},
      //                 {label: 'Pneumatic Tools', url: './shop'},
      //                 {label: 'Milling Cutters', url: './shop'}
      //             ]},
      //             {label: 'Workbenches', url: './shop'},
      //             {label: 'Presses', url: './shop'},
      //             {label: 'Spray Guns', url: './shop'},
      //             {label: 'Riveters', url: './shop'}
      //         ]},
      //         {size: 3, items: [
      //             {label: 'Hand Tools', url: './shop', items: [
      //                 {label: 'Screwdrivers', url: './shop'},
      //                 {label: 'Handsaws', url: './shop'},
      //                 {label: 'Knives', url: './shop'},
      //                 {label: 'Axes', url: './shop'},
      //                 {label: 'Multitools', url: './shop'},
      //                 {label: 'Paint Tools', url: './shop'}
      //             ]},
      //             {label: 'Garden Equipment', url: './shop', items: [
      //                 {label: 'Motor Pumps', url: './shop'},
      //                 {label: 'Chainsaws', url: './shop'},
      //                 {label: 'Electric Saws', url: './shop'},
      //                 {label: 'Brush Cutters', url: './shop'}
      //             ]}
      //         ]},
      //         {size: 3, items: [
      //             {label: 'Machine Tools', url: './shop', items: [
      //                 {label: 'Thread Cutting', url: './shop'},
      //                 {label: 'Chip Blowers', url: './shop'},
      //                 {label: 'Sharpening Machines', url: './shop'},
      //                 {label: 'Pipe Cutters', url: './shop'},
      //                 {label: 'Slotting machines', url: './shop'},
      //                 {label: 'Lathes', url: './shop'}
      //             ]}
      //         ]},
      //         {size: 3, items: [
      //             {label: 'Instruments', url: './shop', items: [
      //                 {label: 'Welding Equipment', url: './shop'},
      //                 {label: 'Power Tools', url: './shop'},
      //                 {label: 'Hand Tools', url: './shop'},
      //                 {label: 'Measuring Tool', url: './shop'}
      //             ]}
      //         ]}
      //     ]
      // }},
      {label: 'mensFashion', url: './shop', en: '', zh: ''},
      {label: 'fashionAccessories', url: './shop', en: '', zh: ''},
      {label: 'shoesAndBags', url: './shop', en: '', zh: ''},
      {label: 'sportsAndOutdoors', url: './shop', en: '', zh: ''},
      {label: 'homeAndLifestyle', url: './shop', en: '', zh: ''},
      {label: 'electronic', url: './shop', en: '', zh: ''},
      {label: 'tvAndHomeAppliances', url: './shop', en: '', zh: ''},
      {label: 'babiesAndToys', url: './shop', en: '', zh: ''},
      {label: 'healthAndBeauty', url: './shop', en: '', zh: ''},
      {label: 'weniSpecial', url: './shop', en: '', zh: ''}

      // {label: 'womensFashion', url: './shop', en: '', zh: '', menu: {
      //     type: 'megamenu',
      //     size: 'xl',
      //     image: 'assets/images/megamenu/megamenu-1.jpg',
      //     columns: [
      //         {size: 3, items: [
      //             {label: 'Power Tools', url: './shop', items: [
      //                 {label: 'Engravers', url: './shop'},
      //                 {label: 'Drills', url: './shop'},
      //                 {label: 'Wrenches', url: './shop'},
      //                 {label: 'Plumbing', url: './shop'},
      //                 {label: 'Wall Chaser', url: './shop'},
      //                 {label: 'Pneumatic Tools', url: './shop'},
      //                 {label: 'Milling Cutters', url: './shop'}
      //             ]},
      //             {label: 'Workbenches', url: './shop'},
      //             {label: 'Presses', url: './shop'},
      //             {label: 'Spray Guns', url: './shop'},
      //             {label: 'Riveters', url: './shop'}
      //         ]},
      //         {size: 3, items: [
      //             {label: 'Hand Tools', url: './shop', items: [
      //                 {label: 'Screwdrivers', url: './shop'},
      //                 {label: 'Handsaws', url: './shop'},
      //                 {label: 'Knives', url: './shop'},
      //                 {label: 'Axes', url: './shop'},
      //                 {label: 'Multitools', url: './shop'},
      //                 {label: 'Paint Tools', url: './shop'}
      //             ]},
      //             {label: 'Garden Equipment', url: './shop', items: [
      //                 {label: 'Motor Pumps', url: './shop'},
      //                 {label: 'Chainsaws', url: './shop'},
      //                 {label: 'Electric Saws', url: './shop'},
      //                 {label: 'Brush Cutters', url: './shop'}
      //             ]}
      //         ]},
      //         {size: 3, items: [
      //             {label: 'Machine Tools', url: './shop', items: [
      //                 {label: 'Thread Cutting', url: './shop'},
      //                 {label: 'Chip Blowers', url: './shop'},
      //                 {label: 'Sharpening Machines', url: './shop'},
      //                 {label: 'Pipe Cutters', url: './shop'},
      //                 {label: 'Slotting machines', url: './shop'},
      //                 {label: 'Lathes', url: './shop'}
      //             ]}
      //         ]},
      //         {size: 3, items: [
      //             {label: 'Instruments', url: './shop', items: [
      //                 {label: 'Welding Equipment', url: './shop'},
      //                 {label: 'Power Tools', url: './shop'},
      //                 {label: 'Hand Tools', url: './shop'},
      //                 {label: 'Measuring Tool', url: './shop'}
      //             ]}
      //         ]}
      //     ]
      // }},
      // {label: 'mensFashion', url: './shop', en: '', zh: '', menu: {
      //     type: 'megamenu',
      //     size: 'lg',
      //     image: 'assets/images/megamenu/megamenu-2.jpg',
      //     columns: [
      //         {size: 4, items: [
      //             {label: 'Hand Tools', url: './shop', items: [
      //                 {label: 'Screwdrivers', url: './shop'},
      //                 {label: 'Handsaws', url: './shop'},
      //                 {label: 'Knives', url: './shop'},
      //                 {label: 'Axes', url: './shop'},
      //                 {label: 'Multitools', url: './shop'},
      //                 {label: 'Paint Tools', url: './shop'}
      //             ]},
      //             {label: 'Garden Equipment', url: './shop', items: [
      //                 {label: 'Motor Pumps', url: './shop'},
      //                 {label: 'Chainsaws', url: './shop'},
      //                 {label: 'Electric Saws', url: './shop'},
      //                 {label: 'Brush Cutters', url: './shop'}
      //             ]}
      //         ]},
      //         {size: 4, items: [
      //             {label: 'Machine Tools', url: './shop', items: [
      //                 {label: 'Thread Cutting', url: './shop'},
      //                 {label: 'Chip Blowers', url: './shop'},
      //                 {label: 'Sharpening Machines', url: './shop'},
      //                 {label: 'Pipe Cutters', url: './shop'},
      //                 {label: 'Slotting machines', url: './shop'},
      //                 {label: 'Lathes', url: './shop'}
      //             ]}
      //         ]},
      //         {size: 4, items: [
      //             {label: 'Instruments', url: './shop', items: [
      //                 {label: 'Welding Equipment', url: './shop'},
      //                 {label: 'Power Tools', url: './shop'},
      //                 {label: 'Hand Tools', url: './shop'},
      //                 {label: 'Measuring Tool', url: './shop'}
      //             ]}
      //         ]}
      //     ]
      // }},
      // {label: 'fashionAccessories', url: './shop', en: '', zh: '', menu: {
      //     type: 'megamenu',
      //     size: 'nl',
      //     image: 'assets/images/megamenu/megamenu-3.jpg',
      //     columns: [
      //         {size: 6, items: [
      //             {label: 'Hand Tools', url: './shop', items: [
      //                 {label: 'Screwdrivers', url: './shop'},
      //                 {label: 'Handsaws', url: './shop'},
      //                 {label: 'Knives', url: './shop'},
      //                 {label: 'Axes', url: './shop'},
      //                 {label: 'Multitools', url: './shop'},
      //                 {label: 'Paint Tools', url: './shop'}
      //             ]},
      //             {label: 'Garden Equipment', url: './shop', items: [
      //                 {label: 'Motor Pumps', url: './shop'},
      //                 {label: 'Chainsaws', url: './shop'},
      //                 {label: 'Electric Saws', url: './shop'},
      //                 {label: 'Brush Cutters', url: './shop'}
      //             ]}
      //         ]},
      //         {size: 6, items: [
      //             {label: 'Instruments', url: './shop', items: [
      //                 {label: 'Welding Equipment', url: './shop'},
      //                 {label: 'Power Tools', url: './shop'},
      //                 {label: 'Hand Tools', url: './shop'},
      //                 {label: 'Measuring Tool', url: './shop'}
      //             ]}
      //         ]}
      //     ]
      // }},
      // {label: 'shoesAndBags', url: './shop', en: '', zh: '', menu: {
      //     type: 'megamenu',
      //     size: 'sm',
      //     columns: [
      //         {size: 12, items: [
      //             {label: 'Hand Tools', url: './shop', items: [
      //                 {label: 'Screwdrivers', url: './shop'},
      //                 {label: 'Handsaws', url: './shop'},
      //                 {label: 'Knives', url: './shop'},
      //                 {label: 'Axes', url: './shop'},
      //                 {label: 'Multitools', url: './shop'},
      //                 {label: 'Paint Tools', url: './shop'}
      //             ]},
      //             {label: 'Garden Equipment', url: './shop', items: [
      //                 {label: 'Motor Pumps', url: './shop'},
      //                 {label: 'Chainsaws', url: './shop'},
      //                 {label: 'Electric Saws', url: './shop'},
      //                 {label: 'Brush Cutters', url: './shop'}
      //             ]}
      //         ]}
      //     ]
      // }},
      // {label: 'sportsAndOutdoors', url: './shop', en: '', zh: '', menu: {
      //     type: 'menu',
      //     items: [
      //         {label: 'Soldering Equipment', url: './shop', items: [
      //             {label: 'Soldering Station', url: './shop'},
      //             {label: 'Soldering Dryers', url: './shop'},
      //             {label: 'Gas Soldering Iron', url: './shop'},
      //             {label: 'Electric Soldering Iron', url: './shop'}
      //         ]},
      //         {label: 'Light Bulbs', url: './shop'},
      //         {label: 'Batteries', url: './shop'},
      //         {label: 'Light Fixtures', url: './shop'},
      //         {label: 'Warm Floor', url: './shop'},
      //         {label: 'Generators', url: './shop'},
      //         {label: 'UPS', url: './shop'}
      //     ]
      // }},
      // {label: 'homeAndLifestyle', url: './shop', en: '', zh: ''},
      // {label: 'electronic', url: './shop', en: '', zh: ''},
      // {label: 'tvAndHomeAppliances', url: './shop', en: '', zh: ''},
      // {label: 'babiesAndToys', url: './shop', en: '', zh: ''},
      // {label: 'healthAndBeauty', url: './shop', en: '', zh: ''},
      // {label: 'weniSpecial', url: './shop', en: '', zh: ''}
    ];

    isOpen = false;
    fixed = false;
    lang = "";

    private get element(): HTMLElement {
        return this.el.nativeElement;
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private renderer: Renderer2,
        private el: ElementRef,
        private service: DepartmentsService,
        private translate: TranslateService,
        public authService: AuthService
    ) {
      this.getAllCategoryList();
    }

    ngOnInit(): void {
        const root = this.element.querySelector('.departments') as HTMLElement;
        const content = this.element.querySelector('.departments__links-wrapper') as HTMLElement;

        this.service.areaElement$.pipe(takeUntil(this.destroy$)).subscribe(areaElement => {
            if (areaElement) {
                this.fixed = true;
                this.isOpen = true;

                if (isPlatformBrowser(this.platformId)) {
                    const areaRect = areaElement.getBoundingClientRect();
                    const areaBottom = areaRect.top + areaRect.height + window.scrollY;

                    root.classList.remove('departments--transition');
                    root.classList.add('departments--fixed', 'departments--opened');

                    const height = areaBottom - (content.getBoundingClientRect().top + window.scrollY);


                    content.getBoundingClientRect(); // force reflow
                } else {
                    this.renderer.addClass(root, 'departments--fixed');
                    this.renderer.addClass(root, 'departments--opened');
                }
            } else {
                this.fixed = false;
                this.isOpen = false;

                if (isPlatformBrowser(this.platformId)) {
                    root.classList.remove('departments--opened', 'departments--fixed');
                    content.style.height = '';
                } else {
                    this.renderer.removeClass(root, 'departments--fixed');
                    this.renderer.removeClass(root, 'departments--opened');
                }
            }
        });

        if (isPlatformBrowser(this.platformId)) {
            fromEvent<MouseEvent>(document, 'mousedown').pipe(
                takeUntil(this.destroy$)
            ).subscribe((event) => {
                if (event.target instanceof HTMLElement && !this.element.contains(event.target)) {
                    this.close();
                }
            });

            fromEvent<TransitionEvent>(content, 'transitionend').pipe(
                takeUntil(this.destroy$)
            ).subscribe((event) => {
                if (event.propertyName === 'height') {
                    root.classList.remove('departments--transition');
                }
            });
        }

        this.lang = this.authService.getLocale();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
          this.lang = event.lang;
        });

        // this.initTranslate();
    }

    toggle(): void {
        if (this.isOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    open(): void {
        this.isOpen = true;

        const root = this.element.querySelector('.departments') as HTMLElement;
        const content = root.querySelector('.departments__links-wrapper') as HTMLElement;
        const startHeight = content.getBoundingClientRect().height;

        root.classList.add('departments--transition', 'departments--opened');

        const endHeight = content.getBoundingClientRect().height;

        content.style.height = startHeight + 'px';
        content.getBoundingClientRect(); // force reflow
        content.style.height = endHeight + 'px';
    }

    close(): void {
        if (this.fixed || !this.isOpen) {
            return;
        }

        this.isOpen = false;

        const root = this.element.querySelector('.departments') as HTMLElement;
        const content = root.querySelector('.departments__links-wrapper') as HTMLElement;
        const startHeight = content.getBoundingClientRect().height;

        content.style.height = startHeight + 'px';

        root.classList.add('departments--transition');
        root.classList.remove('departments--opened');

        content.getBoundingClientRect(); // force reflow
        content.style.height = '';
    }

    // initTranslate(){
    //   this.items.forEach(item => {
    //     // item.zh = this.authService.zh.departmentsData[item.label];
    //     try{
    //       if(item.menu && item.menu.items){
    //         item.menu.items.forEach(i => {
    //           try{
    //             i.zh = this.authService.zh.departmentsData[item.label + "Sub"][i.label];
    //           }catch(err){
    //
    //           }
    //         });
    //       }
    //     }catch(err){
    //
    //     }
    //   });
    //   this.items.forEach(item => {
    //     // item.en = this.authService.en.departmentsData[item.label];
    //     try{
    //       if(item.menu && item.menu.items){
    //         item.menu.items.forEach(i => {
    //           try{
    //             i.en = this.authService.en.departmentsData[item.label + "Sub"][i.label];
    //           }catch(err){
    //
    //           }
    //         });
    //       }
    //     }catch(err){
    //
    //     }
    //   });
    // }

    getAllCategoryList(){
      this.authService.start();
      this.authService.getAllCategoryList().subscribe(
          data => {
            if(data['status'] == 'ok'){
              this.items = [];
              var categoryList = data['result'];

              //align weini special category as the first item
              // categoryList.forEach((c, index) => {
              //   if(c.id == 37){
              //     var item = {label: '', id: null, url: '', en: '', zh: '', menu: null};
              //     item.id = c.id;
              //     item.url = '/classic/shop/' + c.id;
              //     item.en = c.categoryLocaleList[0].name;
              //     item.zh = c.categoryLocaleList[1].name;
              //
              //     this.items.push(item);
              //   }
              // });

              categoryList.forEach((c, index) => {
                if(c.id != 37){
                  var item = {label: '', id: null, url: '', en: '', zh: '', menu: null};
                  item.id = c.id;
                  item.url = '/classic/shop/' + c.id;
                  item.en = c.categoryLocaleList[0].name;
                  item.zh = c.categoryLocaleList[1].name;
                  // console.log(categoryList);
                  if(c.id == 27){
                    // item.menu = {
                    //     type: 'megamenu',
                    //     size: 'xl',
                    //     image: 'assets/images/megamenu/megamenu-1.jpg',
                    //     columns: [
                    //         {size: 3, items: [
                    //             {label: 'Dresses', url: '/classic/shop/' + item.id, en: 'Dresses', zh: '裙子', items: [
                    //                 {label: 'New Arrival', url: '/classic/shop/' + item.id, en: 'New Arrival', zh: '新品推荐'},
                    //                 {label: 'Discounted', url: '/classic/shop/' + item.id, en: 'Discounted', zh: '折扣商品'},
                    //                 {label: 'Bestseller', url: '/classic/shop/' + item.id, en: 'Bestseller', zh: '畅销商品'},
                    //             ]},
                    //             {label: 'Coats & Jackets', url: '/classic/shop/' + item.id, en: 'Coats & Jackets', zh: '夹克外套', items: [
                    //               {label: 'New Arrival', url: '/classic/shop/' + item.id, en: 'New Arrival', zh: '新品推荐'},
                    //               {label: 'Discounted', url: '/classic/shop/' + item.id, en: 'Discounted', zh: '折扣商品'},
                    //               {label: 'Bestseller', url: '/classic/shop/' + item.id, en: 'Bestseller', zh: '畅销商品'},
                    //             ]}
                    //         ]},
                    //         {size: 3, items: [
                    //             {label: 'Tops', url: '/classic/shop/' + item.id, en: 'Tops', zh: '上衣',  items: [
                    //               {label: 'New Arrival', url: '/classic/shop/' + item.id, en: 'New Arrival', zh: '新品推荐'},
                    //               {label: 'Discounted', url: '/classic/shop/' + item.id, en: 'Discounted', zh: '折扣商品'},
                    //               {label: 'Bestseller', url: '/classic/shop/' + item.id, en: 'Bestseller', zh: '畅销商品'},
                    //             ]},
                    //             {label: 'Shorts', url: '/classic/shop/' + item.id, en: 'Shorts', zh: '短裤', items: [
                    //               {label: 'New Arrival', url: '/classic/shop/' + item.id, en: 'New Arrival', zh: '新品推荐'},
                    //               {label: 'Discounted', url: '/classic/shop/' + item.id, en: 'Discounted', zh: '折扣商品'},
                    //               {label: 'Bestseller', url: '/classic/shop/' + item.id, en: 'Bestseller', zh: '畅销商品'},
                    //             ]}
                    //         ]},
                    //         {size: 3, items: [
                    //             {label: 'Jeans', url: '/classic/shop/' + item.id, en: 'Jeans', zh: '牛仔', items: [
                    //               {label: 'New Arrival', url: '/classic/shop/' + item.id, en: 'New Arrival', zh: '新品推荐'},
                    //               {label: 'Discounted', url: '/classic/shop/' + item.id, en: 'Discounted', zh: '折扣商品'},
                    //               {label: 'Bestseller', url: '/classic/shop/' + item.id, en: 'Bestseller', zh: '畅销商品'},
                    //             ]},
                    //             {label: 'Skirts', url: '/classic/shop/' + item.id, en: 'Skirts', zh: '裙子', items: [
                    //               {label: 'New Arrival', url: '/classic/shop/' + item.id, en: 'New Arrival', zh: '新品推荐'},
                    //               {label: 'Discounted', url: '/classic/shop/' + item.id, en: 'Discounted', zh: '折扣商品'},
                    //               {label: 'Bestseller', url: '/classic/shop/' + item.id, en: 'Bestseller', zh: '畅销商品'},
                    //             ]}
                    //         ]},
                    //         {size: 3, items: [
                    //             {label: 'Lingerie', url: '/classic/shop/' + item.id, en: 'Lingerie', zh: '男女内衣', items: [
                    //               {label: 'New Arrival', url: '/classic/shop/' + item.id, en: 'New Arrival', zh: '新品推荐'},
                    //               {label: 'Discounted', url: '/classic/shop/' + item.id, en: 'Discounted', zh: '折扣商品'},
                    //               {label: 'Bestseller', url: '/classic/shop/' + item.id, en: 'Bestseller', zh: '畅销商品'},
                    //             ]},
                    //
                    //         ]}
                    //     ]
                    // };
                  }
                  this.items.push(item);
                }
              });
            }else if(data['status'] == 'error'){
              this.authService.showToast("danger", data['errorMessage']);
            }else{
              this.authService.showToast("danger", this.authService.getTranslation("message.generalError"));
            }
            this.authService.complete();
          },
          error => {
            if(error['error'].errorCode == 400){
              this.authService.showToast("danger", error['error'].message);
            }
            this.authService.complete();
          }
        );
    }
}
