import { Component, Input, OnInit } from '@angular/core';
import { NestedLink } from '../../../../shared/interfaces/nested-link';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '../../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-header-custom-menu',
    templateUrl: './custom-menu.component.html',
    styleUrls: ['./custom-menu.component.scss']
})
export class CustomMenuComponent implements OnInit{
    @Input() layout: 'classic'|'topbar' = 'classic';
    @Input() items: NestedLink[] = [];
    lang = "";

    constructor(private translate: TranslateService, public authService: AuthService) { }

    ngOnInit(){
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });
    }
}
