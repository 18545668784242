<div class="product product--layout--{{ layout }}" *ngIf="product">
    <div class="product__content">
        <!-- .product__gallery -->
        <div class="product__gallery">
            <div class="product-gallery" *ngIf="showGallery">
                <div class="product-gallery__featured">
                    <owl-carousel-o [options]="carouselOptions" (changed)="featuredCarouselTranslated($event)" appOwlPreventClick #featuredCarousel>
                        <ng-container *ngFor="let image of images">
                            <ng-template carouselSlide [id]="image.id">
                                <a appClick (click)="openPhotoSwipe($event, image);" [href]="image.url" target="_blank">
                                    <img [src]="image.url" alt="" #imageElement>
                                </a>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
                <div class="product-gallery__carousel">
                    <owl-carousel-o [options]="thumbnailsCarouselOptions" [appFakeSlides]="images.length" #fakeSlides="appFakeSlides" appOwlPreventClick #thumbnailsCarousel>
                        <ng-container *ngFor="let image of images">
                            <ng-template carouselSlide [id]="image.id">
                                <span class="product-gallery__carousel-item"
                                   [ngClass]="{'product-gallery__carousel-item--active': image.active}"
                                   appClick (click)="featuredCarousel.to(image.id); setActiveImage(image)">
                                    <img class="product-gallery__carousel-image" [src]="image.url" alt="">
                                </span>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngFor="let i of fakeSlides.slides">
                            <ng-template carouselSlide [id]="'fake-slide-'+i"></ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
        <!-- .product__gallery / end -->
        <!-- .product__info -->

        
        <div class="product__info">

            <h1 class="product__name">{{ product.name }}</h1>
            <!-- <div class="product__rating">
                <div class="product__rating-stars">
                    <app-rating [value]="product.rating"></app-rating>
                </div>
                <div class="product__rating-legend">
                    <a href="" appClick>{{ product.reviews }} Reviews</a><span>/</span><a href="" appClick>Write A Review</a>
                </div>
            </div> -->
            <div class="product__description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare, mi in ornare elementum, libero nibh
                lacinia urna, quis convallis lorem erat at purus. Maecenas eu varius nisi.
            </div>
            <ul class="product__features">
                <li>Speed: 750 RPM</li>
                <li>Power Source: Cordless-Electric</li>
                <li>Battery Cell Type: Lithium</li>
                <li>Voltage: 20 Volts</li>
                <li>Battery Capacity: 2 Ah</li>
            </ul>
            <ul class="product__meta">
                <li class="product__meta-availability">{{'productCard.availability' | translate}}: <span class="text-success">{{'productCard.inStock' | translate}}</span></li>
                <li>{{'productCard.brand' | translate}}: <a href="" appClick>Wakita</a></li>
                <li>{{'productCard.sku' | translate}}: 83690/32</li>
            </ul>
        </div>
        <!-- .product__info / end -->
        <!-- .product__sidebar -->
        <div class="product__sidebar">
            <div class="product__availability">
                {{'productCard.availability' | translate}}: <span class="text-success">{{'productCard.inStock' | translate}}</span>
            </div>

            <div class="product__prices">
                <ng-container *ngIf="product.compareAtPrice">
                    <span class="product__new-price">{{ product.price |currencyFormat }}</span>{{ ' ' }}
                    <span class="product__old-price">{{ product.compareAtPrice|currencyFormat }}</span>
                </ng-container>
                <ng-container *ngIf="!product.compareAtPrice">
                    {{ product.price|currencyFormat }}
                </ng-container>
            </div>
            <!-- .product__options -->
            <form class="product__options">
                <div class="form-group product__option">
                    <label class="product__option-label">{{'productCard.color' | translate}}</label>
                    <div class="input-radio-color">
                        <div class="input-radio-color__list">
                            <label class="input-radio-color__item input-radio-color__item--white" style="color: #fff;" data-toggle="tooltip" title="{{'productCard.white' | translate}}">
                                <input type="radio" name="color">
                                <span></span>
                            </label>
                            <label class="input-radio-color__item" style="color: #ffd333;" data-toggle="tooltip" title="{{'productCard.yellow' | translate}}">
                                <input type="radio" name="color">
                                <span></span>
                            </label>
                            <label class="input-radio-color__item" style="color: #ff4040;" data-toggle="tooltip" title="{{'productCard.red' | translate}}">
                                <input type="radio" name="color">
                                <span></span>
                            </label>
                            <label class="input-radio-color__item input-radio-color__item--disabled" style="color: #4080ff;" data-toggle="tooltip" title="{{'productCard.blue' | translate}}">
                                <input type="radio" name="color" disabled>
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group product__option">
                    <label class="product__option-label">{{'productCard.material' | translate}}</label>
                    <div class="input-radio-label">
                        <div class="input-radio-label__list">
                            <label>
                                <input type="radio" name="material">
                                <span>{{'productCard.metal' | translate}}</span>
                            </label>
                            <label>
                                <input type="radio" name="material">
                                <span>{{'productCard.wood' | translate}}</span>
                            </label>
                            <label>
                                <input type="radio" name="material" disabled>
                                <span>{{'productCard.plastic' | translate}}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="form-group product__option">
                    <label class="product__option-label">{{'productCard.quantity' | translate}}</label>
                    <div class="product__actions">
                        <div class="product__actions-item">
                            <app-input-number aria-label="Quantity" class="product__quantity" size="lg" [min]="1" [formControl]="quantity"></app-input-number>
                        </div>
                        <div class="product__actions-item product__actions-item--addtocart">
                            <button type="button" class="btn btn-primary btn-lg"
                                    [ngClass]="{'btn-loading': addingToCart}"
                                    appClick (click)="addToCart()">{{'productCard.addToCart' | translate}}</button>
                        </div>

                    </div>
                </div>
            </form>
            <!-- .product__options / end -->
        </div>
        <!-- .product__end -->
        <!-- <div class="product__footer">
            <div class="product__tags tags">
                <div class="tags__list">
                    <a href="" appClick>Mounts</a>
                    <a href="" appClick>Electrodes</a>
                    <a href="" appClick>Chainsaws</a>
                </div>
            </div>


        </div> -->
    </div>
</div>
