import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { BlockHeaderGroup } from '../../../../shared/interfaces/block-header-group';
import { AuthService } from '../../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-block-header',
    templateUrl: './block-header.component.html',
    styleUrls: ['./block-header.component.scss']
})
export class BlockHeaderComponent implements OnInit{
    @Input() header = {};
    @Input() arrows = false;
    @Input() groups: BlockHeaderGroup[] = [];

    @Output() next: EventEmitter<any> = new EventEmitter();
    @Output() prev: EventEmitter<any> = new EventEmitter();

    @Output() groupChange: EventEmitter<BlockHeaderGroup> = new EventEmitter();
    lang = "";

    @Input() storeId = null;
    @Input() brandId = null;
    @Input() moreParam = null;

    constructor(private translate: TranslateService, public authService: AuthService) {
      //translate.setDefaultLang(translate.getBrowserLang());
    }

    setGroup(group: BlockHeaderGroup): void {
        this.groups.forEach(g => g.current = g === group);
        this.groupChange.emit(group);
    }

    ngOnInit(){
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
        // this.authService.setLocale(this.lang);
      });

      // this.initTranslate();
    }

    goToBrandMerchantPage(){
      if(this.storeId && this.brandId){
        this.authService.router.navigate(['/classic/shop/category-grid-3-columns-sidebar/', this.storeId + "brand" + this.brandId]);
      }
    }

    more(){
      if(this.moreParam && this.moreParam.length > 0){
        this.authService.router.navigate(['/classic/shop/category-grid-3-columns-sidebar/', this.moreParam]);
      }
    }

    // initTranslate(){
    //   this.translate.getTranslation("zh").subscribe(res => {
    //     this.authService.zh = res;
    //     try{
    //       this.header.zh = this.authService.zh.home[this.header.header];
    //     }catch(err){
    //
    //     }
    //   });
    //   this.translate.getTranslation("en").subscribe(res => {
    //     this.authService.en = res;
    //     try{
    //       this.header.en = this.authService.en.home[this.header.header];
    //     }catch(err){
    //
    //     }
    //   });
    // }
}
