<div class="product-card" [ngClass]="{
    'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
    'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
    'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
    'product-card--layout--list':                        layout === 'list',
    'product-card--layout--horizontal':                  layout === 'horizontal'
}">
    <button class="product-card__quickview" type="button" appClick (click)="showQuickview()" [ngClass]="{'product-card__quickview--preload': showingQuickview}">
        <app-icon name="quickview-16" size="16"></app-icon>
        <span class="fake-svg-icon"></span>
    </button>

    
   
    <div  class="product-card__badges-list">
        <div  class="product-card__badge product-card__badge--sale">{{'productCard.auction' | translate}}</div>
        
    </div>

    <div class="product-card__image">
        <a appClick [routerLink]="root.product(product.id)" *ngIf="product.images?.length"><img [src]="product.images[0]" alt=""></a>
    </div>
    <div class="product-card__info">
        <div class="product-card__name">
            <a appClick [routerLink]="root.product(product.id)">{{ product.name }}</a>
        </div>
        <!-- <div class="product-card__rating">
            <app-rating [value]="product.rating"></app-rating>
            <div class="product-card__rating-legend">{{ product.reviews }} Reviews</div>
        </div> -->
        <ul *ngIf="product.features.length" class="product-card__features-list">
            <li *ngFor="let feature of product.features">{{ feature.name }}: {{ feature.value }}</li>
        </ul>
    </div>
    <div class="product-card__actions">
        <div class="product-card__availability" *ngIf="lang == 'en' && authService.en">
            {{authService.en.productCard.availability}}:
            <span *ngIf="product.availability === 'in-stock'" class="text-success">{{authService.en.productCard.inStock}}</span>
        </div>
        <div class="product-card__availability" *ngIf="lang == 'zh' && authService.zh">
            {{authService.zh.productCard.availability}}:
            <span *ngIf="product.availability === 'in-stock'" class="text-success">{{authService.zh.productCard.inStock}}</span>
        </div>
        <!-- <div class="product-card__prices">
            <ng-container *ngIf="product.compareAtPrice">
                <span class="">{{ product.price|currencyFormat }}</span>{{ ' ' }}
                <span class="product-card__old-price">{{ product.compareAtPrice|currencyFormat }}</span>
            </ng-container>
            <ng-container *ngIf="!product.compareAtPrice">
                {{ product.price|currencyFormat }}
            </ng-container>
        </div> -->

        <div class="product-card__prices">

        
            <ng-container>
                <div class="typography font-size-smaller mb-2">
                    <span>{{'productCard.auctionends' | translate}}: </span>
                    <div class="product__new-price">
                        
                       10 days 1 hour 24 min 50 sec
                    </div>{{ ' ' }}
                </div>
            </ng-container>

            <ng-container>
                <div class="d-flex typography font-size-smaller">
                    <span  >{{'productCard.startingprice' | translate}}: </span>
                    <div  class=" pl-6 text-muted">USDT{{ product.price }}</div>{{ ' ' }}
                </div>
            </ng-container>
        
            <ng-container>
                <div class="d-flex typography font-size-smaller">
                    <span>{{'productCard.biddingprice' | translate}}: </span>
                    <div  class=" pl-6 text-muted">USDT{{ product.price }}</div>{{ ' ' }}
                </div>
            </ng-container>

    </div>
        <!-- <div class="product-card__buttons">
            <button class="btn btn-primary product-card__addtocart" type="button" appClick (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}" *ngIf="lang == 'en' && authService.en">{{authService.en.productCard.addToCart}}</button>
            <button class="btn btn-primary product-card__addtocart" type="button" appClick (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}" *ngIf="lang == 'zh' && authService.zh">{{authService.zh.productCard.addToCart}}</button>

            <button class="btn btn-secondary product-card__addtocart product-card__addtocart--list" type="button" appClick (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}" *ngIf="lang == 'en' && authService.en">{{authService.en.productCard.addToCart}}</button>
            <button class="btn btn-secondary product-card__addtocart product-card__addtocart--list" type="button" appClick (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}" *ngIf="lang == 'zh' && authService.zh">{{authService.zh.productCard.addToCart}}</button>



        </div> -->
    </div>
</div>
