import { Component, Input } from '@angular/core';
import { DirectionService } from '../../../shared/services/direction.service';
import { Category } from '../../../../app/shared/interfaces/category';
import { AuthService } from '../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-block-brands',
    templateUrl: './block-brands.component.html',
    styleUrls: ['./block-brands.component.scss']
})
export class BlockBrandsComponent {
    @Input() brands: any[] = [];
    lang = "";

    carouselOptions = {
        items: 6,
        nav: false,
        dots: false,
        loop: true,
        responsive: {
            1100: {items: 6},
            920: {items: 5},
            680: {items: 4},
            500: {items: 3},
            0: {items: 2}
        },
        rtl: this.direction.isRTL()
    };

    constructor(
        private direction: DirectionService,
        private translate: TranslateService,
        public authService: AuthService
    ) { }

    ngOnInit(){
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
        // this.authService.setLocale(this.lang);
      });
    }
}
