import { Component, Input, OnInit } from '@angular/core';
import { Link } from '../../../../shared/interfaces/link';
import { AuthService } from '../../../../auth.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
    selector: 'app-footer-links',
    templateUrl: './links.component.html',
    styleUrls: ['./links.component.scss']
})
export class LinksComponent implements OnInit{
    @Input() header: Link;
    @Input() en: string;
    @Input() zh: string;
    @Input() links: Link[] = [];
    lang = "";

    constructor(private translate: TranslateService, public authService: AuthService) { }

    ngOnInit(){
      this.lang = this.authService.getLocale();
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
      });
    }
}
