import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// modules (third-party)
import { CarouselModule } from 'ngx-owl-carousel-o';

// modules
import { SharedModule } from '../../shared/shared.module';

// blocks
import { BlockBannerComponent } from './block-banner/block-banner.component';
import { BlockBrandsComponent } from './block-brands/block-brands.component';
import { BlockCategoriesComponent } from './block-categories/block-categories.component';
import { BlockFeaturesComponent } from './block-features/block-features.component';
import { BlockMapComponent } from './block-map/block-map.component';
import { BlockPostsComponent } from './block-posts/block-posts.component';
import { BlockProductColumnsComponent } from './block-product-columns/block-product-columns.component';
import { BlockProductsCarouselComponent } from './block-products-carousel/block-products-carousel.component';
import { BlockProductsComponent } from './block-products/block-products.component';
import { BlockSlideshowComponent } from './block-slideshow/block-slideshow.component';
import { BlockMerchantComponent } from './block-merchant/block-merchant.component';

// components
import { BlockHeaderComponent } from './components/block-header/block-header.component';
import { BlockMallComponent } from './block-mall/block-mall.component';


import { CustomBlockProductsComponent } from './custom-block-products/custom-block-products.component';
import { CustomBlockProductsCarouselComponent } from './custom-block-products-carousel/custom-block-products-carousel.component';
import { CustomBlockProductColumnsComponent } from './custom-block-product-columns/custom-block-product-columns.component';
import { CustomBlockProductsMerchantComponent } from './custom-block-products-merchant/custom-block-products-merchant.component';
import { CustomBlockProductsCarouselMerchantComponent } from './custom-block-products-carousel-merchant/custom-block-products-carousel-merchant.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BlockProductCustomVerticleComponent } from './block-product-custom-verticle/block-product-custom-verticle.component';
import { BlockProductCustomHorizontalComponent } from './block-product-custom-horizontal/block-product-custom-horizontal.component';
import { CustomBlockProductsLargeFirstComponent } from './custom-block-products-large-first/custom-block-products-large-first.component';
import { BlockCustomCarouselComponent } from './block-custom-carousel/block-custom-carousel.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
@NgModule({
    declarations: [
        // blocks
        BlockBannerComponent,
        BlockBrandsComponent,
        BlockCategoriesComponent,
        BlockFeaturesComponent,
        BlockMapComponent,
        BlockPostsComponent,
        BlockProductColumnsComponent,
        BlockProductsCarouselComponent,
        BlockProductsComponent,
        BlockSlideshowComponent,
        // components
        BlockHeaderComponent,
        BlockMallComponent,
        CustomBlockProductsComponent,
        CustomBlockProductsCarouselComponent,
        CustomBlockProductColumnsComponent,
        CustomBlockProductsMerchantComponent,
        CustomBlockProductsCarouselMerchantComponent,
        BlockMerchantComponent,
        BlockProductCustomVerticleComponent,
        BlockProductCustomHorizontalComponent,
        CustomBlockProductsLargeFirstComponent,
        BlockCustomCarouselComponent
    ],
    imports: [
        // modules (angular)
        CommonModule,
        RouterModule,
        // modules (third-party)
        CarouselModule,
        // modules
        SharedModule,
        LazyLoadImageModule,
        AngularSvgIconModule.forRoot(),
    ],
    exports: [
        // blocks
        BlockBannerComponent,
        BlockBrandsComponent,
        BlockCategoriesComponent,
        BlockFeaturesComponent,
        BlockMapComponent,
        BlockPostsComponent,
        BlockProductColumnsComponent,
        BlockProductsCarouselComponent,
        BlockProductsComponent,
        BlockSlideshowComponent,
        BlockMallComponent,
        CustomBlockProductsComponent,
        CustomBlockProductsCarouselComponent,
        CustomBlockProductColumnsComponent,
        CustomBlockProductsMerchantComponent,
        CustomBlockProductsCarouselMerchantComponent,
        BlockMerchantComponent,
        BlockProductCustomVerticleComponent,
        BlockProductCustomHorizontalComponent,
        CustomBlockProductsLargeFirstComponent,
        BlockCustomCarouselComponent
    ]
})
export class BlocksModule { }
