<div class="page-header">
    <div class="page-header__container container">
        <div class="page-header__breadcrumb">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <ng-container *ngFor="let breadcrumb of breadcrumbs; last as last">
                        <li *ngIf="!last" class="breadcrumb-item">
                            <a [routerLink]="breadcrumb.url" *ngIf="lang == 'en'">{{ breadcrumb.en }}</a>
                            <a [routerLink]="breadcrumb.url" *ngIf="lang == 'zh'">{{ breadcrumb.zh }}</a>
                            <app-icon class="breadcrumb-arrow" name="arrow-rounded-right-6x9" size="6x9"></app-icon>
                        </li>
                        <li *ngIf="last && lang == 'en'" class="breadcrumb-item active" aria-current="page">{{ breadcrumb.en }}</li>
                        <li *ngIf="last && lang == 'zh'" class="breadcrumb-item active" aria-current="page">{{ breadcrumb.zh }}</li>
                    </ng-container>
                </ol>
            </nav>
        </div>
        <div *ngIf="header" class="page-header__title">
            <h1 *ngIf="lang == 'en'">{{ header.en }}</h1>
            <h1 *ngIf="lang == 'zh'">{{ header.zh }}</h1>
        </div>
    </div>
</div>
