<div class="site-header">
    <app-header-topbar></app-header-topbar>

    <div *ngIf="layout === 'classic'" class="site-header__middle container">
        <div class="site-header__logo ">
            <a routerLink="./">
               <img src="assets/images/logos/weini-logo.png" height="160" class="p-2">
            </a>
        </div>
        <div class="site-header__search">
            <app-header-search></app-header-search>
        </div>
        <!-- <div class="site-header__phone" *ngIf="authService.loggedIn()">           
            <a class="topbar-link">{{'topbar.usdt' | translate}}: <b *ngIf="authService.usdtValue"> {{authService.usdtValue}} </b><b *ngIf="!authService.usdtValue"> 0 </b></a>
            <br>
            <a class="topbar-link">{{'topbar.weni' | translate}}: <b *ngIf="authService.weniTokenValue"> {{authService.weniTokenValue}} </b><b *ngIf="!authService.weniTokenValue"> 0 </b></a>
            <br>
            <a class="topbar-link">{{'topbar.weniPoints' | translate}}: <b *ngIf="authService.weniValue"> {{authService.weniValue}} </b><b *ngIf="!authService.weniValue"> 0 </b></a>
            <br>
            <a class="topbar-link">{{'topbar.usdtPoints' | translate}}: <b *ngIf="authService.usdtPointValue"> {{authService.usdtPointValue}} </b><b *ngIf="!authService.usdtPointValue"> 0 </b></a>
            <br>
            <div class="mt-2"><a style="color: #f34770;" class="topbar-link" routerLink="./account/reload"><i class="fas fa-retweet"></i> {{'topbar.reload' | translate}}</a></div>
        </div> -->
    </div>

    <div class="site-header__nav-panel">
        <app-header-nav [departments]="layout === 'classic'" [logo]="layout === 'compact'" [search]="layout === 'compact'"></app-header-nav>
    </div>
</div>
